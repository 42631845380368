import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { AirlineIcon, MixedCabinToolTip, formatInterval } from "halifax";
import { TripSegment } from "redmond";

import { SliceType, flightShopCopy, formats } from "../../constants";

import "./styles.scss";

export interface IMobileAirlineDetailsCardProps {
  arrivalTime: string;
  departureTime: string;
  description: string;
  duration: number;
  firstTripSegment: TripSegment;
  airlinesCount: number;
  isDeparture: boolean;
  isMixedClass: boolean;
  onClick: (selection: SliceType) => void;
  stops: number;
  type: string;
}

const defaultProps: Partial<IMobileAirlineDetailsCardProps> = {
  isDeparture: false,
  isMixedClass: false,
};

const MobileAirlineDetailsCard = (
  props: IMobileAirlineDetailsCardProps
): JSX.Element => {
  const {
    arrivalTime,
    departureTime,
    description,
    duration,
    firstTripSegment,
    airlinesCount ,
    isDeparture,
    isMixedClass,
    onClick,
    stops,
    type,
  } = props;

  return (
    <Box className="airline-details-with-chevron">
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <b>{type}</b>
          {` ${description}`}
          {isMixedClass && <MixedCabinToolTip />}
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Typography variant="body1">
              {`${dayjs(departureTime).format(formats.SUMMARY_TIME)} - ${dayjs(
                arrivalTime
              ).format(formats.SUMMARY_TIME)}`}
            </Typography>
            <Box className="card-airline-container">
              <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
              <Typography variant="body2">
                {firstTripSegment.airlineName}
                {Boolean(airlinesCount) && (
                  <span className="vi-more red-text">{`+ ${airlinesCount} other`}</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1">{formatInterval(duration)}</Typography>
            <Typography variant="body2">
              {flightShopCopy.STOPS(stops)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <FontAwesomeIcon
        className="mobile-right-chevron"
        icon={faChevronRight}
        onClick={() =>
          onClick(isDeparture ? SliceType.departure : SliceType.return)
        }
      />
    </Box>
  );
};

MobileAirlineDetailsCard.defaultProps = defaultProps;

export default MobileAirlineDetailsCard;

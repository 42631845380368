import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { last } from "lodash";
import { TripCategory } from "redmond";

import { ExchangeTypeEventEnum } from "../constants";
import { ExchangeModuleRootState } from "../store";

const getSearchState = (state: ExchangeModuleRootState) => state.search;

export const getAirlineMap = (state: ExchangeModuleRootState) =>
  getSearchState(state).airlineMap;

export const getAirportMap = (state: ExchangeModuleRootState) =>
  getSearchState(state).airportMap;

export const getAirChangesPending = (state: ExchangeModuleRootState) =>
  getSearchState(state).airChangesPending;

export const getDateChangesPending = (state: ExchangeModuleRootState) =>
  getSearchState(state).dateChangesPending;

export const getDateSelectionDirty = (state: ExchangeModuleRootState) =>
  getSearchState(state).dateSelectionDirty;

export const getCurrency = (state: ExchangeModuleRootState) =>
  getSearchState(state).currency;

export const getFlightBooking = (state: ExchangeModuleRootState) =>
  getSearchState(state).booking;

export const getPassengers = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.passengers;

export const getPayments = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.paymentBreakdown.payments;

export const getOriginalPaxPricing = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.sellingPricing.pricingByPassenger;

export const getTotalPricing = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.sellingPricing.totalPricing;

export const getSeatPayments = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.seats?.seats;

export const getAncillariesPayment = (state: ExchangeModuleRootState) =>
  getTotalPricing(state)?.ancillaries;

export const getSubTotalPayment = (state: ExchangeModuleRootState) =>
  getTotalPricing(state)?.subtotal;

export const getTotalPayment = (state: ExchangeModuleRootState) =>
  getTotalPricing(state)?.total;

export const getTravelCredit = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.travelCredit;

export const getTravelItinerary = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.travelItinerary;

export const getMultiTicketType = (state: ExchangeModuleRootState) =>
  getFlightBooking(state)?.bookedItinerary.multiTicketType;

export const getSelectedPnrHfv2 = (state: ExchangeModuleRootState) =>
  getSearchState(state).selectedPnrHfv2;

export const getOriginalFlightInfo = (state: ExchangeModuleRootState) => {
  const origFlightInfo = getSearchState(state).original;
  const { departureDate: depDate, returnDate: retDate } = origFlightInfo;

  // hydrate dates into Date types
  return {
    ...origFlightInfo,
    departureDate: depDate ? dayjs(depDate) : null,
    returnDate: retDate ? dayjs(retDate) : null,
  };
};

export const getModifiedFlightInfo = (state: ExchangeModuleRootState) => {
  const modifiedFlightInfo = getSearchState(state).modified;
  const { departureDate: depDate, returnDate: retDate } = modifiedFlightInfo;

  // hydrate dates into Date types
  return {
    ...modifiedFlightInfo,
    departureDate: depDate ? dayjs(depDate) : null,
    returnDate: retDate ? dayjs(retDate) : null,
  };
};

export const getMonths = (state: ExchangeModuleRootState) =>
  getSearchState(state).months;

export const getPriceLegend = (state: ExchangeModuleRootState) =>
  getSearchState(state).priceLegend;

export const getTripType = (state: ExchangeModuleRootState) =>
  getSearchState(state).tripType;

/**
 * @description Gets the current value of the departure date (after user edits).
 * Hydrated as a DayJS
 * @param state
 * @return {Maybe<Dayjs>}
 */
export const getDepartureDate = (state: ExchangeModuleRootState) =>
  getModifiedFlightInfo(state).departureDate;

export const getDestination = (state: ExchangeModuleRootState) =>
  getModifiedFlightInfo(state).destination;

export const getDestinationCode = (state: ExchangeModuleRootState) =>
  getModifiedFlightInfo(state).destinationCode;

export const getOrigin = (state: ExchangeModuleRootState) =>
  getModifiedFlightInfo(state).origin;

export const getOriginCode = (state: ExchangeModuleRootState) =>
  getModifiedFlightInfo(state).originCode;

/**
 * @description Gets the current value of the return date (after user edits).
 * Hydrated as a Dayjs
 * @param state
 * @return {Maybe<Dayjs>}
 */
export const getReturnDate = (state: ExchangeModuleRootState) =>
  getModifiedFlightInfo(state).returnDate;

export const getPrevOutboundFlight = createSelector(
  getFlightBooking,
  (booking) => {
    const { travelItinerary } = booking?.bookedItinerary ?? {};
    let outboundSegment;

    if (travelItinerary) {
      if ("travelItineraries" in travelItinerary) {
        // handle MultiTravelItinerary
        outboundSegment = last(
          travelItinerary.travelItineraries[0].slices[0].segments
        );
      } else if ("slices" in travelItinerary) {
        // handle SingleTravelItinerary
        outboundSegment = last(travelItinerary.slices[0].segments);
      }
    }

    return outboundSegment;
  }
);

export const getOutboundLocator = createSelector(
  getFlightBooking,
  (booking) => {
    const { travelItinerary } = booking?.bookedItinerary ?? {};
    let outboundLocator;

    if (travelItinerary) {
      if ("travelItineraries" in travelItinerary) {
        // handle MultiTravelItinerary
        outboundLocator = travelItinerary.travelItineraries[0].locators;
      }
    }

    return outboundLocator;
  }
);

export const getReturnLocator = createSelector(getFlightBooking, (booking) => {
  const { travelItinerary } = booking?.bookedItinerary ?? {};
  let returnLocator;

  if (travelItinerary) {
    if ("travelItineraries" in travelItinerary) {
      // handle MultiTravelItinerary
      returnLocator = travelItinerary.travelItineraries[1].locators;
    }
  }

  return returnLocator;
});

export const getPrevReturnFlight = createSelector(
  getFlightBooking,
  getTripType,
  (booking, tripType) => {
    const { travelItinerary } = booking?.bookedItinerary ?? {};
    let returnSegment = null;

    if (travelItinerary && tripType === TripCategory.ROUND_TRIP) {
      if ("travelItineraries" in travelItinerary) {
        // handle MultiTravelItinerary
        const returnItinerary = last(travelItinerary.travelItineraries);
        const returnSlice = last(returnItinerary?.slices);

        returnSegment = last(returnSlice?.segments);
      } else if ("slices" in travelItinerary) {
        // handle SingleTravelItinerary
        const returnSlice = last(travelItinerary.slices);

        returnSegment = last(returnSlice?.segments);
      }
    }

    return returnSegment;
  }
);

export const getShoppedDestination = createSelector(
  getDestination,
  getOriginalFlightInfo,
  (destination, originalFlight) => destination ?? originalFlight.destination
);

export const getShoppedDestinationLabel = createSelector(
  getAirportMap,
  getShoppedDestination,
  (airports, destination) => {
    const backup = airports[destination?.id.code.code ?? ""]?.cityName || "";
    return destination?.label ?? backup;
  }
);

export const getShoppedOrigin = createSelector(
  getOrigin,
  getOriginalFlightInfo,
  (origin, originalFlight) => origin ?? originalFlight.origin
);

export const getShoppedOriginLabel = createSelector(
  getAirportMap,
  getShoppedOrigin,
  (airports, origin) => {
    const backup = airports[origin?.id.code.code ?? ""]?.cityName || "";
    return origin?.label ?? backup;
  }
);

export const getShoppedReturnDate = createSelector(
  getReturnDate,
  getOriginalFlightInfo,
  (returnDate, originalFlight) => returnDate ?? originalFlight.returnDate
);

export const getShoppedDepartureDate = createSelector(
  getDepartureDate,
  getOriginalFlightInfo,
  (departureDate, originalFlight) =>
    departureDate ?? originalFlight.departureDate
);

export const getDatesChanged = createSelector(
  getShoppedDepartureDate,
  getShoppedReturnDate,
  getOriginalFlightInfo,
  (departureDate, returnDate, originalFlight): boolean => {
    const depDateChanged = departureDate
      ? !departureDate.isSame(originalFlight.departureDate, "day")
      : false;
    const returnDateChanged =
      (originalFlight.returnDate && !returnDate) ||
      (!originalFlight.returnDate && returnDate) ||
      (returnDate && !returnDate.isSame(originalFlight.returnDate, "day"));

    return !!(depDateChanged || returnDateChanged);
  }
);

export const getAirportsChanged = createSelector(
  getShoppedDestination,
  getShoppedOrigin,
  getOriginalFlightInfo,
  (dest, origin, originalFlight) => {
    const destCode = dest?.id?.code?.code;
    const originCode = origin?.id?.code?.code;
    const destChanged =
      destCode && destCode !== originalFlight.destination?.id.code.code;
    const originChanged =
      originCode && originCode !== originalFlight.origin?.id.code.code;

    return !!(destChanged || originChanged);
  }
);

export const getExchangeTypeEvent = createSelector(
  getDatesChanged,
  getAirportsChanged,
  (datesChanged, airportsChanged) => {
    if (datesChanged && airportsChanged)
      return ExchangeTypeEventEnum.AirportAndDates;

    if (datesChanged) return ExchangeTypeEventEnum.AirportOnly;

    if (airportsChanged) return ExchangeTypeEventEnum.DatesOnly;

    return ExchangeTypeEventEnum.Same;
  }
);

export const getShoppedParams = createSelector(
  getShoppedDepartureDate,
  getShoppedDestination,
  getShoppedOrigin,
  getShoppedReturnDate,
  (departureDate, destination, origin, returnDate) => ({
    departureDate,
    destination,
    origin,
    returnDate,
    destinationCode: destination?.id.code.code,
    originCode: origin?.id.code.code,
  })
);

import axios from "axios";
import {
  ExchangeSubmitResponse,
  ISubmitFlightExchangeReq,
  ISubmitFlightExchangeRes,
} from "redmond";

import { config } from "../../config";
import { submitFlightExchangePath } from "../paths";

const submitFlightExchange = (req: ISubmitFlightExchangeReq): Promise<void> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post<ISubmitFlightExchangeRes>(
        submitFlightExchangePath,
        req,
        {
          baseURL: config.baseURL,
        }
      );

      if (res.data.ExchangeSubmitResponse === ExchangeSubmitResponse.success) {
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      reject(e);
    }
  });

export default submitFlightExchange;

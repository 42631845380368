import React, { useEffect, useRef, useState } from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  CloseButtonIcon,
  FlightCategoryToggle,
  FlightCategoryToggleTripCategory,
  Header,
  ActionLink,
  ActionButton,
  PickerType,
} from "halifax";
import { isEqual } from "lodash";
import {
  Currency,
  IDepartureCalendarReport,
  IDepartureCalendarRequestBody,
  IMonthBucket,
  ITripTerminus,
  Locale,
  RegionType,
  TripCategory,
  TripFilter,
} from "redmond";
import { batch, useDispatch, useSelector } from "react-redux";

import { MobileAirportPicker } from "../../../MobileAirportPicker";
import { MobileCalendarPicker } from "../../../MobileCalendarPicker";
import fetchCalendar from "../../../../api/v1/exchange/fetchCalendar";
import {
  buttonText,
  MobileFlightSearchStep,
  searchCopy,
} from "../../../../constants";
import {
  setCurrency,
  setDepartureDate,
  setDestination,
  setOrigin,
  setPriceLegend,
  setReturnDate,
  setTripType,
} from "../../../../reducers/search";
import {
  getDepartureDate,
  getDestination,
  getDestinationCode,
  getOrigin,
  getOriginCode,
  getReturnDate,
  getTripType,
} from "../../../../selectors";
import { transformDateBuckets } from "../../../../utils/helpers";

import "./styles.scss";

export interface IMobileFlightSearchControlProps {
  onFlowComplete: () => void;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  steps: MobileFlightSearchStep[];
}

const defaultProps: Partial<IMobileFlightSearchControlProps> = {};

const MobileFlightSearchControl = (props: IMobileFlightSearchControlProps) => {
  const { onFlowComplete, open, setOpen, steps } = props;
  const dispatch = useDispatch();
  const prevSteps = useRef(steps);
  const departureDate = useSelector(getDepartureDate);
  const destCode = useSelector(getDestinationCode);
  const destination = useSelector(getDestination);
  const origin = useSelector(getOrigin);
  const originCode = useSelector(getOriginCode);
  const returnDate = useSelector(getReturnDate);
  const tripType = useSelector(getTripType);

  const [currStep, setCurrStep] = useState(steps[0]);
  const [localDepDate, setLocalDepDate] = useState<Date | null>(
    departureDate?.toDate() ?? null
  );
  const [localDest, setLocalDest] = useState<ITripTerminus | null>(destination);
  const [localOrigin, setLocalOrigin] = useState<ITripTerminus | null>(origin);
  const [localRetDate, setLocalRetDate] = useState<Date | null>(
    returnDate?.toDate() ?? null
  );
  const [months, setMonths] = useState<IMonthBucket[]>([]);
  const [readyForNextStep, setReadyForNextStep] = useState(false);

  const isOneWay = tripType === TripCategory.ONE_WAY;
  const onFirstStep = currStep === steps[0];
  const onLastStep = currStep === steps[steps.length - 1];

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const commitLocalChanges = () => {
    if (currStep === MobileFlightSearchStep.LocationSearch) {
      batch(() => {
        dispatch(setOrigin(localOrigin));
        dispatch(setDestination(localDest));
      });
    } else if (currStep === MobileFlightSearchStep.CalendarPicker) {
      const strDepDate = dayjs(localDepDate).toString();
      const strRetDate = dayjs(localRetDate).toString();

      batch(() => {
        dispatch(setDepartureDate(strDepDate));
        !isOneWay && dispatch(setReturnDate(strRetDate));
      });
    }
  };

  const goToNextStep = () => {
    if (onLastStep) {
      onFlowComplete();
    } else {
      const currStepIdx = steps.findIndex((step) => step === currStep);

      setCurrStep(steps[currStepIdx + 1]);
    }
  };

  const handleGoBack = () => {
    if (!onFirstStep) {
      const currStepIdx = steps.findIndex((step) => step === currStep);

      setCurrStep(steps[currStepIdx - 1]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currStep]);

  useEffect(() => {
    if (destCode && originCode && tripType) {
      const request: IDepartureCalendarRequestBody = {
        tripType,
        filter: { TripFilter: TripFilter.NO_FILTER },
        preferences: { locale: Locale.EN_US, currency: Currency.USD },
        route: {
          destination: {
            code: destCode,
            regionType: RegionType.Airport,
          },
          origin: { code: originCode, regionType: RegionType.Airport },
        },
      };

      fetchCalendar(request)
        .then((calendar: IDepartureCalendarReport) => {
          const { currency, departureDateBuckets } = calendar;
          const months = transformDateBuckets(departureDateBuckets);
          const priceLegend = departureDateBuckets.map(
            (bucket: any) => bucket.legend
          );

          batch(() => {
            setMonths(months);
            dispatch(setCurrency(currency));
            dispatch(setPriceLegend(priceLegend));
          });
        })
        .catch(() => {
          batch(() => {
            setMonths([]);
            dispatch(setCurrency(""));
            dispatch(setPriceLegend([]));
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destCode, originCode, tripType]);

  useEffect(() => {
    let isReadyForNextStep = false;

    if (currStep === MobileFlightSearchStep.LocationSearch) {
      isReadyForNextStep = Boolean(localOrigin && localDest);
    } else if (currStep === MobileFlightSearchStep.CalendarPicker) {
      if (isOneWay) {
        isReadyForNextStep = Boolean(localDepDate);
      } else {
        isReadyForNextStep = Boolean(localDepDate && localRetDate);
      }
    }

    if (isReadyForNextStep) commitLocalChanges();

    setReadyForNextStep(isReadyForNextStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStep, localDepDate, localDest, isOneWay, localOrigin, localRetDate]);

  useEffect(() => {
    if (!isEqual(prevSteps.current, steps)) {
      prevSteps.current = steps;
      setCurrStep(steps[0]);
    }
  }, [steps]);

  return open ? (
    <Box
      className={clsx("mobile-flight-search-flow", {
        "date-range-picker": currStep === MobileFlightSearchStep.CalendarPicker,
        "location-picker": currStep === MobileFlightSearchStep.LocationSearch,
      })}
    >
      <Header
        fullWidth
        isMobile
        center={
          <Box className="header-center-section">
            {currStep === MobileFlightSearchStep.CalendarPicker && (
              <Box className="trip-origin-dest-summary">
                <Box className="origin-dest-container">
                  <Typography variant="body1">
                    {searchCopy.CHOOSE_DATES}
                  </Typography>
                </Box>
              </Box>
            )}
            {currStep === MobileFlightSearchStep.LocationSearch && (
              <Box className="flight-search-location-label">
                <span>{searchCopy.CHOOSE_FLIGHTS}</span>
              </Box>
            )}
          </Box>
        }
        left={
          !onFirstStep ? (
            <ActionLink
              className={clsx("flight-search-header-back-btn", {
                hidden: currStep === MobileFlightSearchStep.LocationSearch,
              })}
              content={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={handleGoBack}
            />
          ) : undefined
        }
        right={
          <ActionLink
            className="flight-search-header-close-btn"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={() => setOpen(false)}
          />
        }
      />
      {currStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <Box className="trip-category-container">
            <FlightCategoryToggle
              category={tripType! as FlightCategoryToggleTripCategory}
              className="flight-search-category-toggle b2b"
              setTripCategory={(newType: FlightCategoryToggleTripCategory) =>
                dispatch(setTripType(newType))
              }
            />
          </Box>
          <MobileAirportPicker
            destination={destination}
            onClose={() => { }}
            origin={origin}
            setDestination={setLocalDest}
            setOrigin={setLocalOrigin}
          />
        </Box>
      )}
      {currStep === MobileFlightSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          departureDate={localDepDate}
          focusedMonthIndex={focusedMonthIndex}
          months={months}
          onComplete={() => { }}
          pickerType={isOneWay ? PickerType.DAY : PickerType.RANGE}
          readyToSearch={false}
          returnDate={localRetDate}
          setDepartureDate={setLocalDepDate}
          setReturnDate={setLocalRetDate}
        />
      )}
      {readyForNextStep && (
        <ActionButton
          className="next-step-btn b2b"
          defaultStyle="h4r-primary"
          message={onLastStep ? buttonText.SAVE : buttonText.CONTINUE}
          onClick={goToNextStep}
        />
      )}
    </Box>
  ) : null;
};

MobileFlightSearchControl.defaultProps = defaultProps;

export default MobileFlightSearchControl;

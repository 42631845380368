import React from "react";
import { Breadcrumbs } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink, Icon, IconName } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  ExchangeScenario,
  FareClasses,
  FlightRatingsEnum,
  FlightShopStep,
  TripCategory,
} from "redmond";

import { buttonText } from "../../../../constants";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../context/experiments";
import {
  resetAllFilters,
  setFareClassesFilter,
  setShopStep,
} from "../../../../reducers/flightShop";
import {
  getExchangeScenario,
  getExchangeType,
  getFlightShopStep,
  getOutboundFareClass,
  getShoppedTrip,
  getTripType,
} from "../../../../selectors";
import { goBackToMyTrips, skipShopAction } from "../../../../utils/helpers";

import "./styles.scss";

export interface IReshopBreadcrumbsProps {
  history: History;
}

const defaultProps: Partial<IReshopBreadcrumbsProps> = {};

const ReshopBreadcrumbs = (_props: IReshopBreadcrumbsProps): JSX.Element => {
  // const {  } = props;
  const dispatch = useDispatch();
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);

  const history = useHistory();
  const scenario = useSelector(getExchangeScenario);
  const exchangeType = useSelector(getExchangeType);
  const shopStep = useSelector(getFlightShopStep);
  const shoppedTrip = useSelector(getShoppedTrip);
  const tripType = useSelector(getTripType);
  const outboundFareClass = useSelector(getOutboundFareClass);

  const { outboundSelection, returnSelection } = exchangeType;
  const { outgoingSliceId, returnSliceId } = shoppedTrip;
  const isOneWay = tripType === TripCategory.ONE_WAY;
  const skipChooseDeparture = skipShopAction(outboundSelection);
  const skipChooseReturn = skipShopAction(returnSelection);
  const returnDisabled = isOneWay || !outgoingSliceId;
  const reviewDisabled = isOneWay
    ? !outgoingSliceId
    : !outgoingSliceId && !returnSliceId;

  return (
    <Breadcrumbs
      aria-label="Flight shop breadcrumbs"
      className="reshop-breadcrumbs-container"
      separator={
        <Icon className="separator" name={IconName.NavigationForward} />
      }
    >
      <ActionLink
        className={clsx("breadcrumb b2b")}
        content={buttonText.MY_TRIPS}
        onClick={() => goBackToMyTrips(history)}
      />
      {!skipChooseDeparture && (
        <ActionLink
          className={clsx("breadcrumb b2b", {
            current: shopStep === FlightShopStep.ChooseDeparture,
          })}
          content={buttonText.CHOOSE_DEPARTURE}
          onClick={() => {
            dispatch(resetAllFilters());
            dispatch(setShopStep(FlightShopStep.ChooseDeparture));
          }}
        />
      )}
      {!(skipChooseReturn || isOneWay) && (
        <ActionLink
          className={clsx("breadcrumb b2b", {
            current: shopStep === FlightShopStep.ChooseReturn,
          })}
          content={buttonText.CHOOSE_RETURN}
          disabled={returnDisabled}
          onClick={() => {
            const fareClassesFilter: FareClasses = {
              basic: false,
              enhanced: false,
              luxury: false,
              premium: false,
              standard: false,
            };

            const fareClass = FlightRatingsEnum[outboundFareClass];
            const fareClasses = {
              ...fareClassesFilter,
              [fareClass]: !fareClassesFilter[fareClass],
            };
            dispatch(setFareClassesFilter(fareClasses));
            dispatch(setShopStep(FlightShopStep.ChooseReturn));
          }}
        />
      )}
      {(redesignExp || scenario === ExchangeScenario.ftc) && (
        <ActionLink
          className={clsx("breadcrumb b2b", {
            current: shopStep === FlightShopStep.ReviewItinerary,
          })}
          content={buttonText.REVIEW_ITINERARY}
          // With the current implementation this should is only enabled for FTC.
          // The only way to advance to the confirmation page is by selecting flights
          disabled={reviewDisabled}
          onClick={() => {
            if (scenario === ExchangeScenario.ftc) {
              dispatch(setShopStep(FlightShopStep.ReviewItinerary));
            }
          }}
        />
      )}
    </Breadcrumbs>
  );
};

ReshopBreadcrumbs.defaultProps = defaultProps;

export default ReshopBreadcrumbs;

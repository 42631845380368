import React from "react";
import { Box, Button } from "@material-ui/core";
import { DesktopPopupModal, PickerType } from "halifax";
import { IMonthBucket, TripCategory } from "redmond";

import { MonthAndDatePicker } from "../MonthAndDatePicker";
import {
  CloseReason,
  MonthAndDatePickerType,
  buttonText,
  searchCopy,
} from "../../constants";

import "./styles.scss";

export interface IDesktopCalendarPickerProps {
  closePopup: (reason?: CloseReason) => void;
  departureDate: Date | null;
  months: IMonthBucket[];
  open: boolean;
  pickerType: PickerType;
  readyToSearch: boolean;
  returnDate: Date | null;
  setDepartureDate: (date: Date | null) => void;
  setReturnDate: (date: Date | null) => void;
  tripCategory?: TripCategory;
}

const DesktopCalendarPicker = (props: IDesktopCalendarPickerProps) => {
  const {
    closePopup,
    departureDate,
    months,
    open,
    pickerType,
    readyToSearch,
    returnDate,
    setDepartureDate,
    setReturnDate,
    tripCategory,
  } = props;

  const headerTitle =
    tripCategory === TripCategory.ONE_WAY
      ? searchCopy.DESKTOP_CALENDAR_TITLE_ONE_WAY
      : searchCopy.DESKTOP_CALENDAR_TITLE_ROUND_TRIP;

  return (
    <DesktopPopupModal
      aria-modal
      contentClassName="desktop-calendar-picker-wrapper"
      className="calendar-picker-popup-root flight-calendar-picker-popup-root flights-module"
      invisibleBackdrop={false}
      onClose={() => closePopup(CloseReason.exit)}
      open={open}
    >
      <Box aria-label="Date Range Picker.">
        <MonthAndDatePicker
          className="b2b-flights"
          endDate={returnDate}
          header={headerTitle}
          months={months}
          pickerType={pickerType}
          setEndDate={setReturnDate}
          setStartDate={setDepartureDate}
          startDate={departureDate}
          viewType={MonthAndDatePickerType.Horizontal}
        />
        <Button
          aria-disabled={!readyToSearch}
          aria-label="Done button. Click to save selection."
          className="select-dates-button"
          disabled={!readyToSearch}
          onClick={() => closePopup(CloseReason.submit)}
          variant="contained"
        >
          {buttonText.DONE}
        </Button>
      </Box>
    </DesktopPopupModal>
  );
};

export default DesktopCalendarPicker;

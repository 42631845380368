import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { FlightDetailsCard } from "halifax";
import { useSelector } from "react-redux";
import { TripCategory, TripDetails } from "redmond";

import { fareClasses } from "../../../../constants";
import {
  getAirportMap,
  getDepartureDate,
  getReturnDate,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getShoppedTrip,
  getTripType,
} from "../../../../selectors";
import {
  getPlusDays,
  getReviewCardHeader,
  getSliceIndex,
} from "../../../../utils/helpers";

import "./styles.scss";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../context/experiments";

export interface IFlightDetailsProps {
  isMobile: boolean;
  isChatbotEnabled: boolean;
  isOutgoing: boolean;
  onAlgomerchTagClick: (tag: string) => void;
  onFareClick: (sliceId: string, fareId: string) => void;
  selectedFareId: string;
  tripDetails: TripDetails;
  tripId: string;
}

const defaultProps: Partial<IFlightDetailsProps> = {
  isMobile: false,
  isOutgoing: false,
};

enum ratings {
  BASIC,
  STANDARD,
  ENHANCED,
  PREMIUM,
  LUXURY,
}

const FlightDetails = (props: IFlightDetailsProps) => {
  const {
    isMobile,
    isOutgoing,
    onAlgomerchTagClick,
    onFareClick,
    selectedFareId,
    tripDetails,
    isChatbotEnabled = false,
  } = props;
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);
  const airports = useSelector(getAirportMap);
  const departureDate = useSelector(getDepartureDate);
  const returnDate = useSelector(getReturnDate);
  const rewardsKey = useSelector(
    getSelectedAccountReferenceIdIfRedemptionEnabled
  );
  const shoppedTrip = useSelector(getShoppedTrip);
  const tripType = useSelector(getTripType);
  // const [fareDetails, setFareDetails] = useState<FareDetails>();
  // const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);

  // useEffect(() => {
  //   if (fareDetails) {

  //   }
  // }, [fareDetails]);

  // useEffect(() => {
  //   if (selectedFareId) {
  //     const details = tripDetails.fareDetails.find((f) => f.id === selectedFareId);

  //     setFareDetails(details);
  //   }
  // }, [selectedFareId]);

  if (!tripDetails) return null;

  const firstSliceIdx = getSliceIndex(true, tripDetails);
  const secondSliceIdx = getSliceIndex(false, tripDetails);
  const {
    destinationCode: firstSliceDestCode,
    destinationName: firstSliceDestName,
  } = tripDetails.slices[firstSliceIdx];
  const firstSliceDest =
    airports[firstSliceDestCode]?.cityName ?? firstSliceDestName;
  const outboundHeader =
    departureDate &&
    getReviewCardHeader(true, firstSliceDest, departureDate.toDate(), true);
  const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  let returnHeader = null;

  if (tripType === TripCategory.ROUND_TRIP && secondSliceIdx !== -1) {
    const {
      destinationCode: secondSliceDestCode,
      destinationName: secondSliceDestName,
    } = tripDetails.slices[secondSliceIdx];
    const secondSliceDest =
      airports[secondSliceDestCode]?.cityName ?? secondSliceDestName;

    returnHeader =
      returnDate &&
      getReviewCardHeader(false, secondSliceDest, returnDate.toDate(), true);
  }

  const getEmptyRestrictionsText = (fareRating?: number) => {
    switch (fareRating) {
      case ratings.BASIC:
        return fareClasses.BASIC.desc;
      case ratings.STANDARD:
        return fareClasses.STANDARD.desc;
      case ratings.ENHANCED:
        return fareClasses.ENHANCED.desc;
      case ratings.PREMIUM:
        return fareClasses.PREMIUM.desc;
      case ratings.LUXURY:
        return fareClasses.LUXURY.desc;
      default:
        return "";
    }
  };

  const renderHeader = (header: string | null) => {
    if (!header) return undefined;

    const [fromHeader, dateHeader] = header.split(":");

    return (
      <Typography className="flight-details-header" variant="body1">
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
        {/* {isMixedCabinClass && <MixedCabinToolTip />} */}
      </Typography>
    );
  };

  return (
    <FlightDetailsCard
      showFaresAsDiffs
      className={clsx("b2b", { mobile: isMobile })}
      sortFares="total"
      getEmptyRestrictionsText={getEmptyRestrictionsText}
      header={
        isOutgoing ? renderHeader(outboundHeader) : renderHeader(returnHeader)
      }
      // isMixedCabinClass={isMixedCabinClass}
      isMobile={isMobile}
      isOutgoing={isOutgoing}
      maxAmenities={redesignExp ? 7 : -1}
      onAlgomerchClick={onAlgomerchTagClick}
      onFareClick={(fareId: string) => onFareClick(slice.id, fareId)}
      outgoingFareRating={
        isOutgoing ? undefined : shoppedTrip.outgoingFareRating
      }
      plusDays={getPlusDays(slice)}
      rewardsKey={rewardsKey ?? undefined}
      selectedFareId={selectedFareId}
      tripDetails={tripDetails}
      isRefundableFaresEnabled={false}
      isChatbotEnabled={isChatbotEnabled}
      isReshop
      hideIsInPolicy={true}
    />
  );
};

FlightDetails.defaultProps = defaultProps;

export default FlightDetails;

import dayjs, { Dayjs } from "dayjs";
import { IPartialAirportMap, Policy } from "redmond";

export const DATE_FORMAT = "ddd, MMM D";
export const OUTBOUND = "Outbound";
export const RETURN = "Return";
export const HOUR_FORMAT = "h:mm A";

interface arguments {
  airports: IPartialAirportMap;
  airportCode: string;
  isOutgoing: boolean;
  date: Dayjs | null;
}

export const getItineraryDetailsHeader = ({
  airports,
  airportCode,
  isOutgoing,
  date,
}: arguments) => {
  const title = isOutgoing ? OUTBOUND : RETURN;
  const { cityName } = airports[airportCode];
  const formatedDate = dayjs(date).format(DATE_FORMAT);
  return `<b>${title}</b> to ${cityName} (${airportCode}) on ${formatedDate} `;
};

export const isSliceDisabled = ({
  policies,
  unscopedValue,
}: {
  unscopedValue?: string;
  policies?: Policy[];
}) => {
  if (policies && unscopedValue) {
    return !policies.find((p) => p?.agentLocator?.unscopedValue === unscopedValue)?.exchangeable;
  }
  return false;
}

export const DISABLED_FLIGHT = "One of the flights on this itinerary is not eligible to be changed."

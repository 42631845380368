import React from "react";
import { MobileFlightDetailsModal } from "halifax";
import { FareDetails, TripDetails } from "redmond";

import {
  getPlusDays,
  getReviewCardHeader,
  getSliceIndex,
} from "../../../../utils/helpers";

import "./styles.scss";

export interface IMobileItineraryDetailsModalProps {
  buttonText?: string;
  fareDetails?: FareDetails;
  isDeparture: boolean;
  isMixedCabinClass: boolean;
  onClose: () => void;
  open: boolean;
  tripDetails: TripDetails;
}

const defaultProps: Partial<IMobileItineraryDetailsModalProps> = {
  isMixedCabinClass: false,
  isDeparture: false,
};

const MobileItineraryDetailsModal = (
  props: IMobileItineraryDetailsModalProps
) => {
  const {
    buttonText,
    fareDetails,
    isDeparture,
    isMixedCabinClass,
    onClose,
    open,
    tripDetails,
  } = props;

  if (!open) return null;

  const sliceIdx = tripDetails ? getSliceIndex(isDeparture, tripDetails) : 0;
  const tripSlice = tripDetails?.slices[sliceIdx];
  const { departureTime, destinationCode } = tripSlice;

  const renderCardHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");

    return (
      <>
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
      </>
    );
  };

  return (
    <MobileFlightDetailsModal
      buttonText={buttonText}
      className="b2b b2b-secondary"
      departure={isDeparture}
      fareDetails={fareDetails}
      isMixedCabinClass={isMixedCabinClass}
      onClose={onClose}
      openModal={open}
      plusDays={getPlusDays(tripSlice)}
      title={renderCardHeader(
        getReviewCardHeader(isDeparture, destinationCode, departureTime, true)
      )}
      tripDetails={tripDetails}
    />
  );
};

MobileItineraryDetailsModal.defaultProps = defaultProps;

export default MobileItineraryDetailsModal;

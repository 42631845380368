import React, { useMemo } from "react";
import { Box, Button, Chip } from "@material-ui/core";
import { startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { SliceStopCountFilter } from "redmond";

import { buttonText } from "../../../../../../constants";
import {
  resetAllFilters,
  resetFilter,
} from "../../../../../../reducers/flightShop";
import {
  getFlightFilterOptions,
  getFlightShopFilters,
} from "../../../../../../selectors";
import { isTimeRangeModified } from "../../../../../../utils/helpers";

import "./styles.scss";

export interface IAppliedFilterTagsProps {}

const ENTER_KEY = "Enter";
const FARE_CLASSES = "fareClasses";
const STOPS_FILTER = "stopsCount";

const defaultProps: Partial<IAppliedFilterTagsProps> = {};

const AppliedFilterTags = (/*props: IAppliedFilterTagsProps*/) => {
  // const {} = props;
  const dispatch = useDispatch();
  const { priceMax } = useSelector(getFlightFilterOptions);
  const filters = useSelector(getFlightShopFilters);

  const appliedFilters = useMemo(
    () =>
      Object.keys(filters).filter((key) => {
        const value = filters[key];

        // ignore fare classes
        if (key === FARE_CLASSES) return false;
        // handles airlines, airports, flightNumbers
        else if (Array.isArray(value)) return Boolean(value.length);
        // handles maxPrice
        else if (typeof value === "number")
          return (
            value >= 0 &&
            value < Number.MAX_SAFE_INTEGER &&
            value !== priceMax.value
          );
        // handles stopsCount
        else if (typeof value === "string")
          return value !== SliceStopCountFilter.ANY_NUMBER;
        // handles outboundArrival/Departure, returnArrival/Departure
        else if (typeof value === "object") return isTimeRangeModified(value);

        return false;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const handleRemove = (filter: string) => {
    if (filter === STOPS_FILTER) {
      // price prediciton stuff
    }

    dispatch(resetFilter(filter));
  };

  const handleReset = () => {
    if (appliedFilters.includes(STOPS_FILTER)) {
      // price prediction stuff
    }

    dispatch(resetAllFilters());
  };

  if (!appliedFilters.length) return null;

  return (
    <Box
      aria-label="Applied filters"
      className="applied-filter-tags"
      role="region"
    >
      {appliedFilters.map((filter) => (
        <Chip
          aria-label={`Remove ${filter}`}
          className="applied-filter-chip"
          color="secondary"
          key={filter}
          label={startCase(filter)}
          onDelete={() => handleRemove(filter)}
          onKeyDown={(e) => {
            if (e.key === ENTER_KEY) {
              handleRemove(filter);
            }
          }}
        />
      ))}
      {appliedFilters.length > 0 && (
        <Button
          className="reset-filters-btn"
          onClick={handleReset}
          onKeyDown={(e) => {
            if (e.key === ENTER_KEY) {
              handleReset();
            }
          }}
        >
          {buttonText.RESET_FILTERS}
        </Button>
      )}
    </Box>
  );
};

AppliedFilterTags.defaultProps = defaultProps;

export default AppliedFilterTags;

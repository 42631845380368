import React, { useCallback, useState } from "react";
import { Box } from "@material-ui/core";
import { batch, useDispatch } from "react-redux";
import _ from "lodash";
import { ITripTerminus } from "redmond";
import clsx from "clsx";
import { DesktopAirportPicker } from "../DesktopAirportPicker";
import { MobileAirportPicker } from "../MobileAirportPicker";
import { CloseReason } from "../../constants";
import {
  setAirportMap,
  setDestination,
  setOrigin,
} from "../../reducers/search";
import fetchCityName from "../../api/v1/exchange/fetchCityName";

export interface IFlightAirportPickerProps {
  closePicker: () => void;
  isMobile: boolean;
  className?: string;
}

const defaultProps: Partial<IFlightAirportPickerProps> = {
  isMobile: false,
};

const FlightAirportPicker = (props: IFlightAirportPickerProps): JSX.Element => {
  const { closePicker, isMobile, className } = props;
  const dispatch = useDispatch();
  const [destination, setLocalDestination] = useState<ITripTerminus | null>(
    null
  );
  const [origin, setLocalOrigin] = useState<ITripTerminus | null>(null);

  /**
   * @description Commit local state values to redux store and close picker
   * @param {CloseReason} [reason]
   */
  const closeModal = useCallback(
    (reason: CloseReason) => {
      if (reason === CloseReason.submit) {
        fetchCityName([origin!.id.code.code, destination!.id.code.code]).then(
          (cityNames) => {
            batch(() => {
              dispatch(
                setAirportMap(
                  _.mapValues(cityNames, (cityName) => ({ cityName }))
                )
              );
              dispatch(setOrigin(origin));
              dispatch(setDestination(destination));
            });
          }
        );
      }

      closePicker();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [origin, destination]
  );

  return (
    <Box className={clsx(className)}>
      {isMobile ? (
        <MobileAirportPicker
          destination={destination}
          onClose={closeModal}
          origin={origin}
          setDestination={setLocalDestination}
          setOrigin={setLocalOrigin}
        />
      ) : (
        <DesktopAirportPicker
          destination={destination}
          onClose={closeModal}
          origin={origin}
          setDestination={setLocalDestination}
          setOrigin={setLocalOrigin}
        />
      )}
    </Box>
  );
};

FlightAirportPicker.defaultProps = defaultProps;

export default FlightAirportPicker;

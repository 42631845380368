import React from "react";
import {} from "@material-ui/core";
import { RadioDropdown } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { ISelectOption, SliceStopCountFilter } from "redmond";

import { filterCopy } from "../../../../../../constants";
import { setStopCountFilter } from "../../../../../../reducers/flightShop";
import { getStopsCountFilter } from "../../../../../../selectors";

import "./styles.scss";

export interface IStopsCountFilterProps {
  showDropdownContentOnly?: boolean;
}

const defaultProps: Partial<IStopsCountFilterProps> = {
  showDropdownContentOnly: false,
};

export const stopsOptions: ISelectOption<SliceStopCountFilter>[] = [
  {
    label: filterCopy.ANY_NUMBER_OF_STOPS,
    value: SliceStopCountFilter.ANY_NUMBER,
  },
  {
    label: filterCopy.NO_STOPS,
    value: SliceStopCountFilter.NONE,
  },
  {
    label: filterCopy.ONE_STOP_OR_FEWER,
    value: SliceStopCountFilter.ONE_OR_LESS,
  },
  {
    label: filterCopy.TWO_STOPS_OR_FEWER,
    value: SliceStopCountFilter.TWO_OR_LESS,
  },
];

const StopsCountFilter = (props: IStopsCountFilterProps): JSX.Element => {
  const { showDropdownContentOnly } = props;
  const dispatch = useDispatch();
  const stopsCount = useSelector(getStopsCountFilter);

  const handleChange = (newValue: string) => {
    dispatch(setStopCountFilter(newValue as SliceStopCountFilter));
  };

  return (
    <RadioDropdown
      buttonAriaLabel={filterCopy.CHANGE_STOPS_FILTER}
      buttonClassName="stops-count-filter b2b-shop-filter"
      dropdownLabel={filterCopy.STOPS}
      groupAriaLabel={filterCopy.FILTER_STOPS}
      options={stopsOptions}
      popoverClassName="b2b"
      selected={stopsCount}
      setOption={handleChange}
      showDropdownContentOnly={showDropdownContentOnly}
    />
  );
};

StopsCountFilter.defaultProps = defaultProps;

export default StopsCountFilter;

import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { last } from "lodash";
import {
  AirlineMap,
  BookedFlightItineraryWithDepartureTime,
  ExchangeActionEnum,
  Flights,
  IExchangeType,
  IFlightBookingInfoRes,
  IMonthBucket,
  IdEnum,
  ITripTerminus,
  Maybe,
  RegionType,
  TripCategory,
  getDepartureSlice,
  getReturnSlice,
  IPartialAirportMap,
} from "redmond";

import { getFlightDuration } from "../utils/helpers";

export type FlightInfo = {
  departureDate: Maybe<string>;
  departureDuration: Maybe<string>;
  departureStops: Maybe<number>;
  returnStops: Maybe<number>;
  destination: Maybe<ITripTerminus>;
  destinationArrival: Maybe<string>;
  returnArrival: Maybe<string>;
  destinationCode: string;
  departureAirlineCode: Maybe<string>;
  returnAirlineCode: Maybe<string>;
  origin: Maybe<ITripTerminus>;
  originCode: string;
  returnDuration: Maybe<string>;
  returnDate: Maybe<string>;
};

export interface SearchState {
  airlineMap: AirlineMap;
  airportMap: IPartialAirportMap;
  booking: Maybe<BookedFlightItineraryWithDepartureTime>;
  airChangesPending: boolean;
  dateChangesPending: boolean;
  dateSelectionDirty: boolean;
  currency: string;
  modified: FlightInfo;
  months: IMonthBucket[];
  original: FlightInfo;
  priceLegend: string[];
  tripType?: TripCategory.ONE_WAY | TripCategory.ROUND_TRIP;
  selectedPnrHfv2?: string;
}

const getEmptyFlightInfo = () => ({
  departureDate: null,
  departureDuration: "",
  departureStops: null,
  destination: null,
  destinationCode: "",
  destinationArrival: null,
  returnArrival: null,
  origin: null,
  originCode: "",
  returnDuration: "",
  returnStops: null,
  returnDate: null,
  departureAirlineCode: null,
  returnAirlineCode: null,
});

export const setExchangeType = createAction<IExchangeType>("setExchangeType");
export const setFlights = createAction<Flights>("setFlights");

const initialState: SearchState = {
  airlineMap: {},
  airportMap: {},
  booking: null,
  airChangesPending: false,
  dateChangesPending: false,
  dateSelectionDirty: false,
  currency: "",
  modified: getEmptyFlightInfo(),
  months: [],
  original: getEmptyFlightInfo(),
  priceLegend: [],
  selectedPnrHfv2: "",
};

export const searchSlice = createSlice({
  initialState,
  name: "exchange/search",
  reducers: {
    resetState: () => initialState,
    setAirlineMap: (state: SearchState, action: PayloadAction<AirlineMap>) => {
      state.airlineMap = {
        ...state.airlineMap,
        ...action.payload,
      };
    },
    setAirportMap: (
      state: SearchState,
      action: PayloadAction<IPartialAirportMap>
    ) => {
      state.airportMap = {
        ...state.airportMap,
        ...action.payload,
      };
    },
    setCurrency: (state: SearchState, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setDepartureDate: (
      state: SearchState,
      action: PayloadAction<Maybe<string>>
    ) => {
      const { departureDate } = state.original;
      const isDiff = !dayjs(departureDate).isSame(dayjs(action.payload), "day");

      state.modified = {
        ...state.modified,
        departureDate: action.payload,
      };
      state.dateChangesPending = isDiff;

      if (!state.dateSelectionDirty) {
        state.dateSelectionDirty = isDiff;
      }
    },
    setDestination: (
      state: SearchState,
      action: PayloadAction<Maybe<ITripTerminus>>
    ) => {
      const { payload } = action;
      const { code: originalCode } = state.original.destination?.id.code ?? {};
      const { code: newCode } = payload?.id.code ?? {};

      state.modified = {
        ...state.modified,
        destination: payload,
        destinationCode: payload?.id?.code?.code ?? "",
      };
      state.airChangesPending = !!payload && originalCode !== newCode;
    },
    setDestinationCode: (state: SearchState, action: PayloadAction<string>) => {
      const { payload } = action;

      state.modified = {
        ...state.modified,
        destinationCode: payload,
      };
      state.airChangesPending =
        !!payload && state.original.destinationCode !== payload;
    },
    setFlightBookingInfo: (
      state: SearchState,
      action: PayloadAction<Maybe<IFlightBookingInfoRes>>
    ) => {
      if (action.payload) {
        const {
          context: { airlines, airports },
          itinerary,
        } = action.payload;
        const departureSlice = getDepartureSlice(itinerary.bookedItinerary);
        const returnSlice = getReturnSlice(itinerary.bookedItinerary);
        const outgoingDepSegment = departureSlice.segments[0];
        const outgoingArrSegment = last(departureSlice.segments);
        const returnDepSegment = returnSlice?.segments[0] ?? null;
        const destCode = outgoingArrSegment?.destination.locationCode ?? "";
        const originCode = outgoingDepSegment.origin.locationCode ?? "";

        state.airlineMap = airlines;
        state.airportMap = airports;
        state.booking = itinerary;
        state.dateChangesPending = false;
        state.airChangesPending = false;
        state.modified = getEmptyFlightInfo();
        state.original = {
          destinationArrival: outgoingDepSegment?.updatedArrival ?? "",
          returnArrival: null,
          departureStops: outgoingDepSegment.stops,
          returnStops: returnDepSegment?.stops ?? null,
          returnDuration: getFlightDuration(returnSlice),
          departureDuration: getFlightDuration(departureSlice),
          departureAirlineCode: outgoingDepSegment.marketingAirline.code,
          returnAirlineCode: returnDepSegment?.marketingAirline.code ?? null,
          departureDate: outgoingDepSegment.updatedDeparture ?? "",
          destination: {
            id: {
              Id: IdEnum.Flight,
              code: {
                regionType: RegionType.Airport,
                code: destCode ?? "",
              },
            },
            label: airports[destCode]?.name,
          },
          destinationCode: destCode,
          origin: {
            id: {
              Id: IdEnum.Flight,
              code: {
                regionType: RegionType.Airport,
                code: originCode ?? "",
              },
            },
            label: airports[originCode]?.name,
          },
          originCode: originCode,
          returnDate: null,
        };

        if (returnSlice) {
          state.original.returnArrival = returnDepSegment?.updatedArrival ?? "";
          state.original.returnDate = returnDepSegment?.updatedDeparture ?? "";
          state.tripType = TripCategory.ROUND_TRIP;
        } else {
          state.tripType = TripCategory.ONE_WAY;
        }
      } else {
        state.airlineMap = {};
        state.airportMap = {};
        state.booking = null;
      }
    },
    setOrigin: (
      state: SearchState,
      action: PayloadAction<Maybe<ITripTerminus>>
    ) => {
      const { payload } = action;
      const { code: originalCode } = state.original.origin?.id.code ?? {};
      const { code: newCode } = payload?.id.code ?? {};

      state.modified = {
        ...state.modified,
        origin: payload,
        originCode: payload?.id?.code?.code ?? "",
      };
      state.airChangesPending = !!payload && originalCode !== newCode;
    },
    setOriginCode: (state: SearchState, action: PayloadAction<string>) => {
      const { payload } = action;

      state.modified = {
        ...state.modified,
        originCode: payload,
      };
      state.airChangesPending =
        !!payload && state.original.originCode !== payload;
    },
    setPriceLegend: (state: SearchState, action: PayloadAction<string[]>) => {
      state.priceLegend = action.payload;
    },
    setSelectedPnrHfv2: (state: SearchState, action: PayloadAction<string>) => {
      state.selectedPnrHfv2 = action.payload;
    },
    setReturnDate: (
      state: SearchState,
      action: PayloadAction<Maybe<string>>
    ) => {
      const { returnDate } = state.original;
      const isDiff = !dayjs(returnDate).isSame(dayjs(action.payload), "day");

      state.modified = {
        ...state.modified,
        returnDate: action.payload,
      };
      state.dateChangesPending = isDiff;

      if (!state.dateSelectionDirty) {
        state.dateSelectionDirty = isDiff;
      }
    },
    setTripType: (state: SearchState, action: PayloadAction<TripCategory.ONE_WAY | TripCategory.ROUND_TRIP>) => {
      state.tripType = action.payload;
    },
  },
  // for actions from other slices
  extraReducers: (builder) => {
    builder
      .addCase(setExchangeType, (state, action) => {
        const { outboundSelection, returnSelection } = action.payload;

        // update tripType based on slice selection
        if (
          outboundSelection.ExchangeAction === ExchangeActionEnum.remove ||
          returnSelection?.ExchangeAction === ExchangeActionEnum.remove
        ) {
          state.tripType = TripCategory.ONE_WAY;
        } else if (
          outboundSelection.ExchangeAction === ExchangeActionEnum.keep &&
          returnSelection?.ExchangeAction === ExchangeActionEnum.keep
        ) {
          state.tripType = TripCategory.ROUND_TRIP;
        }
      })
      .addCase(setFlights, (state, action) => {
        const { airlines, airports } = action.payload;

        state.airlineMap = {
          ...state.airlineMap,
          ...airlines,
        };
        state.airportMap = {
          ...state.airportMap,
          ...airports,
        };
      })
      .addDefaultCase((_state, _action) => { });
  },
});

export const {
  resetState,
  setAirportMap,
  setCurrency,
  setDepartureDate,
  setDestination,
  setDestinationCode,
  setFlightBookingInfo,
  setOrigin,
  setOriginCode,
  setPriceLegend,
  setReturnDate,
  setTripType,
  setSelectedPnrHfv2,
} = searchSlice.actions;

export default searchSlice.reducer;

import { compose, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import flightPolicyReducer from "../reducers/flightPolicy";
import flightShopReducer from "../reducers/flightShop";
import flightBookReducer from "../reducers/flightBook";
import searchReducer from "../reducers/search";
import userReducer from "../reducers/user";
import rootSaga from "../sagas";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

//REDUX SAGA middleware
export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools:
    process.env.NODE_ENV !== "production"
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
  reducer: {
    flightShop: flightShopReducer,
    policy: flightPolicyReducer,
    search: searchReducer,
    flightBook: flightBookReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    sagaMiddleware,
  ],
  enhancers: compose,
});

sagaMiddleware.run(rootSaga);

export type ExchangeModuleRootState = ReturnType<typeof store.getState>;

export default store;

import React from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { DateRangePicker, PickerType } from "halifax";
import { useSelector } from "react-redux";
import { IMonthBucket, Maybe } from "redmond";

import { MonthAndDatePickerType } from "../../constants";
import { getCurrency, getPriceLegend } from "../../selectors";

import "./styles.scss";

export interface IMonthAndDatePickerProps {
  className?: string;
  endDate: Maybe<Date>;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  header?: JSX.Element | string;
  months: IMonthBucket[];
  pickerType: PickerType;
  setEndDate: (date: Maybe<Date>) => void;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setStartDate: (date: Maybe<Date>) => void;
  startDate: Maybe<Date>;
  viewType: MonthAndDatePickerType;
}

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const {
    className,
    endDate,
    focusedMonthIndex,
    header,
    months,
    pickerType,
    setEndDate,
    setFocusedMonthIndex,
    setStartDate,
    startDate,
    viewType,
  } = props;
  const currency = useSelector(getCurrency);
  const priceTags = useSelector(getPriceLegend);

  return (
    // Intentionally give this Box a blank aria-label so parent can provide label
    <Box aria-label="">
      {header && (
        <Box
          aria-label="Header."
          className="month-and-date-picker-header-container"
        >
          <Typography variant="body2">{header}</Typography>
        </Box>
      )}
      <DateRangePicker
        className={className}
        minAllowedDate={dayjs().toDate()}
        maxAllowedDate={dayjs().add(1, "year").toDate()}
        months={months}
        priceTags={priceTags}
        currency={currency}
        startDate={startDate}
        endDate={endDate}
        {...(viewType === MonthAndDatePickerType.Horizontal ||
        viewType === MonthAndDatePickerType.Column
          ? { setStartDate, setEndDate, pickerType }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Column
          ? { focusedMonthIndex, columnView: true }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Group
          ? { setFocusedMonthIndex, groupView: true }
          : undefined)}
      />
    </Box>
  );
};

import React, { useCallback, useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { GenericSlider } from "halifax";
import { debounce } from "lodash";
import { ITimeRange } from "redmond";

import { DAY_IN_MINUTES, formats } from "../../../../../../../../constants";

export interface ITimeRangeSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  timeRange: ITimeRange;
}

const defaultProps: Partial<ITimeRangeSliderProps> = {};

const TimeRangeSlider = (props: ITimeRangeSliderProps): JSX.Element => {
  const { className, onChange, timeRange } = props;
  const [value, setValue] = useState(timeRange);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce(onChange, 300, {
      leading: false,
      trailing: true,
    }),
    [onChange]
  );

  const getTimeLabel = (value: number) => {
    const totalTime = dayjs().hour(0).minute(value);

    return totalTime.format(formats.SUMMARY_TIME);
  };

  const handleChange = (min: number, max: number) => {
    debouncedOnChange(min, max);
    setValue({ min, max });
  };

  return (
    <GenericSlider
      chosenMax={value.max}
      chosenMin={value.min}
      className={clsx("time-range-slider-root", className)}
      getLabel={getTimeLabel}
      onChange={handleChange}
      sliderMax={DAY_IN_MINUTES}
      sliderMin={0}
      sliderType={"doubleThumb"}
      step={1}
    />
  );
};

TimeRangeSlider.defaultProps = defaultProps;

export default TimeRangeSlider;

import React from "react";
import { ExpandableCard } from "halifax";
import { IFlightListData } from "redmond";

import { FlightCardType } from "../../../../constants";
import { FlightListItemInfo } from "../FlightListItemInfo";

import "./styles.scss";

export interface ISkeletonFlightListItemProps {
  isMobile: boolean;
  onExpand: (
    sliceId: string,
    selectedFareClass: string,
    flight: IFlightListData
  ) => void;
}

const defaultProps: Partial<ISkeletonFlightListItemProps> = {
  isMobile: false,
};

const SkeletonFlightListItem = (
  props: ISkeletonFlightListItemProps
): JSX.Element => {
  const { isMobile } = props;

  return (
    <ExpandableCard
      scrollExpandedIntoView
      cardKey=""
      className="flight-row flight-list-item b2b"
      content={{
        title: (
          <FlightListItemInfo
            onFareClick={() => {}}
            tripId=""
            type={FlightCardType.skeleton}
          />
        ),
        body: <></>,
      }}
      expandedCardKey="expandedFlight"
      handleCardKeyChange={() => {}}
      isMobile={isMobile}
      key=""
    />
  );
};

SkeletonFlightListItem.defaultProps = defaultProps;

export default SkeletonFlightListItem;

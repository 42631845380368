import { Box, Typography } from "@material-ui/core";
import { CurrencyFormatters as Currency, Icon, IconName } from "halifax";
import React, { useMemo } from "react";

import { confirmCopy } from "../../../../constants";

import "./styles.scss";

export interface IPrevPaymentLineItem {
  amount: number,
  currency: string;
  icon?: IconName;
  label: string;
}

export interface IPrevPaymentSectionProps {
  payments: IPrevPaymentLineItem[];
}

const defaultProps: Partial<IPrevPaymentSectionProps> = {};

const PrevPaymentSection = (props: IPrevPaymentSectionProps): JSX.Element => {
  const { payments } = props;
  const airfareCurrency = payments[0].currency;

  const total = useMemo(() => {
    const sum = payments.reduce((acc, pmnt) => acc + pmnt.amount, 0);

    return Math.max(sum, 0);
  }, [payments]);

  return (
    <Box className="prev-payment-section">
      <Box className="section-title-container">
        <Typography className="section-title" variant="subtitle1">
          {confirmCopy.PREV_PAYMENT}
        </Typography>
        <Typography className="section-total" variant="subtitle2">
          {Currency.get(airfareCurrency).format(total)}
        </Typography>
      </Box>
      <Box className="prev-payment-section-body">
        {payments.map((payment) => {
          const { amount, currency, icon, label } = payment;

          return (
            <Box className="prev-payment-line" key={label}>
              {icon && <Icon className="payment-icon" name={icon} />}
              <Typography className="label" variant="subtitle2">
                {label}
              </Typography>
              <Typography className="value" variant="subtitle2">
                {Currency.get(currency).format(amount)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

PrevPaymentSection.defaultProps = defaultProps;

export default PrevPaymentSection;

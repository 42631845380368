import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FlightDetailsSummary, Restriction } from "halifax";
import { IRestrictionProps } from "halifax/build/Restriction/component";
import { last, startCase } from "lodash";
import { FareDetails, ShopSymbol, TripDetails } from "redmond";
import { DullesUta } from "redmond/apis/dulles/interfaces";

import { ClientContext } from "../../../../App";
import { reviewCopy } from "../../../../constants";
import { getPlusDays, getSliceIndex } from "../../../../utils/helpers";

import "./styles.scss";

export interface IReviewItineraryDetailsProps {
  fareDetails: FareDetails;
  isDeparture: boolean;
  isMixedCabinClass: boolean;
  isMobile: boolean;
  isMultiTicket: boolean;
  tripDetails: TripDetails;
}

const defaultProps: Partial<IReviewItineraryDetailsProps> = {
  isDeparture: false,
  isMixedCabinClass: false,
  isMobile: false,
  isMultiTicket: false,
};

const ReviewItineraryDetails = (
  props: IReviewItineraryDetailsProps
): JSX.Element => {
  const {
    fareDetails,
    isDeparture,
    isMixedCabinClass,
    isMobile,
    isMultiTicket,
    tripDetails,
  } = props;
  const clientContext = useContext(ClientContext);
  const fareIndex = fareDetails.slices.findIndex((slice) =>
    isDeparture ? slice.outgoing : !slice.outgoing
  );
  const fare = fareDetails.slices[fareIndex];
  const sliceIndex = getSliceIndex(isDeparture, tripDetails);
  const slice = isDeparture ? tripDetails.slices[0] : tripDetails.slices[1];
  const firstSlice = tripDetails.slices[0];
  const lastSlice = last(tripDetails.slices);
  const plusDays = getPlusDays(slice);

  const getRestrictions = () => {
    const { utas } = fareDetails.slices[sliceIndex].amenitiesUtas?.utas ?? {};

    if (utas) {
      const restrictions: IRestrictionProps[] = utas.map((uta: DullesUta) => {
        const { assessment, category, description } = uta;
        let symbol = ShopSymbol.UNAVAILABLE;

        if (assessment === "benefit") symbol = ShopSymbol.INCLUDED;
        else if (assessment === "fee") symbol = ShopSymbol.PURCHASABLE;

        return {
          descriptionString: description,
          symbol,
          name: category
            .split("-")
            .reduce(
              (name: string, segment: string) =>
                `${name}${name.length > 0 ? " " : ""}${startCase(segment)}`,
              ""
            ),
        };
      });

      return restrictions;
    }

    return [];
  };

  return (
    <Box className={clsx("review-itinerary-details-root", { isMobile })}>
      <Box className="details-container">
        <FlightDetailsSummary
          className="flight-details"
          departureTime={
            isDeparture ? firstSlice.departureTime : lastSlice!.departureTime
          }
          fareClass={fare.fareShelf?.shortBrandName ?? ""}
          fareSlice={
            isDeparture ? fareDetails.slices[0] : fareDetails.slices[1]
          }
          isMixedCabinClass={isMixedCabinClass}
          planeInfo={
            fare.amenitiesUtas?.amenities?.aircraft.info.displayText ?? ""
          }
          plusDays={plusDays}
          segments={
            isDeparture ? firstSlice.segmentDetails : lastSlice!.segmentDetails
          }
          isReshop
        />
        <Box className="restriction-details">
          <Box className="restriction-image-overlay-with-text">
            {clientContext.itineraryReviewBackground && (
              <img
                alt="itinerary-review-background"
                className="itinerary-review-background"
                src={clientContext.itineraryReviewBackground}
              />
            )}
            <Box className="itinerary-overlay-text">
              <Typography className="title">
                {isMultiTicket
                  ? reviewCopy.COMBINATION_HEADER
                  : reviewCopy.HEADER}
              </Typography>
              <Typography className="subtitle">
                {isMultiTicket ? reviewCopy.COMBINATION_SUBHEADER : ""}
              </Typography>
            </Box>
          </Box>
          <Box className="restrictions-section">
            {getRestrictions().map((restriction) => (
              <Restriction
                description={
                  <Typography className="description" variant="subtitle2">
                    {restriction.descriptionString}
                  </Typography>
                }
                key={restriction.name}
                name={restriction.name}
                symbol={restriction.symbol}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ReviewItineraryDetails.defaultProps = defaultProps;

export default ReviewItineraryDetails;

import { AncillaryKindEnum } from "redmond";

export const confirmCopy = {
  BASE_FARE: "Base Fare",
  BOOK_FINALIZE_LOADING_TITLE: "Booking your new flight",
  BOOK_FINALIZE_LOADING_SUBTITLE: "",
  CFAR_COVERAGE: "Cancel for Any Reason Booking Fee Coverage", // BOPS-501 temp removed colon
  CHANGE_FEE: "Airline Change Fee",
  CHECKOUT_BREAKDOWN_TITLE: "Traveler and Pricing Summary",
  DEFAULT_CASH_CURRENCY: "USD",
  DEFAULT_REDEMPTION_RATE: 100,
  DEFAULT_REWARDS_CURRENCY: "Miles",
  DEFAULT_REWARDS_CURRENCY_DESC: "miles",
  EXCHANGE_BOOK_SUCCESS_TITLE: "Flight Changed",
  EXCHANGE_BOOK_SUCCESS_SUBTITLE: "Your flight has been successfully changed. You will receive a schedule change email confirmation shortly.",
  FTC_APPLIED: "Travel credit applied:",
  IMPORTANT_INFO: "Important Information",
  ISSUE_SUBMITTING_REQ: "There was an issue submitting your request",
  ISSUE_UPDATING_PRICE: "There was an issue fetching the latest price",
  PREV_AIRFARE_VALUE: "Previous Airfare Value",
  PREV_BOOKING_CREDIT: "Credit for Previous Booking",
  PREV_CARD_CHARGED: "Previously charged card",
  PREV_PAYMENT: "Previous Payment",
  PRICE_FREEZE_CREDIT: "Less Price Freeze Credit",
  PRICE_QUOTE_LOADING_TITLE: "Retrieving updated price quote",
  PRICE_QUOTE_LOADING_SUBTITLE: "Confirming we have the best price for you",
  REVIEW_TITLE: "Review Your Change Request",
  SEATS_PURCHASED: "Seats Purchased",
  SUBMIT_SUCCESS: "Change Requested",
  SUBMIT_SUCCESS_SUBTITLE:
    "We are reviewing your request and will confirm completion within 2-4 hours. If your request is approved, you'll receive an email confirmation. Our team will contact you by phone in the event that your chosen flights are unavailable.",
  SUBMIT_TRY_AGAIN: "Please try again or contact support for assistance.",
  TAXES_FEES: "Taxes & Fees",
  TOTAL: "Total",
  TOTAL_PAYABLE: "Total Payable",
  UPDATED_PRICE: "Updated Booking Price",
  WALLET_CREDIT: "Less Travel Credit Applied",
  WALLET_OFFER: "Less Travel Offer Applied",
};

export const ancillaryLabels: Record<AncillaryKindEnum, string> = {
  CancelOrChange: "Cancel Or Change Protection",
  Cfar: "Cancel For Any Reason Protection",
  Chfar: "Change For Any Reason Protection",
  Delay: "Delay Protection",
  Insurance: "Travel Insurance",
  Luggage: "Luggage",
  MissedConnection: "Missed Connection Protection",
};

export default confirmCopy;

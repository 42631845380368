import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  Amount,
  ExchangeScenario,
  IExchangeCopy,
  IFTCExchangePolicy,
  INonChfarExchangePolicy,
  INonChfarVoidableExchangeable,
  NonChfarScenario,
  Policy,
} from "redmond";

import { ExchangeModuleRootState } from "../store";
import { getPolicyAttr } from "../utils/helpers";
import { getTravelCredit } from "./search";

export const getFlightExchangePolicy = (state: ExchangeModuleRootState) =>
  state.policy.policy;

export const getBannerCopy = (state: ExchangeModuleRootState) => {
  const policy = getFlightExchangePolicy(state);

  return getPolicyAttr("bannerCopy", policy);
};

export const getCustomerServiceCopy = (state: ExchangeModuleRootState) => {
  const policy = getFlightExchangePolicy(state);

  return getPolicyAttr("customerServiceCopy", policy);
};

export const getExchangeFee = (state: ExchangeModuleRootState) => {
  const policy = getFlightExchangePolicy(state);

  return getPolicyAttr("changeFee", policy);
};

export const getExchangeScenario = (state: ExchangeModuleRootState) =>
  getFlightExchangePolicy(state)?.ExchangeScenario;

export const getPolicyHasCfar = (state: ExchangeModuleRootState) => {
  const policy = getFlightExchangePolicy(state);

  return getPolicyAttr("hasCfar", policy);
};

export const getOriginalExchangeFee = (
  state: ExchangeModuleRootState
): Amount => {
  const ftc = getTravelCredit(state);
  const policy = getFlightExchangePolicy(state);
  const ogChangeFee = getPolicyAttr("originalChangeFee", policy);

  return {
    amount: ogChangeFee?.amount ?? 0,
    currency: ogChangeFee?.currency ?? ftc?.credit.currency,
  };
};

export const getScenarioCopy = createSelector(getFlightExchangePolicy, (policy) => {
  let copy = null;

  if (policy) {
    const { ExchangeScenario: scenario } = policy;

    if (scenario === ExchangeScenario.ftc) copy = policy;
    else if (scenario === ExchangeScenario.nonChfar) {
      const { NonChfar } = policy as INonChfarExchangePolicy;
      const { exchangeableScenario, isExchangeable } = policy as INonChfarVoidableExchangeable;

      if (NonChfar === NonChfarScenario.exchangeable && !isExchangeable) copy = policy;
      else if (NonChfar === NonChfarScenario.ticketedVoidable && isExchangeable) {
        copy = exchangeableScenario;
      }
    }
  }
  
  return copy;
});

export const getConfirmPageCopy = createSelector(
  getScenarioCopy,
  (scenarioCopy) => {
    if (scenarioCopy && "exchangeConfirmationCopy" in scenarioCopy)
      return scenarioCopy.exchangeConfirmationCopy;

    return null;
  }
);

export const getExchangeCopy = createSelector(
  getScenarioCopy,
  (scenarioCopy) => {
    if (scenarioCopy && "exchangeCopy" in scenarioCopy)
      return scenarioCopy.exchangeCopy;

    return null;
  }
);

export const getHeaderCopy = createSelector(
  getFlightExchangePolicy,
  getScenarioCopy,
  (policy, scenarioCopy) => {
    let headerCopy: any = {
      confirm: undefined,
      search: undefined,
    };

    if (policy && scenarioCopy) {
      const { ExchangeScenario: scenario } = policy;

      if (scenario === ExchangeScenario.ftc) {
        const { bannerCopy } = scenarioCopy as IFTCExchangePolicy;

        headerCopy.search = {
          body: bannerCopy?.body[0] ?? "", // only display first sentence in header
          title: bannerCopy?.title ?? "",
        };
      } else if (scenario === ExchangeScenario.nonChfar) {
        const { confirmationBannerCopy, exchangeBannerCopy } =
          scenarioCopy as IExchangeCopy;

        headerCopy = {
          confirm: confirmationBannerCopy,
          search: exchangeBannerCopy,
        };
      }
    }

    return headerCopy;
  }
);

export const getRebookDeadline = createSelector(
  getFlightExchangePolicy,
  (policy) => {
    if (policy?.ExchangeScenario === ExchangeScenario.ftc) {
      return dayjs(policy.rebookDeadlineMillis);
    }

    return null;
  }
);

export const getTravelDeadline = createSelector(
  getFlightExchangePolicy,
  (policy) => {
    if (policy?.ExchangeScenario === ExchangeScenario.ftc) {
      return dayjs(policy.travelDeadlineMillis);
    }

    return null;
  }
);

export const getVoidWindowEnd = createSelector(
  getFlightExchangePolicy,
  (policy) => {
    const voidWindowEnd = getPolicyAttr("voidWindowEnd", policy);

    if (voidWindowEnd) return dayjs(voidWindowEnd);
    return null;
  }
);

export const getPolicies = createSelector(
  getFlightExchangePolicy,
  (policy) => {
    return getPolicyAttr<Policy[]>("policies", policy) ?? [];
  }
);

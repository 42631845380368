import { ExchangeModuleRootState } from "../store";

export const getFlightBook = (state: ExchangeModuleRootState) =>
  state.flightBook;

export const getSessionId = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).sessionId;
};

export const getCartQuoteId = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).cartQuoteId;
};

export const getIdempotencyKey = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).idempotencyKey;
};

export const getPriceQuote = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).priceQuote;
};

export const getPaymentMethods = (state: ExchangeModuleRootState) => {
  return getFlightBook(state).paymentMethods;
};

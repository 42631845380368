import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IFlightExchangePolicyRes, Maybe } from "redmond";

import { resetState } from "./search";

export interface FlightPolicyState {
  policy: Maybe<IFlightExchangePolicyRes>;
}

const initialState: FlightPolicyState = {
  policy: null,
};

export const FlightPolicySlice = createSlice({
  initialState,
  name: "exchange/flightPolicy",
  reducers: {
    setExchangePolicy(
      state: FlightPolicyState,
      action: PayloadAction<IFlightExchangePolicyRes>
    ) {
      state.policy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addDefaultCase((_state, _action) => {});
  },
});

export const { setExchangePolicy } = FlightPolicySlice.actions;

export default FlightPolicySlice.reducer;

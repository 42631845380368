import axios from "axios";
import { TripDetails } from "redmond";

import { config } from "../../config";
import { exchangeTripDetailsPath } from "../paths";
import mockDetails from "../../../utils/mockTripDetails.json";

export const fetchTripDetails = async (tripId: string, isHackerfareReturn: boolean | undefined, mockDataExp = false) => {
  try {
    if (mockDataExp) {
      const tripDetails = {
        ...mockDetails,
        id: tripId,
      };

      return Promise.resolve(tripDetails as unknown as TripDetails);
    }

    const req = {tripId, isHackerfareReturn};
    const res =
      await axios.post<TripDetails>(exchangeTripDetailsPath, req, {
        baseURL: config.baseURL,
      });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

import React, { createContext, useMemo, useState } from "react";
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import { AgentBanner } from "halifax";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import {
  ExchangeClientAssetProps,
  ExchangeModuleProps,
  installColorConfig,
} from "redmond";

import AxiosInterceptors from "./components/AxiosInterceptors";
import { RewardsBanner } from "./components/RewardsBanner";
import Body from "./components/Body";
import ExperimentsProvider from "./context/experiments";
import UserSourceProvider from "./context/userSource";
import useWindowEventListener from "./hooks/useWindowEventListener";
import * as englishTranslations from "./lang/en.json";
import * as frenchTranslations from "./lang/fr.json";
import store from "./store";
import { eventsToListen } from "./utils/events";

import "./styles.scss";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptExchangeModule",
  seed: "ptExchangeModule",
});

export const ClientContext = createContext<Partial<ExchangeClientAssetProps>>(
  {}
);

const App = (props: ExchangeModuleProps) => {
  const {
    baseHistory,
    clientAssets,
    colors,
    experiments,
    isAgentPortal,
    language,
    theme,
  } = props;
  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(language).default);
  const {
    sessionInfo: { userInfo: { firstName = "", lastName = "" } = {} } = {},
  } = clientAssets;

  const clientContext = useMemo(
    () => ({
      ...clientAssets,
      isAgentPortal: !!isAgentPortal,
    }),
    [clientAssets, isAgentPortal]
  );

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };

  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };

  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <UserSourceProvider>
          <Router history={baseHistory}>
            <AxiosInterceptors isAgentPortal={isAgentPortal} />
            <ClientContext.Provider value={clientContext}>
              <div className="App">
                <StylesProvider generateClassName={generateClassName}>
                  <ThemeProvider theme={activeTheme}>
                    {messages != null ? (
                      <IntlProvider
                        defaultLocale="en"
                        locale={locale}
                        messages={messages}
                      >
                        {isAgentPortal ? (
                          <AgentBanner
                            firstName={firstName}
                            lastName={lastName}
                          />
                        ) : (
                          <RewardsBanner />
                        )}
                        <Body />
                      </IntlProvider>
                    ) : (
                      <div>Loading</div>
                    )}
                  </ThemeProvider>
                </StylesProvider>
              </div>
            </ClientContext.Provider>
          </Router>
        </UserSourceProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export default App;

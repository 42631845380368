import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionButton,
  B2BSpinner,
  CurrencyFormatters,
  FlightPricingLineItem,
  IconName,
  IMarkdownLineItem,
  IPaymentLineItem,
  ISummaryLineItem,
} from "halifax";
import { last } from "lodash";
import React, {
  MutableRefObject,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import {
  Amount,
  ExchangeScenario,
  FareDetails,
  IExchangePriceQuote,
  Maybe,
  PaymentAmountEnum,
  PaymentLineItemAncillaryCredit,
  PaymentLineItemAncillaryCreditEnum,
  PaymentLineItemEnum,
  PaymentLineItemRewards,
  PaymentLineItemTravelWallet,
  PaymentLineItemTravelWalletEnum,
  PaymentLineItemUserCard,
  RewardsPrice,
} from "redmond";

import { ancillaryLabels, buttonText, confirmCopy } from "../../../../constants";
import {
  getAncillariesPayment,
  getExchangeFee,
  getExchangeScenario,
  getOriginalExchangeFee,
  getOriginalPaxPricing,
  getPassengers,
  getPayments,
  getPolicyHasCfar,
  getSeatPayments,
  getShoppedTrip,
  getSubTotalPayment,
  getTravelCredit,
  getTripDetails,
} from "../../../../selectors";
import { ExchangeModuleRootState } from "../../../../store";
import { IPrevPaymentLineItem } from "../PrevPaymentSection";
import { PricingSummary } from "../PricingSummary";

import "./styles.scss";

export interface IPaymentInfo {
  cardInfo: string;
  cashPaid?: Amount;
  pfCredit: number;
  prevPayments: IPrevPaymentLineItem[];
  rewardsPaid?: {
    PaymentAmount: PaymentAmountEnum;
    rewardsCashAmt: number;
    rewardsCashCurrency: string;
    rewardsPrice: RewardsPrice;
  };
}

export interface ICheckoutBreakdownProps {
  isMobile?: boolean;
  isSubmitting?: boolean;
  onSubmit: () => Promise<void>;
  totalPriceRef: MutableRefObject<string>;
  submitButtonText: string;
  enableSubmitButton: boolean;
  priceQuote: Maybe<IExchangePriceQuote>;
}

const defaultProps: Partial<ICheckoutBreakdownProps> = {
  isMobile: false,
  isSubmitting: false,
  submitButtonText: buttonText.SUBMIT_REQUEST,
  enableSubmitButton: false,
};

const CheckoutBreakdown = (props: ICheckoutBreakdownProps): JSX.Element => {
  const {
    isMobile,
    isSubmitting,
    onSubmit,
    totalPriceRef,
    submitButtonText,
    enableSubmitButton,
    priceQuote,
  } = props;
  const paymentLineRef = useRef<IPaymentLineItem>();
  const totalChangeFeeRef = useRef(0);

  const ancillaryPayments = useSelector(getAncillariesPayment);
  const exchangeFee = useSelector(getExchangeFee);
  const ogExchangeFee = useSelector(getOriginalExchangeFee);
  const ogPaxPricings = useSelector(getOriginalPaxPricing);
  const policyHasCfar = useSelector(getPolicyHasCfar);
  const scenario = useSelector(getExchangeScenario);
  const passengers = useSelector(getPassengers);
  const payments = useSelector(getPayments);
  const { outgoingFareId, returnFareId, tripId } = useSelector(getShoppedTrip);
  const seatPayments = useSelector(getSeatPayments);
  const travelCredit = useSelector(getTravelCredit);
  const subTotalPayment = useSelector(getSubTotalPayment);
  const tripDetails = useSelector((state: ExchangeModuleRootState) =>
    getTripDetails(state, tripId ?? "")
  );

  const isFTC = scenario === ExchangeScenario.ftc;

  const updatePricingFromPriceQuote = (
    originalBase: number,
    originalTax: number,
    shopBase: number,
    shopTax: number,
    passengerType: any,
  ) => {
    if (priceQuote && priceQuote.records.length > 0) {
      const { records } = priceQuote!;
      const quotePaxTypePricing = records?.find(
        (pricing) => {
          return pricing.passengerType === passengerType
        }
      );

      const quotePaxPricingInfo = quotePaxTypePricing?.pricingInfo;
      const quoteBase = quotePaxPricingInfo?.baseAmount ?? 0;
      const quoteTax = quotePaxPricingInfo?.taxAmount ?? 0;

      return {
        base: originalBase + quoteBase,
        tax: originalTax + quoteTax,
      };
    } else {
      return {
        base: originalBase + shopBase,
        tax: originalTax + shopTax,
      };
    }
  }

  const getPricingInfo = (type: any, tripFareDetails?: FareDetails) => {
    const { paxPricings = [] } = tripFareDetails ?? {};
    const ogPTypePricing = ogPaxPricings?.find(
      (pricing) => {
        return pricing.passengerType === type
      }
    );
    const pTypePricing = paxPricings.find(
      (pricing) => pricing.paxType === type
    );
    const { baseWithoutMargin, taxes } = ogPTypePricing ?? {};
    const { baseAmount, taxAmount } = pTypePricing?.pricing ?? {};
    const ogBase = baseWithoutMargin?.fiat.value ?? 0;
    const ogTax = taxes?.fiat.value ?? 0;
    const currBase = baseAmount?.fiat.value ?? 0;
    const currTax = taxAmount?.fiat.value ?? 0;

    return updatePricingFromPriceQuote(
      ogBase,
      ogTax,
      currBase,
      currTax,
      type,
    )
  };

  const newRewardsPrice = (cashAmt: number): RewardsPrice => ({
    currency: confirmCopy.DEFAULT_REWARDS_CURRENCY,
    currencyDescription: confirmCopy.DEFAULT_REWARDS_CURRENCY_DESC,
    value: cashAmt * confirmCopy.DEFAULT_REDEMPTION_RATE,
  });

  const submitRequest = async () => {
    await onSubmit();
  };

  // categorize payment breakdown
  const payment = useMemo(() => {
    const paymentInfo: IPaymentInfo = {
      cardInfo: confirmCopy.PREV_CARD_CHARGED,
      pfCredit: 0,
      prevPayments: [],
    };
    let prevAirfareAmt = 0, prevAirfareCurrency = "";

    if (subTotalPayment) {
      const { currencyCode, value } = subTotalPayment.fiat;

      prevAirfareAmt = value;
      prevAirfareCurrency = currencyCode;
    }

    if (seatPayments?.length) {
      let amount = 0;
      let currency = "";

      amount = seatPayments.reduce((acc, seat) => {
        currency = seat.currency;

        return acc + seat.price;
      }, amount);

      if (amount > 0 && currency) {
        paymentInfo.prevPayments.push({
          amount,
          currency,
          label: confirmCopy.SEATS_PURCHASED,
        });
      }
    }

    if (ancillaryPayments?.length) {
      ancillaryPayments.forEach((ancil) => {
        const {
          kind,
          premium: {
            fiat: { currencyCode, value },
          },
        } = ancil;
        const label = ancillaryLabels[kind];

        if (value > 0 && currencyCode && label) {
          paymentInfo.prevPayments.push({
            label,
            amount: value,
            currency: currencyCode,
          });
        }
      });
    }

    // ancillary credit, travel wallet, and travel offers
    if (payments?.length) {
      let accountDisplayName = "";
      let cardNumberLastFour = "";

      payments.forEach((pmnt) => {
        const { PaymentLineItem: type } = pmnt;
        let amount = 0, currency = "", label = "";

        switch (type) {
          case PaymentLineItemEnum.AncillaryCredit: {
            ({
              AncillaryCredit: label,
              amount: { amount, currency },
            } = pmnt as PaymentLineItemAncillaryCredit);

            if (
              label === PaymentLineItemAncillaryCreditEnum.PriceFreezeCredit
            ) {
              paymentInfo.pfCredit = amount;
              /*
               * subtotal includes price freeze credit already so it needs to be
               * added back in to get the total airfare value
               */
              prevAirfareAmt += amount;
              amount *= -1; // price freeze credit is a deduction in prev payment
              label = confirmCopy.PRICE_FREEZE_CREDIT;
            }
            break;
          }
          case PaymentLineItemEnum.Rewards: {
            ({ accountDisplayName } = pmnt as PaymentLineItemRewards);
            break;
          }
          case PaymentLineItemEnum.TravelWallet: {
            ({
              TravelWallet: label,
              amount: { amount, currency },
            } = pmnt as PaymentLineItemTravelWallet);

            if (label === PaymentLineItemTravelWalletEnum.TravelWalletCredit) {
              label = confirmCopy.WALLET_CREDIT;
            } else if (label === PaymentLineItemTravelWalletEnum.TravelWalletOffer) {
              label = confirmCopy.WALLET_OFFER;
            }

            amount *= -1; // wallet credits/offers are deductions
            break;
          }
          case PaymentLineItemEnum.UserCard: {
            const { cardNumberDisplay = "" } = pmnt as PaymentLineItemUserCard;
            
            cardNumberLastFour = last(cardNumberDisplay.split("-")) ?? "";
            break;
          }
          default:
        }

        if (amount !== 0 && currency && label) {
          paymentInfo.prevPayments.push({ amount, currency, label });
        }
      });

      if (accountDisplayName && cardNumberLastFour) {
        paymentInfo.cardInfo = `${cardNumberLastFour} / ${accountDisplayName}`;
      } else if (accountDisplayName) {
        paymentInfo.cardInfo = accountDisplayName;
      } else if (cardNumberLastFour) {
        paymentInfo.cardInfo = cardNumberLastFour;
      }
    }

    if (prevAirfareAmt > 0 && prevAirfareCurrency) {
      paymentInfo.prevPayments.unshift({
        amount: prevAirfareAmt,
        currency: prevAirfareCurrency,
        label: confirmCopy.PREV_AIRFARE_VALUE
      });
    }

    return paymentInfo;
  }, [ancillaryPayments, payments, seatPayments, subTotalPayment]);

  // exchanged flight cost per passenger
  const pricingItems = useMemo(() => {
    const items: FlightPricingLineItem[] = [];
    const tripFareDetails = tripDetails?.fareDetails.find(
      (fd) => fd.id === outgoingFareId || fd.id === returnFareId
    );

    if (passengers && outgoingFareId) {
      const { alone, withLapInfants } = passengers;
      let pfCreditAmt = 0;

      if (payment.pfCredit) {
        const numTravelers = alone.length + withLapInfants.length;
        
        pfCreditAmt = payment.pfCredit / numTravelers;
      }

      for (let i = 0; i < alone.length; i += 1) {
        const {
          person: { givenName, surname },
          type: pType,
        } = alone[i];
        const outPricingInfo = getPricingInfo(pType, tripFareDetails);
        // if flight has CFAR, exchangeFee is zeroed out so number provided in ogExchangeFee
        const changeFee = policyHasCfar ? ogExchangeFee : exchangeFee;
        // BOPS-501 Temp fix to hide change fees for FTCs with CFAR
        const changeFeeAmt = policyHasCfar ? undefined : changeFee?.amount ?? 0;

        items.push({
          baseAmount: outPricingInfo.base + pfCreditAmt,
          changeFee: changeFeeAmt,
          lineTitle: `${givenName} ${surname}`,
          taxesAndFees: outPricingInfo.tax,
        });

        if (ogExchangeFee) {
          totalChangeFeeRef.current += ogExchangeFee.amount;
        }
      }

      for (let i = 0; i < withLapInfants.length; i += 1) {
        const {
          adult: {
            person: { givenName: adtFirstName, surname: adtSurname },
            type: pType,
          },
          infant: {
            person: { givenName: infFirstName, surname: infSurname },
          },
        } = withLapInfants[i];
        const adultName = `${adtFirstName} ${adtSurname}`;
        const infantName = `${infFirstName} ${infSurname}`;
        const outPricingInfo = getPricingInfo(pType, tripFareDetails);
        // if flight has CFAR, exchangeFee is zeroed out so number provided in ogExchangeFee
        const changeFee = policyHasCfar ? ogExchangeFee : exchangeFee;
        // BOPS-501 Temp fix to hide change fees for FTCs with CFAR
        const changeFeeAmt = policyHasCfar ? undefined : changeFee?.amount ?? 0;

        items.push(
          {
            baseAmount: outPricingInfo.base + pfCreditAmt,
            changeFee: changeFeeAmt,
            lineTitle: `${adultName}`,
            taxesAndFees: outPricingInfo.tax,
          },
          {
            baseAmount: 0,
            changeFee: policyHasCfar ? undefined : 0, // BOPS-501
            lineTitle: `${infantName} (on lap)`,
            taxesAndFees: 0,
          }
        );

        if (policyHasCfar && ogExchangeFee) {
          totalChangeFeeRef.current += ogExchangeFee.amount;
        }
      }
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeFee, outgoingFareId, passengers, payment, tripDetails, priceQuote]);

  // subtotal + prev payment or FTC value
  const summaryItems = useMemo(() => {
    const items: ISummaryLineItem[] = [];
    const shoppedBookingPrice = pricingItems.reduce((sum, pricingItem) => {
      const { baseAmount = 0, changeFee = 0, taxesAndFees = 0 } = pricingItem;

      return sum + baseAmount + changeFee + taxesAndFees;
    }, 0);
    const { cardInfo, prevPayments } = payment;
    let total = shoppedBookingPrice;

    items.push({
      fiatPrice: {
        currencyCode: confirmCopy.DEFAULT_CASH_CURRENCY,
        currencySymbol: CurrencyFormatters.getSymbol(
          confirmCopy.DEFAULT_CASH_CURRENCY
        ),
        value: shoppedBookingPrice,
      },
      label: isFTC ? confirmCopy.TOTAL : confirmCopy.UPDATED_PRICE,
      type: "custom",
    });

    if (!isFTC && prevPayments.length) {
      const airfareValue = prevPayments.find(
        (p) => p.label === confirmCopy.PREV_AIRFARE_VALUE
      );

      if (airfareValue) {
        const { amount, currency } = airfareValue;

        total -= airfareValue.amount;
        items.push({
          fiatPrice: {
            currencyCode: currency,
            currencySymbol: CurrencyFormatters.getSymbol(currency),
            value: amount
          },
          label: confirmCopy.PREV_BOOKING_CREDIT,
          type: "custom",
        });
      }
    } else if (isFTC && travelCredit) {
      const { amount, currency } = travelCredit.credit;

      total -= amount;
      items.push({
        fiatPrice: {
          currencyCode: currency,
          currencySymbol: CurrencyFormatters.getSymbol(currency),
          value: -1 * amount,
        },
        label: confirmCopy.FTC_APPLIED,
        type: "markdown",
      } as IMarkdownLineItem);
    }

    // BOPS-501: Temp fix until FTC CFAR change fees are sent by TRVK
    if (false && policyHasCfar) {
      const { currency } = ogExchangeFee;
      const { current: totalChangeFee } = totalChangeFeeRef;

      items.push({
        fiatPrice: {
          currencyCode: currency,
          currencySymbol: CurrencyFormatters.getSymbol(currency),
          value: -1 * totalChangeFee,
        },
        icon: IconName.CheckShieldBlue,
        label: confirmCopy.CFAR_COVERAGE,
        rewardsPrice: newRewardsPrice(-1 * totalChangeFee),
        type: "custom",
      });

      total -= totalChangeFee;
    }

    total = Math.max(total, 0);
    paymentLineRef.current = {
      fiatPrice: {
        currencyCode: confirmCopy.DEFAULT_CASH_CURRENCY,
        currencySymbol: CurrencyFormatters.getSymbol(
          confirmCopy.DEFAULT_CASH_CURRENCY
        ),
        value: total,
      },
      rewardsPrice: newRewardsPrice(total),
      label: confirmCopy.TOTAL_PAYABLE,
      lastFour: cardInfo,
      type: "payment",
    };

    // value used in parent component - ConfirmFlightExchange
    totalPriceRef.current = CurrencyFormatters.get().format(total);

    return items;
  }, [payment, pricingItems, subTotalPayment]);

  return (
    <Box className={clsx("checkout-breakdown-root", { mobile: isMobile })}>
      <Typography className="checkout-breakdown">
        {confirmCopy.CHECKOUT_BREAKDOWN_TITLE}
      </Typography>
      <PricingSummary
        payments={payment.prevPayments}
        totalPayable={paymentLineRef.current}
        summaryItems={summaryItems}
        passengerItems={pricingItems}
      />
      <ActionButton
        className="submit-request-btn"
        disabled={isSubmitting || !enableSubmitButton}
        message={
          isSubmitting ? (
            <B2BSpinner className="processing-submission-spinner" />
          ) : (
            submitButtonText
          )
        }
        onClick={submitRequest}
      />
    </Box>
  );
};

CheckoutBreakdown.defaultProps = defaultProps;

export default CheckoutBreakdown;

import React, { useMemo } from "react";
import clsx from "clsx";
import { RadioDropdown } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { FlightSortOption } from "redmond";

import { sortOptions } from "../../../../constants/flightShop";
import { setSortOption } from "../../../../reducers/flightShop";
import { getSortOption } from "../../../../selectors";

import "./styles.scss";

export interface ISortOptionSelectProps {
  isMobile: boolean;
}

const defaultProps: Partial<ISortOptionSelectProps> = {
  isMobile: false,
};

const SortOptionSelect = (props: ISortOptionSelectProps): JSX.Element => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const sortOption = useSelector(getSortOption);

  const dropdownLabel = useMemo(() => {
    const selOption = sortOptions.find((opt) => opt.value === sortOption);
    const [shortenedLabel] = selOption?.label?.split(" ") ?? [];

    return shortenedLabel;
  }, [sortOption]);

  return (
    <RadioDropdown
      buttonAriaLabel="Change Sort Option"
      buttonClassName={clsx("b2b-shop-filter reshop-sort-btn", {
        mobile: isMobile,
      })}
      dropdownLabel={dropdownLabel}
      groupAriaLabel="Select a Sort Option"
      options={sortOptions.map(({ label, value }) => ({
        label,
        value,
      }))}
      popoverClassName="b2b"
      selected={sortOption}
      setOption={(option) =>
        dispatch(setSortOption(option as FlightSortOption))
      }
    />
  );
};

SortOptionSelect.defaultProps = defaultProps;

export default SortOptionSelect;

import { PATH_CORP_FAQ, isCorpTenant } from "@capone/common";
import { config } from "../api/config";

export const SUPPORT_LINK = "Support";

export const SUPPORT_LINK_URL = isCorpTenant(config.TENANT)
  ? PATH_CORP_FAQ
  : "https://www.capitalone.com/help-center/capital-one-travel/";

export const CONTACT_SUPPORT_HEADER = "Get Help With Capital One Travel";
export const CONTACT_SUPPORT_HEADER_2 = "Contact Support";
export const CONTACT_SUPPORT_TEXT_1 =
  "For information regarding Seats, Baggage, Changes, Cancellations and Refunds, please visit";
export const FREQUENTLY_ASKED_QUESTIONS = "Frequently Asked Questions";
export const CONTACT_SUPPORT_TEXT_2 =
  "For any questions regarding your trip, please contact our support team and we’d be happy to help.";
export const CONTACT_SUPPORT_NUMBER = "844-422-6922";
export const CONTACT_F9_SUBTITLE =
  "Your booking is managed by the airline. To change, cancel, or modify your flight, contact Frontier directly or visit their website.";

export const QUESTIONS_ABOUT_CAP1_TRAVEL =
  "Questions about Capital One Travel?";

export const GET_HELP = "Get Help";
export const SIGN_IN = "Sign in";

import { createSelector } from "@reduxjs/toolkit";
import {
  cardsToShowWithPriorityOnRewardsBanner,
  RewardsAccount,
} from "redmond";

import { ExchangeModuleRootState } from "../store";

export const getUser = (state: ExchangeModuleRootState) => state.user;

export const getAgentEmail = (state: ExchangeModuleRootState) =>
  getUser(state).agentEmail;

export const getIsFirstLaunch = (state: ExchangeModuleRootState) =>
  getUser(state).isFirstLaunch;

export const getRewardsAccountCallState = (state: ExchangeModuleRootState) =>
  getUser(state).rewardsAccountCallState;

export const getRewardsAccounts = (state: ExchangeModuleRootState) =>
  getUser(state).rewardsAccounts;

export const getSelectedAccountRefId = (state: ExchangeModuleRootState) =>
  getUser(state).selectedAccountRefId;

export const getSelectedAccount = createSelector(
  getRewardsAccounts,
  getSelectedAccountRefId,
  (rewardsAccounts, accountRefId) =>
    rewardsAccounts.find((act) => act.accountReferenceId === accountRefId)
);

export const getSelectedAccountReferenceIdIfRedemptionEnabled = createSelector(
  getRewardsAccounts,
  getSelectedAccountRefId,
  (rewardsAccounts, accountRefId) => {
    const account = rewardsAccounts.find(
      (act) => act.accountReferenceId === accountRefId
    );

    if (account && (account?.allowRewardsRedemption ?? true)) {
      return account.accountReferenceId;
    }

    return null;
  }
);

export const getRewardsUserProperties = createSelector(
  getSelectedAccount,
  (rewardsAccount) => ({
    currency: rewardsAccount?.rewardsBalance.currency,
    product: rewardsAccount?.productDisplayName,
  })
);

export const getSortedRewardsAccountWithLargestEarnBanner = createSelector(
  getRewardsAccounts,
  (rewardsAccounts) => {
    const earnMultiplier = (acct: RewardsAccount) =>
      acct.earn.flightsMultiplier ?? acct.earn.hotelsMultiplier ?? -1;
    // create clone since `rewardsAccounts` is read-only and can't be sorted in place
    const accounts = [...rewardsAccounts];

    return accounts.sort((prev, current) => {
      const lcProductDisplayName = current.productDisplayName.toLowerCase();
      const currEarnMultiplier = earnMultiplier(current);
      const prevEarnMultiplier = earnMultiplier(prev);

      if (currEarnMultiplier === prevEarnMultiplier) {
        return cardsToShowWithPriorityOnRewardsBanner.includes(
          lcProductDisplayName
        )
          ? 1
          : -1;
      }

      return currEarnMultiplier - prevEarnMultiplier;
    });
  }
);

export const getRewardsAccountWithLargestEarnBanner = createSelector(
  getSortedRewardsAccountWithLargestEarnBanner,
  (sortedAccounts) => sortedAccounts[0]
);

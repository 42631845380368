import React from "react";
import { Box } from "@material-ui/core";
import { ActionButton, PickerType } from "halifax";
import { IMonthBucket } from "redmond";

import { MonthAndDatePicker } from "../MonthAndDatePicker";
import {
  CloseReason,
  MonthAndDatePickerType,
  buttonText,
} from "../../constants";

import "./styles.scss";

export interface IMobileCalendarPickerProps {
  departureDate: Date | null;
  focusedMonthIndex: number;
  months: IMonthBucket[];
  onComplete: (reason?: CloseReason) => void;
  pickerType: PickerType;
  readyToSearch: boolean;
  returnDate: Date | null;
  setDepartureDate: (newDate: Date | null) => void;
  setReturnDate: (newDate: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    departureDate,
    focusedMonthIndex,
    months,
    onComplete,
    pickerType,
    readyToSearch,
    returnDate,
    setDepartureDate,
    setReturnDate,
  } = props;

  return (
    <Box className="mobile-calendar-picker-root exchange">
      <MonthAndDatePicker
        className="b2b"
        endDate={returnDate}
        focusedMonthIndex={focusedMonthIndex}
        months={months}
        pickerType={pickerType}
        setEndDate={setReturnDate}
        setStartDate={setDepartureDate}
        startDate={departureDate}
        viewType={MonthAndDatePickerType.Column}
      />
      {readyToSearch && (
        <ActionButton
          className="mobile-calendar-picker-search-button b2b"
          message={buttonText.SEARCH}
          onClick={() => onComplete(CloseReason.submit)}
        />
      )}
    </Box>
  );
};

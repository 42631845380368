import React, { ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ExchangeScenario, TripCategory } from "redmond";

import { searchCopy } from "../../constants";
import { ActiveExperiments, useExperiment } from "../../context/experiments";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import {
  getExchangeFee,
  getExchangeScenario,
  getPolicies,
  getPrevAirlineName,
  getTripType,
} from "../../selectors";

import "./styles.scss";

export interface IFlightExchangeBodyProps {
  className?: string;
  isLoading: boolean;
  shopForm: ReactNode;
  subtitle: string | string[];
  title: string;
}

const defaultProps: Partial<IFlightExchangeBodyProps> = {
  className: "",
};

const FlightExchangeBody = (props: IFlightExchangeBodyProps): JSX.Element => {
  const { className, isLoading, shopForm, subtitle } = props;
  const { matchesMobile } = useDeviceTypes();
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);
  const airlineName = useSelector(getPrevAirlineName);
  const policies = useSelector(getPolicies);
  const changeFee = useSelector(getExchangeFee);
  const scenario = useSelector(getExchangeScenario);
  const tripType = useSelector(getTripType);
  let title = "";
  let subtitles: string[] = [];

  if (redesignExp) {
    if (scenario === ExchangeScenario.ftc) {
      title = searchCopy.REDEEM_FTC(airlineName);
    } else {
      const isOneWay = tripType === TripCategory.ONE_WAY;

      title = searchCopy.REDESIGN_SEARCH_TITLE(isOneWay);
      subtitles = [searchCopy.REDESIGN_SEARCH_SUBTITLE(airlineName, changeFee)];
    }
  }

  if (!subtitles.length) {
    if (Array.isArray(subtitle)) {
      subtitles = subtitle;
    } else {
      subtitles = [subtitle];
    }
  }

  if (policies.length) {
    title = ""
    subtitles = []
  }

  return (
    <Box
      className={clsx("flight-exchange-body", className, {
        mobile: matchesMobile,
        redesign: redesignExp,
      })}
    >
      {isLoading ? (
        <>
          <Skeleton className="title-skeleton" />
          <Skeleton className="subtitle-skeleton" />
        </>
      ) : (
        <>
          <Typography className="title" variant="h2">
            {title}
          </Typography>
            {!isEmpty(subtitles) && subtitles.map((s) => (
              <Typography
                className={clsx("subtitle", {
                  mobile: matchesMobile,
                })}
                component="span"
                dangerouslySetInnerHTML={{ __html: s }}
                key={s}
              />
            ))}
        </>
      )}
      {shopForm}
    </Box>
  );
};

FlightExchangeBody.defaultProps = defaultProps;

export default FlightExchangeBody;

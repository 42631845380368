import React, { useEffect } from "react";
import { SliderDropdown } from "halifax";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { APPLIED_FILTER } from "redmond";

import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";
import { filterCopy } from "../../../../../../constants";
import { setMaxPriceFilter } from "../../../../../../reducers/flightShop";
import { getMaxPriceFilter } from "../../../../../../selectors";

export interface IMaxPriceFilterProps {
  maxPrice: number;
  minPrice: number;
  showDropdownContentOnly?: boolean;
}

const defaultProps: Partial<IMaxPriceFilterProps> = {
  showDropdownContentOnly: false,
};

const MaxPriceFilter = (props: IMaxPriceFilterProps): JSX.Element => {
  const { maxPrice, minPrice, showDropdownContentOnly } = props;
  const dispatch = useDispatch();
  const maxPriceFilter = useSelector(getMaxPriceFilter);

  const setMaxPrice = (newMaxPrice: number) =>
    dispatch(setMaxPriceFilter(newMaxPrice));

  const debouncedSetMaxPrice = debounce(setMaxPrice, 300, {
    leading: false,
    trailing: true,
  });

  const handleChange = (newValue: number) => {
    debouncedSetMaxPrice(newValue);
    trackEvent({
      eventName: APPLIED_FILTER,
      properties: { filter_value: newValue },
    });
  };

  useEffect(() => {
    setMaxPrice(maxPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPrice]);

  return (
    <SliderDropdown
      className="max-price-filter b2b-shop-filter"
      dropdownLabel={filterCopy.PRICE}
      maxValue={maxPrice}
      minValue={maxPrice === minPrice ? 0 : minPrice}
      popoverClassName="b2b"
      setValue={handleChange}
      showDropdownContentOnly={showDropdownContentOnly}
      sliderHeader={filterCopy.SET_MAX_PRICE}
      sliderLabelFormat={(value: number) => `$${value.toFixed(2)}`}
      value={maxPriceFilter}
    />
  );
};

MaxPriceFilter.defaultProps = defaultProps;

export default MaxPriceFilter;

import React from "react";
import { MultiSelectDropdown } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { AirlineCode, ISelectOption } from "redmond";

import { filterCopy } from "../../../../../../constants";
import { setAirlinesFilter } from "../../../../../../reducers/flightShop";
import { getAirlinesFilter } from "../../../../../../selectors";

export interface IAirlineFilterProps {
  airlines: ISelectOption<AirlineCode>[];
  showDropdownContentOnly?: boolean;
}

const defaultProps: Partial<IAirlineFilterProps> = {
  showDropdownContentOnly: false,
};

const AirlineFilter = (props: IAirlineFilterProps): JSX.Element => {
  const { airlines, showDropdownContentOnly } = props;
  const dispatch = useDispatch();
  const airlinesFilter = useSelector(getAirlinesFilter);

  return (
    <MultiSelectDropdown
      className="airline-filter b2b-shop-filter"
      currentValue={airlinesFilter}
      dropdownLabel={filterCopy.AIRLINE}
      options={airlines}
      popoverClassName="b2b"
      setValue={(newFilter) => dispatch(setAirlinesFilter(newFilter))}
      showDropdownContentOnly={showDropdownContentOnly}
    />
  );
};

AirlineFilter.defaultProps = defaultProps;

export default AirlineFilter;

import axios from "axios";
import {
  AirExchangePriceQuoteScheduleResponse,
  IScheduleExchangePriceQuoteReq,
  IScheduleExchangePriceQuoteRes,
} from "redmond";

import { config } from "../../config";
import { scheduleExchangePriceQuotePath } from "../paths";

const scheduleExchangePriceQuote = (
  reservationId: string,
  tripId: string,
  fareId: string,
  subBookingLocator?: string,
  isWithinVoidWindow?: boolean
): Promise<IScheduleExchangePriceQuoteRes> =>
  new Promise(async (resolve, reject) => {
    try {
      const req: IScheduleExchangePriceQuoteReq = {
        reservationId,
        tripId,
        fareId,
        subBookingLocator,
        isWithinVoidWindow,
      };
      const res = await axios.post(scheduleExchangePriceQuotePath, req, {
        baseURL: config.baseURL,
      });

      if (
        res.data.AirExchangePriceQuoteScheduleResponse ===
        AirExchangePriceQuoteScheduleResponse.success
      ) {
        resolve(res.data);
      } else {
        reject(res.data.error);
      }
    } catch (e) {
      reject(e);
    }
  });

export default scheduleExchangePriceQuote;

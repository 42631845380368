import { Box, Collapse } from "@material-ui/core";
import {
  ActionLink, FlightPricingLineItem, IPaymentLineItem,
  ISummaryLineItem,
  SummaryRow
} from "halifax";
import React, { useState } from "react";

import { buttonText } from "../../../../constants";
import PrevPaymentSection, {
  IPrevPaymentLineItem
} from "../PrevPaymentSection/component";
import { TravelerRow } from "../TravelerRow";

import "./styles.scss";

export interface IPricingSummaryProps {
  passengerItems: FlightPricingLineItem[];
  payments: IPrevPaymentLineItem[];
  summaryItems: ISummaryLineItem[];
  totalPayable: IPaymentLineItem;
}

const defaultProps: Partial<IPricingSummaryProps> = {};

const PricingSummary = (props: IPricingSummaryProps): JSX.Element => {
  const { payments, totalPayable, summaryItems, passengerItems } = props;
  const [pricingOpen, setPricingOpen] = useState(false);

  return (
    <Box className="exchange-pricing-summary">
      <Box className="pricing-summary-section travelers">
        {passengerItems.map((passengerItem) => (
          <TravelerRow expanded={pricingOpen} pricing={passengerItem} />
        ))}
      </Box>
      <Collapse unmountOnExit in={pricingOpen} timeout="auto">
        {summaryItems.map((summaryItem) => (
          <Box className="pricing-summary-section">
            <SummaryRow
              key={summaryItem.type}
              summary={summaryItem}
              titles={{
                rewardsAccountText: (lastFour?: string) =>
                  lastFour ? `Ending in ${lastFour}` : `Amount due:`,
                rewardsAppliedText: (accountName: string) =>
                  `${accountName} Rewards applied:`,
                totalText: "Total",
              }}
            />
          </Box>
        ))}
        {payments.length ? (
          <Box className="pricing-summary-section deductions">
            <PrevPaymentSection payments={payments} />
          </Box>
        ) : null}
      </Collapse>
      <ActionLink
        className="b2b expand-action"
        content={`${pricingOpen ? "-" : "+"} ${buttonText.VIEW_PRICING}`}
        onClick={() => setPricingOpen(!pricingOpen)}
      />
      <Box className="pricing-summary-section total">
        <SummaryRow
          summary={totalPayable}
          titles={{
            rewardsAccountText: (lastFour?: string) =>
              lastFour ? `Ending in ${lastFour}` : `Amount due:`,
            rewardsAppliedText: (accountName: string) =>
              `${accountName} Rewards applied:`,
            totalText: "Total",
          }}
        />
      </Box>
    </Box>
  );
};

PricingSummary.defaultProps = defaultProps;

export default PricingSummary;

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  FlightSummaryPanel,
  formatInterval,
  removeTimezone,
} from "halifax";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ExchangeScenario,
  FlightItinerarySlice,
  FlightShopStep,
  getDurationFromSegments,
  Slice,
} from "redmond";

import {
  buttonText,
  flightShopCopy,
  formats,
  TripType,
} from "../../../../constants";
import { resetAllFilters, setShopStep } from "../../../../reducers/flightShop";
import {
  getAirlineMap,
  getAirportMap,
  getExchangeScenario,
  getExchangeType,
  getShoppedTrip,
  getTripDetails,
} from "../../../../selectors";
import { ExchangeModuleRootState } from "../../../../store";
import { getAirportCityName, skipShopAction } from "../../../../utils/helpers";
import { PATH_FLIGHT_SHOP } from "../../../../utils/paths";

import "./styles.scss";
import { setSessionId } from "../../../../reducers/flightBook";

export interface IFlightSummaryCardProps extends RouteComponentProps {
  className?: string;
  isMobile: boolean;
  slice: FlightItinerarySlice | Slice;
  tripType: TripType;
}

const defaultProps: Partial<IFlightSummaryCardProps> = {
  isMobile: false,
};

const FlightSummaryCard = (props: IFlightSummaryCardProps) => {
  const { className, history, isMobile, slice, tripType } = props;
  const dispatch = useDispatch();
  const airlines = useSelector(getAirlineMap);
  const airports = useSelector(getAirportMap);
  const { outboundSelection, returnSelection } = useSelector(getExchangeType);
  const scenario = useSelector(getExchangeScenario);
  const { outgoingFareId, returnFareId, tripId } = useSelector(getShoppedTrip);
  const tripDetails = useSelector((state: ExchangeModuleRootState) =>
    getTripDetails(state, tripId!)
  );
  let arrival,
    departure,
    destination,
    marketingAirline = "",
    plusDays = 0,
    stops,
    totalDurationMinutes;

  if ("id" in slice) {
    // handles Slice type (from shop response)
    arrival = slice.arrival;
    departure = slice.departure;
    destination = slice.destination;
    marketingAirline = slice.marketingAirline;
    plusDays = slice.segments.reduce((days, seg) => days + seg.plusDays, 0);
    stops = slice.stops;
    totalDurationMinutes = slice.totalDurationMinutes;
  } else {
    // handles FlightItinerarySlice (from prev booking)
    const { segments } = slice;
    ({
      marketingAirline: { code: marketingAirline },
      updatedDeparture: departure,
    } = segments[0]);
    ({
      destination: { locationCode: destination },
      updatedArrival: arrival,
    } = segments[segments.length - 1]);

    plusDays = slice.segments.reduce((days, seg) => {
      const { plusDays = 0 } = seg;

      return days + plusDays;
    }, 0);
    stops = segments.length - 1;
    totalDurationMinutes = getDurationFromSegments(
      segments,
      (departure, arrival) => dayjs(arrival).diff(dayjs(departure), "m")
    );
  }

  const airline = airlines[marketingAirline];
  const isOutbound = tripType === TripType.Outbound;
  const isReturn = tripType === TripType.Return;
  const cityName = getAirportCityName(airports, destination);
  const fareId = isOutbound ? outgoingFareId : returnFareId;
  const fareDetails = tripDetails?.fareDetails.find((f) => f.id === fareId);
  const departureDesc = `to ${cityName} (${destination}) on ${dayjs(
    departure
  ).format(formats.DISPLAY_DATE)}`;
  const blockChange = isOutbound
    ? skipShopAction(outboundSelection)
    : skipShopAction(returnSelection);
  const cardActions = [];

  // only FTC Redeem has review page
  if (scenario === ExchangeScenario.ftc) {
    cardActions.push(
      <ActionLink
        ariaProps={{
          role: "button",
        }}
        className="summary-action b2b"
        content={buttonText.VIEW_DETAILS}
        key={buttonText.VIEW_DETAILS}
        label={buttonText.VIEW_DETAILS}
        onClick={() => {
          dispatch(setShopStep(FlightShopStep.ReviewItinerary));
          history.push({
            pathname: PATH_FLIGHT_SHOP,
            search: history.location.search,
          });
        }}
      />
    );
  }

  // If user kept original departure/return, they can't change their selection
  if (!blockChange) {
    cardActions.push(
      <ActionLink
        ariaProps={{
          role: "button",
        }}
        className="summary-action b2b"
        content={buttonText.CHANGE}
        key={buttonText.CHANGE}
        label={buttonText.CHANGE}
        onClick={() => {
          if (isOutbound) {
            dispatch(resetAllFilters());
            dispatch(setShopStep(FlightShopStep.ChooseDeparture));
          } else if (isReturn) {
            dispatch(setShopStep(FlightShopStep.ChooseReturn));
          }
          // reset sessionId since a new Price Quote will be needed
          dispatch(setSessionId(""));

          history.push({
            pathname: PATH_FLIGHT_SHOP,
            search: history.location.search,
          });
        }}
      />
    );
  }

  return (
    <Box className="flight-summary-card">
      <FlightSummaryPanel
        airlineCode={airline?.code ?? ""}
        airline={airline?.displayName ?? ""}
        className={clsx("flight-summary", className)}
        duration={formatInterval(totalDurationMinutes)}
        fareClass={fareDetails?.slices[0].fareShelf?.shortBrandName ?? ""}
        formattedArrivalTime={dayjs(removeTimezone(arrival)).format(
          formats.SUMMARY_TIME
        )}
        departureDescriptionBold={tripType}
        departureDescription={departureDesc}
        formattedDepartureTime={dayjs(removeTimezone(departure)).format(
          formats.SUMMARY_TIME
        )}
        isMobile={isMobile}
        plusDays={plusDays}
        stopString={flightShopCopy.STOPS(stops)}
      />
      <Box className="summary-actions">
        {cardActions.map((action, index) => (
          <Fragment key={action.key}>
            {index !== 0 && (
              <Typography
                className="action-divider"
                component="span"
                display="inline"
              >
                |
              </Typography>
            )}
            {action}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

FlightSummaryCard.defaultProps = defaultProps;

export default withRouter(FlightSummaryCard);

import axios from "axios";
import { ActiveExperimentsResponse } from "redmond";

import { config } from "../../config";
import { fetchExperimentsPath } from "../paths";

export const fetchActiveExperiments = (): Promise<ActiveExperimentsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        fetchExperimentsPath,
        {},
        { baseURL: config.baseURL }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

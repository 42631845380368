import React from "react";
import { MultiSelectDropdown } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { AirportCode, ISelectOption } from "redmond";

import { filterCopy } from "../../../../../../constants";
import { setAirportsFilter } from "../../../../../../reducers/flightShop";
import { getAirportsFilter } from "../../../../../../selectors";

export interface IAirportFilterProps {
  airports: ISelectOption<AirportCode>[];
  showDropdownContentOnly?: boolean;
}

const defaultProps: Partial<IAirportFilterProps> = {
  showDropdownContentOnly: false,
};

const AirportFilter = (props: IAirportFilterProps): JSX.Element => {
  const { airports, showDropdownContentOnly } = props;
  const dispatch = useDispatch();
  const airportsFilter = useSelector(getAirportsFilter);

  return (
    <MultiSelectDropdown
      className="airport-filter b2b-shop-filter"
      currentValue={airportsFilter}
      dropdownLabel={filterCopy.AIRPORT}
      options={airports}
      popoverClassName="b2b"
      setValue={(newFilter) => dispatch(setAirportsFilter(newFilter))}
      showDropdownContentOnly={showDropdownContentOnly}
    />
  );
};

AirportFilter.defaultProps = defaultProps;

export default AirportFilter;

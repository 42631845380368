export const BASE_PATH_HOME = "/exchange";
export const HOTELS_HOME = "/hotels/";
export const TRIPS_HOME = "/trips/";

export const PATH_FLIGHT_EXCHANGE = `${BASE_PATH_HOME}/flight`;
export const PATH_FLIGHT_CONFIRM = `${PATH_FLIGHT_EXCHANGE}/confirm`;
export const PATH_FLIGHT_CONFIRM_AUTOMATED = `${PATH_FLIGHT_EXCHANGE}/book`;
export const PATH_FLIGHT_SEARCH = `${PATH_FLIGHT_EXCHANGE}/search`;
export const PATH_FLIGHT_SHOP = `${PATH_FLIGHT_EXCHANGE}/shop`;


import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { GenericDropdown } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { TripCategory } from "redmond";

import { TimeRangeSlider } from "./components/TimeRangeSlider";
import { flightShopCopy } from "../../../../../../constants";
import {
  setOutboundArrivalTimeFilter,
  setOutboundDepartureTimeFilter,
  setReturnArrivalTimeFilter,
  setReturnDepartureTimeFilter,
} from "../../../../../../reducers/flightShop";
import {
  getOutboundArrivalTimeFilter,
  getOutboundDepartureTimeFilter,
  getReturnArrivalTimeFilter,
  getReturnDepartureTimeFilter,
} from "../../../../../../selectors";

import "./styles.scss";

export interface IDepartureArrivalFilterProps {
  showArrivalsOnly?: boolean;
  showDeparturesOnly?: boolean;
  showDropdownContentOnly?: boolean;
  type: TripCategory;
}

const defaultProps: Partial<IDepartureArrivalFilterProps> = {
  showArrivalsOnly: false,
  showDeparturesOnly: false,
  showDropdownContentOnly: false,
};

const DepartureArrivalFilter = (
  props: IDepartureArrivalFilterProps
): JSX.Element => {
  const {
    showArrivalsOnly,
    showDeparturesOnly,
    showDropdownContentOnly,
    type,
  } = props;
  const dispatch = useDispatch();
  const outboundArrival = useSelector(getOutboundArrivalTimeFilter);
  const outboundDeparture = useSelector(getOutboundDepartureTimeFilter);
  const returnArrival = useSelector(getReturnArrivalTimeFilter);
  const returnDeparture = useSelector(getReturnDepartureTimeFilter);

  const DropdownContent = useMemo(
    () => (
      <Box className="departure-arrival-filter-root">
        {!showArrivalsOnly && (
          <Box className="outbound-container">
            <Box className="header-container">
              {flightShopCopy.TIMES_OUTBOUND}
            </Box>
            <Box className="filter-controls">
              <Box className="time-range-container departure">
                <Box className="time-range-label">
                  {flightShopCopy.DEPARTURE}
                </Box>
                <TimeRangeSlider
                  onChange={(min: number, max: number) =>
                    dispatch(setOutboundDepartureTimeFilter({ min, max }))
                  }
                  timeRange={outboundDeparture}
                />
              </Box>
              <Box className="time-range-container arrival">
                <Box className="time-range-label">{flightShopCopy.ARRIVAL}</Box>
                <TimeRangeSlider
                  onChange={(min: number, max: number) =>
                    dispatch(setOutboundArrivalTimeFilter({ min, max }))
                  }
                  timeRange={outboundArrival}
                />
              </Box>
            </Box>
          </Box>
        )}
        {type === TripCategory.ROUND_TRIP && !showDeparturesOnly && (
          <Box className="return-container">
            <Box className="header-container">
              {flightShopCopy.TIMES_RETURN}
            </Box>
            <Box className="filter-controls">
              <Box className="time-range-container departure">
                <Box className="time-range-label">
                  {flightShopCopy.DEPARTURE}
                </Box>
                <TimeRangeSlider
                  onChange={(min: number, max: number) =>
                    dispatch(setReturnDepartureTimeFilter({ min, max }))
                  }
                  timeRange={returnDeparture}
                />
              </Box>
              <Box className="time-range-container arrival">
                <Box className="time-range-label">{flightShopCopy.ARRIVAL}</Box>
                <TimeRangeSlider
                  onChange={(min: number, max: number) =>
                    dispatch(setReturnArrivalTimeFilter({ min, max }))
                  }
                  timeRange={returnArrival}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      outboundArrival,
      outboundDeparture,
      returnArrival,
      returnDeparture,
      showArrivalsOnly,
      showDeparturesOnly,
      type,
    ]
  );

  return (
    <Box className="departure-arrival-dropdown-container">
      {showDropdownContentOnly ? (
        DropdownContent
      ) : (
        <GenericDropdown
          ariaLabel="Time Filter"
          buttonClassName="departure-arrival-dropdown"
          dropdownContent={DropdownContent}
          dropdownLabel={flightShopCopy.TIME}
          popoverClassName="departure-arrival-popover b2b"
        />
      )}
    </Box>
  );
};

DepartureArrivalFilter.defaultProps = defaultProps;

export default DepartureArrivalFilter;

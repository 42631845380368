import React, { useState } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionButton,
  B2BSearchAutocomplete,
  DatePickerButton,
  Icon,
  IconName,
} from "halifax";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  ILocationQueryLabel,
  LocationQueryEnum,
  TripCategory,
  ITripTerminus,
} from "redmond";

import { FlightDateRangePicker } from "../FlightDateRangePicker";
import fetchLocations from "../../api/v1/exchange/fetchLocations";
import { buttonText, searchCopy, formats } from "../../constants";
import { ActiveExperiments, useExperiment } from "../../context/experiments";
import { setDestination, setOrigin } from "../../reducers/search";
import { getShoppedParams, getTripType } from "../../selectors";

import "./styles.scss";

type MaybeTripTerminus = ITripTerminus | null;

export interface IFlightExchangeFormProps {
  isMobile: boolean;
  onSubmit: () => void;
}

const defaultProps: Partial<IFlightExchangeFormProps> = {};

const FlightExchangeForm = (props: IFlightExchangeFormProps) => {
  const { isMobile, onSubmit } = props;
  const dispatch = useDispatch();
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);
  const tripType = useSelector(getTripType);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [destOpts, setDestOpts] = useState<any[]>([]);
  const [destLoading, setDestLoading] = useState(false);
  const [originOpts, setOriginOpts] = useState<any[]>([]);
  const [originLoading, setOriginLoading] = useState(false);
  const shoppedParams = useSelector(getShoppedParams);

  const {
    departureDate,
    destination,
    destinationCode,
    origin,
    originCode,
    returnDate,
  } = shoppedParams;

  const fetchLocationCategories = debounce(
    (forOrigin: boolean, searchText: string) => {
      const requestBody: ILocationQueryLabel = {
        LocationQuery: LocationQueryEnum.Label,
        l: searchText,
      };

      if (forOrigin) {
        setOriginLoading(true);
      } else {
        setDestLoading(true);
      }

      fetchLocations(requestBody)
        .then((res) => {
          const { categories } = res;

          if (forOrigin) setOriginOpts(categories);
          else setDestOpts(categories);
        })
        .catch(() => {
          if (forOrigin) setOriginOpts([]);
          else setDestOpts([]);
        })
        .finally(() => {
          if (forOrigin) setOriginLoading(false);
          else setDestLoading(false);
        });
    },
    300
  );

  const getIsSearchDisabled = () => {
    if (tripType === TripCategory.ONE_WAY) {
      return !origin || !destination || !departureDate;
    }

    return !origin || !destination || !departureDate || !returnDate;
  };

  /**
   * @description Underlying MapFn used by Autocomplete to find the selected
   * option in the list of options
   * @param {MaybeTripTerminus} option
   * @param {MaybeTripTerminus} value
   */
  const isOptionSelected = (
    option: MaybeTripTerminus,
    value: MaybeTripTerminus
  ) => value?.id?.code?.code === option?.id?.code?.code;

  const saveDestination = (newDest: MaybeTripTerminus) => {
    dispatch(setDestination(newDest));
  };

  const saveOrigin = (newOrigin: MaybeTripTerminus) => {
    dispatch(setOrigin(newOrigin));
  };
  return (
    <Box className={clsx("flight-exchange-request-form", { mobile: isMobile })}>
      {/*  hide this until we suppport switching from oneWay <--> roundTrip
        <FlightCategoryToggle
          category={tripType!}
          className="trip-category-toggle b2b outline"
          setTripCategory={(category) => dispatch(setTripType(category))}
        /> */}
      <Box className="location-row">
        <B2BSearchAutocomplete
          autoFocus
          className="origin-autocomplete"
          customIcon={<Icon name={IconName.B2BMapPin} />}
          fetchValueCategories={(searchText) =>
            fetchLocationCategories(true, searchText)
          }
          getOptionSelected={isOptionSelected}
          label={originCode || searchCopy.WHERE_FROM}
          loading={originLoading}
          setValue={saveOrigin}
          value={origin}
          valueCategories={originOpts}
        />
        <B2BSearchAutocomplete
          className="destination-autocomplete"
          customIcon={<Icon name={IconName.B2BMapPin} />}
          fetchValueCategories={(searchText) =>
            fetchLocationCategories(false, searchText)
          }
          getOptionSelected={isOptionSelected}
          label={destinationCode || searchCopy.WHERE_TO}
          loading={destLoading}
          setValue={saveDestination}
          value={destination}
          valueCategories={destOpts}
        />
      </Box>
      <Box className="dates-and-search-row">
        <DatePickerButton
          classes={["date-inputs"]}
          dateFormat={formats.DISPLAY_DATE}
          endDate={returnDate?.toDate() ?? null}
          endLabel={searchCopy.RETURN}
          hideEndDate={tripType === TripCategory.ONE_WAY}
          onClick={() => setCalendarOpen(true)}
          renderCalendarPopup={(innerProps) => (
            <FlightDateRangePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...innerProps}
              className="date-picker-popup"
              closePicker={() => setCalendarOpen(false)}
              destinationCode={destinationCode}
              isMobile={isMobile}
              open={calendarOpen}
              originCode={originCode}
            />
          )}
          startDate={departureDate?.toDate() ?? null}
          startLabel={searchCopy.DEPARTURE}
        />
        {!calendarOpen && (
          <ActionButton
            className="search-btn big-btn"
            disabled={getIsSearchDisabled()}
            message={
              redesignExp ? buttonText.SEARCH_FLIGHTS : buttonText.SEARCH
            }
            onClick={onSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

FlightExchangeForm.defaultProps = defaultProps;

export default FlightExchangeForm;

import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { B2BSpinner, LoadingIndicator, useDeviceTypes } from "halifax";
import { IFlightListData, Maybe, Slice, TripDetails } from "redmond";

import { FlightDetails } from "../FlightDetails";
import { FlightListItemInfo } from "../FlightListItemInfo";
import { FlightCardType, ModalType } from "../../../../constants/flightShop";

import "./styles.scss";
import { getExperimentVariant, useExperiments } from "../../../../context/experiments";

export interface IFlightListItemProps {
  expandedFareDetails: Maybe<TripDetails>;
  expandedFlightId: string;
  flight: IFlightListData;
  isInternational: boolean;
  isReturn: boolean;
  onAlgomerchTagClick: (tag: string) => void;
  onExpand: (
    sliceId: string,
    selectedFareClass: string,
    flight: IFlightListData
  ) => void;
  onFareClick: (fareId: string) => void;
  onFareSelectClick: (flight: any, fareId: string) => void;
  selectedFareId: string;
  setOpenModal: (type: ModalType) => void;
  slice: Slice;
  sliceId: string;
}

const defaultProps: Partial<IFlightListItemProps> = {
  isReturn: false,
  isInternational: false,
};

const MOBILE_OFFSET_SCROLL = 300;
const DESKTOP_OFFSET_SCROLL = 250;
  
const AIR_CHATBOT_EXPERIMENT = "b2b-agency-air-pricing-chatbot";
const AVAILABLE = "available";

const FlightListItem = (props: IFlightListItemProps): JSX.Element => {
  const {
    expandedFareDetails,
    expandedFlightId,
    flight,
    isInternational,
    isReturn,
    onAlgomerchTagClick,
    onExpand,
    onFareClick,
    onFareSelectClick,
    selectedFareId,
    setOpenModal,
    slice,
    sliceId,
  } = props;
  const { matchesDesktop, matchesLargeDesktop, matchesMobile } =
    useDeviceTypes();
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const handleAlgomerchTagClick = (tag: string) => {
    onAlgomerchTagClick(tag);
    setOpenModal(ModalType.AlgomerchInfo);
  };

  const expState = useExperiments();

  const isChatbotEnabled = useMemo(
    () =>
      getExperimentVariant(expState.experiments, AIR_CHATBOT_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );

  return (
    <Box
      className={clsx("flight-row flight-list-item b2b", {
        "row-view-desktop":
          matchesMediumDesktopOnly || (matchesLargeDesktop && isInternational),
      })}
      id={sliceId}
    >
      <FlightListItemInfo
        flight={flight}
        isExpanded={sliceId === expandedFlightId}
        key={sliceId}
        onAlgomerchTagClick={handleAlgomerchTagClick}
        onClick={(selectedFareClass: string) => {
          onExpand(sliceId, selectedFareClass, flight);
          setTimeout(() => {
            const OFFSET = matchesMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document?.getElementById(sliceId)?.getBoundingClientRect().top ??
              0;

            window.scrollBy({
              behavior: matchesMobile ? "auto" : "smooth",
              top: cardTop - OFFSET,
            });
          }, 100);
        }}
        onFareClick={onFareClick}
        slice={slice}
        type={FlightCardType.content}
      />
      {sliceId === expandedFlightId && (
        <>
          {expandedFareDetails ? (
            <FlightDetails
              isMobile={matchesMobile}
              isOutgoing={!isReturn}
              onAlgomerchTagClick={handleAlgomerchTagClick}
              onFareClick={(_sliceId: string, fareId?: string) =>
                onFareSelectClick(flight, fareId!)
              }
              selectedFareId={selectedFareId}
              tripDetails={expandedFareDetails}
              isChatbotEnabled={isChatbotEnabled}
            />
          ) : (
            <LoadingIndicator
              className="flight-reshop-details-loading-indicator"
              indicator={B2BSpinner}
              indicatorSize="small"
              message="Loading Flight"
            />
          )}
        </>
      )}
    </Box>
  );
};

FlightListItem.defaultProps = defaultProps;

export default FlightListItem;

import React, { useCallback, useContext, useMemo } from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import { ActionLink, Header } from "halifax";
import { useSelector } from "react-redux";
import {
  matchPath,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { FlightShopStep, TripCategory } from "redmond";

import { ClientContext } from "../../App";
import {
  FAQ_URL,
  buttonText,
  headerSubtitles,
  headerTitles,
  searchCopy,
  formats,
} from "../../constants";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import {
  getFlightShopStep,
  getHeaderCopy,
  getShoppedDepartureDate,
  getShoppedDestination,
  getShoppedDestinationLabel,
  getShoppedOrigin,
  getShoppedOriginLabel,
  getShoppedReturnDate,
  getTripType,
} from "../../selectors";
import {
  HOTELS_HOME,
  PATH_FLIGHT_CONFIRM,
  PATH_FLIGHT_CONFIRM_AUTOMATED,
  PATH_FLIGHT_EXCHANGE,
  PATH_FLIGHT_SEARCH,
  PATH_FLIGHT_SHOP,
} from "../../utils/paths";
import { goBackToMyTrips } from "../../utils/helpers";

import "./styles.scss";

export interface IFlightExchangeHeaderProps extends RouteComponentProps {
  isMobile: boolean;
}

const defaultProps: Partial<IFlightExchangeHeaderProps> = {};

const FlightExchangeHeader = (
  props: IFlightExchangeHeaderProps
): JSX.Element => {
  const { history, location } = props;
  const { logo } = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();

  const destination = useSelector(getShoppedDestinationLabel);
  const flightShopStep = useSelector(getFlightShopStep);
  const shoppedReturnDate = useSelector(getShoppedReturnDate);
  const shoppedDepartureDate = useSelector(getShoppedDepartureDate);
  const shoppedOriginCode = useSelector(getShoppedOrigin)?.id.code.code;
  const shoppedDepartureCode = useSelector(getShoppedDestination)?.id.code.code;
  const headerCopy = useSelector(getHeaderCopy);
  const origin = useSelector(getShoppedOriginLabel);
  const tripType = useSelector(getTripType);

  const { originDest, tripDates, tripTypeStr } = useMemo(() => {
    const isOneWay = tripType === TripCategory.ONE_WAY;
    const tripTypeStr = isOneWay ? "One-way" : "Round-trip";

    const formattedDepDate =
      shoppedDepartureDate?.format(formats.SHORT_DATE) ?? "";

    const formattedReturnDate =
      shoppedReturnDate?.format(formats.SHORT_DATE) ?? "";

    const originDest =
      shoppedOriginCode && shoppedDepartureCode
        ? `${shoppedOriginCode} - ${shoppedDepartureCode}`
        : "";

    return {
      originDest,
      tripTypeStr,
      tripDates: `${
        isOneWay
          ? formattedDepDate
          : `${formattedDepDate} - ${formattedReturnDate}`
      }`,
    };
  }, [
    shoppedDepartureDate,
    shoppedReturnDate,
    shoppedOriginCode,
    shoppedDepartureCode,
    tripType,
  ]);

  /**
   * @description What the screenreader reads when focused on the back button
   * @todo When modifying this behavior, also modify `onBackClick` to ensure
   * they stay in sync.
   */
  const getBackActionAriaLabel = () => {
    const onLandingPage = matchPath(location.pathname, {
      path: PATH_FLIGHT_EXCHANGE,
      exact: true,
      strict: false,
    });

    if (onLandingPage) {
      return "Go back to my trips";
    }

    return "Go back to flight exchange";
  };

  const getTitleContainer = useCallback(
    (title?: string, subtitle?: string) => (
      <Box className="title-container">
        <Typography variant="h1" className="title">
          {title}
        </Typography>
        <Typography
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle ?? "" }}
        />
      </Box>
    ),
    []
  );

  /**
   * @description Controls what should happen when the mobile back arrow button
   * is clicked.
   * @todo When modifying this behavior, also modify `getBackActionAriaLabel`
   * to ensure they stay in sync.
   */
  const onBackClick = useCallback(() => {
    if (
      matchPath(location.pathname, {
        path: PATH_FLIGHT_EXCHANGE,
        exact: true,
        strict: false,
      })
    ) {
      return goBackToMyTrips(history);
    }

    return history.push({
      pathname: PATH_FLIGHT_EXCHANGE,
      search: location.search,
    });
  }, [location, history]);

  const onFAQClick = () => {
    window.open(FAQ_URL, "_blank")?.focus();
  };

  const onLogoClick = () => {
    history.push({ pathname: HOTELS_HOME });
  };

  return matchesMobile ? (
    <Box className="flight-exchange-mobile-header">
      <FontAwesomeIcon
        aria-label={getBackActionAriaLabel()}
        className="back-action-link"
        icon={faChevronLeft}
        onClick={onBackClick}
        role="button"
        tabIndex={0}
      />
      <Switch>
        <Route
          path={[PATH_FLIGHT_SHOP, PATH_FLIGHT_CONFIRM, PATH_FLIGHT_CONFIRM_AUTOMATED]}
          render={() =>
            getTitleContainer(`${tripTypeStr} ${originDest}`, tripDates)
          }
        />
        <Route
          path={[PATH_FLIGHT_EXCHANGE, PATH_FLIGHT_SEARCH]}
          render={() => getTitleContainer(originDest, tripTypeStr)}
        />
      </Switch>
    </Box>
  ) : (
    <Header
      className="flight-exchange-desktop-header"
      left={
        <>
          <Box
            aria-label="Go back to travel portal home"
            className="cap-one-logo"
            onClick={onLogoClick}
            role="button"
            tabIndex={0}
          >
            {logo}
          </Box>
          <Switch>
            <Route
              path={PATH_FLIGHT_SHOP}
              render={() => {
                const isOneWay = tripType === TripCategory.ONE_WAY;
                const onDepartureStep =
                  flightShopStep === FlightShopStep.ChooseDeparture;
                const onReturnStep =
                  flightShopStep === FlightShopStep.ChooseReturn;
                const onReviewStep =
                  flightShopStep === FlightShopStep.ReviewItinerary;
                let subtitle, title;

                if (onDepartureStep) {
                  subtitle = headerSubtitles.PRICES_ARE(isOneWay);
                  title = headerTitles.CHOOSE_FLIGHT(destination, onReturnStep);
                } else if (onReturnStep) {
                  subtitle = headerSubtitles.PRICES_ARE(isOneWay);
                  title = headerTitles.CHOOSE_FLIGHT(origin, onReturnStep);
                } else if (onReviewStep) {
                  subtitle = headerSubtitles.REVIEW;
                  title = headerTitles.REVIEW_ITINERARY;
                }

                return getTitleContainer(title, subtitle);
              }}
            />
            <Route
              path={[PATH_FLIGHT_CONFIRM, PATH_FLIGHT_CONFIRM_AUTOMATED]}
              render={() => {
                const subtitle =
                  headerCopy.confirm?.body ?? headerSubtitles.SUBMIT;
                const title =
                  headerCopy.confirm?.title ?? headerTitles.SUBMIT_REQUEST;

                return getTitleContainer(title, subtitle);
              }}
            />
            <Route
              path={[PATH_FLIGHT_EXCHANGE, PATH_FLIGHT_SEARCH]}
              render={() => {
                const subtitle =
                  headerCopy.search?.body ?? headerSubtitles.REQUEST_CHANGE;
                const title =
                  headerCopy.search?.title ?? headerTitles.CHANGE_FLIGHT;

                return getTitleContainer(title, subtitle);
              }}
            />
          </Switch>
        </>
      }
      right={
        <>
          <Typography className="title">{searchCopy.QUESTIONS}</Typography>
          <span className="text-divider">|</span>
          <ActionLink
            aria-label="View F.A.Qs"
            className="view-faqs"
            content={buttonText.VIEW_FAQS}
            onClick={onFAQClick}
          />
        </>
      }
    />
  );
};

FlightExchangeHeader.defaultProps = defaultProps;

export default withRouter(FlightExchangeHeader);

import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  B2BFlightCardV2,
  CurrencyFormatters,
  FlightGridRow,
  FlightShopRow,
  TriangleIcon,
  formatInterval,
  getRewardText,
  getRewardsString,
  useDeviceTypes,
} from "halifax";
import { useSelector } from "react-redux";
import {
  AlgomerchTag,
  FlightFare,
  FlightRatingsEnum,
  FlightTags,
  IFlightGridFares,
  IFlightListData,
  Slice,
  TagType,
  getTags,
  VIEWED_OFFER_FAQ,
  SelectedTravelOfferScreen,
  FlightFareSlice,
} from "redmond";

import { FlightCardType } from "../../../../constants";
import {
  getFareClassesFilter,
  getFlights,
  getMaxPriceFilter,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getTripType,
} from "../../../../selectors";
import { getStopsString } from "../../../../utils/helpers";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { IFlightGridFareInfo } from "redmond/build/apis/tysons/trip-summary";

export interface IFlightListItemInfoContentProps {
  className?: string;
  flight: IFlightListData;
  isExpanded: boolean;
  onAlgomerchTagClick: (tag: string) => void;
  onClick?: (selectedFareClass: string) => void;
  onFareClick: (fareId: string) => void;
  slice: Slice;
  tripId: string;
  type: FlightCardType;
}

const defaultProps: Partial<IFlightListItemInfoContentProps> = {
  isExpanded: false,
};

const FARE_GRID_TAG_LIMIT = 2;

const FlightListItemInfoContent = (
  props: IFlightListItemInfoContentProps
): JSX.Element => {
  const {
    flight,
    isExpanded,
    onAlgomerchTagClick,
    onClick,
    onFareClick,
    slice,
  } = props;
  const { matchesDesktop, matchesLargeDesktop, matchesMobile } =
    useDeviceTypes();

  const fareClassFilter = useSelector(getFareClassesFilter);
  const flights = useSelector(getFlights);
  const maxPriceFilter = useSelector(getMaxPriceFilter);
  const rewardsKey = useSelector(
    getSelectedAccountReferenceIdIfRedemptionEnabled
  );
  const tripType = useSelector(getTripType);
  const airline = flights!.airlines[slice.marketingAirline];
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const getFareId = (fare: any) => fare.example?.fare ?? fare.id;

  const getFareSliceId = (fare: any) => fare.fareSlice || fare.return;

  const getFlightGridFares = () => {
    const fares: IFlightGridFares = {
      basic: null,
      enhanced: null,
      luxury: null,
      premium: null,
      standard: null,
    };

    flight.fares.forEach((fare: any) => {
      const {
        fiat: { currencySymbol, value },
        rewards,
      } = fare.amount;
      const {
        fareBrandName,
        fareShelf,
        tags: fareTags,
      } = flights!.fareSlices[getFareSliceId(fare)];
      const tags = getTags(fareTags as any);
      const reward = rewardsKey ? rewards[rewardsKey] : "";
      let rewardText;

      if (reward?.value >= 0) {
        rewardText = `or ${getRewardsString(reward)}`;
      } else if (reward?.value < 0) {
        // no neg fare differences
        rewardText = "or 0";
      }

      const gridFare = {
        fareId: getFareId(fare),
        fareName: fareBrandName ?? "",
        // +/- appended to price string in FlightGridRowContent
        price: `${currencySymbol}${Math.round(value)}`,
        rawPrice: value,
        reward: rewardText,
        tags,
      };

      const faresForFareShelf: IFlightGridFareInfo =
        fares[FlightRatingsEnum[fareShelf.value]];
      if (
        !faresForFareShelf ||
        faresForFareShelf.rawPrice === undefined ||
        faresForFareShelf.rawPrice > gridFare.rawPrice
      ) {
        fares[FlightRatingsEnum[fareShelf.value]] = gridFare;
      }
    });

    return fares;
  };

  const gridFares = useMemo(
    () => getFlightGridFares(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flight]
  );

  const {
    fareSlice,
    selectedFare,
  }: {
    fareSlice: FlightFareSlice;
    selectedFare: FlightFare;
    // selectedFareClass: string;
  } = useMemo(() => {
    const selectedGridFare =
      Object.keys(gridFares).find(
        (fare) => fareClassFilter[fare] && gridFares[fare]
      ) ?? "";
    const selFare =
      flight.fares.find(
        (fare) => getFareId(fare) === gridFares[selectedGridFare]?.fareId
      ) ?? flight.fares[0];
    const fSlice = flights!.fareSlices[getFareSliceId(selFare)];

    return {
      fareSlice: fSlice,
      selectedFare: selFare,
    };
  }, [flight.fares, fareClassFilter, flights, gridFares]);

  const getFlightTags = (fareSliceTags: FlightTags) => {
    // sometimes there is no tags, avoiding the error
    if (!fareSliceTags) {
      return [];
    }
    const { isBest, isBestQuality, isCheapest, isFastest } = fareSliceTags;
    const tags = [];

    if (isBest) tags.push(AlgomerchTag.BestFlight);
    if (isBestQuality) tags.push(AlgomerchTag.BestQuality);
    if (isCheapest) tags.push(AlgomerchTag.Cheapest);
    if (isFastest) tags.push(AlgomerchTag.Fastest);

    return tags.map((value) => ({ value, type: TagType.Algomerch }));
  };

  const getPriceCopy = (flightFare: FlightFare) => {
    const amount = flightFare.amount;

    let currentPriceCopy = "";
    let rewardsPriceCopy = "";

    if (!!amount) {
      currentPriceCopy = CurrencyFormatters.get(
        amount.fiat.currencyCode
      ).format(amount?.fiat?.value || 0);

      if (amount.fiat.value >= 0) {
        currentPriceCopy = `+${currentPriceCopy}`;
      } else {
        currentPriceCopy = "+0";
      }

      const reward = amount.rewards[rewardsKey ?? ""];
      rewardsPriceCopy = (reward && getRewardText({ reward })) ?? "";

      if (reward?.value >= 0) {
        rewardsPriceCopy = `or ${rewardsPriceCopy}`;
      } else if (reward?.value < 0) {
        // no neg fare difference
        rewardsPriceCopy = "or 0";
      }
    }

    return { currentPriceCopy, rewardsPriceCopy };
  };

  const handleOnClick = () => {
    if (onClick) onClick(fareSlice.fareBrandName);

    if (!matchesLargeDesktop && !slice.domestic) {
      onFareClick(getFareId(selectedFare));
    }
  };

  const trackViewedOfferFaq = () => {
    trackEvent({
      eventName: VIEWED_OFFER_FAQ,
      properties: {
        screen: SelectedTravelOfferScreen.HOTEL_SHOP,
      },
    });
  };

  const renderGridDesktopFlightListInfo = () => (
    <FlightGridRow
      showFaresAsDiffs
      airlineCode={airline.code}
      airlineName={airline.displayName}
      arrivalTime={slice.arrival}
      className={`flight-grid-row row-${slice.id}`}
      departureTime={slice.departure}
      destinationCode={slice.destination}
      duration={formatInterval(slice.totalDurationMinutes)}
      fareCardClassName="b2b"
      fareClassFilter={fareClassFilter}
      fares={gridFares}
      isExpanded={isExpanded}
      tripType={tripType}
      layoverString={getStopsString(slice.stops)}
      key={slice.id}
      onAlgomerchInfoClick={onAlgomerchTagClick}
      onFareClick={onFareClick}
      originCode={slice.origin}
      selectedMaxPrice={maxPriceFilter}
      tagsLimit={FARE_GRID_TAG_LIMIT}
      type="content"
      onOfferTooltipSetModalOpen={trackViewedOfferFaq}
      isReshop
      hideIsInPolicy={true}
      viNotificationBannerBox={() => <Box />}
    />
  );

  const renderMobileFlightListInfo = () => {
    const { code, displayName } = airline;
    const {
      arrival,
      departure,
      destination,
      origin,
      segments,
      totalDurationMinutes,
    } = slice;
    const airports = segments.slice(0, -1).map((s) => s.destination);
    const tags = getFlightTags(fareSlice.tags);
    const { currentPriceCopy, rewardsPriceCopy } = getPriceCopy(selectedFare);

    return (
      <Box className="mobile-flight-card-wrapper">
        <B2BFlightCardV2
          airlineName={displayName}
          airports={airports}
          arrivalTime={arrival}
          brandName={fareSlice?.fareBrandName ?? ""}
          currentPriceText={currentPriceCopy}
          departureTime={departure}
          destinationCode={destination}
          duration={formatInterval(totalDurationMinutes)}
          originCode={origin}
          primaryCarrier={code}
          rewardText={rewardsPriceCopy}
          tags={tags}
          onFirstTravelOfferCardChange={trackViewedOfferFaq}
          showHiddenPrice
          showHeader
          viNotificationBannerBox={() => <Box />}
        />
      </Box>
    );
  };

  const renderRowDesktopFlightListInfo = () => {
    const tags = getFlightTags(fareSlice?.tags);
    const { currentPriceCopy, rewardsPriceCopy } = getPriceCopy(selectedFare);

    return (
      <>
        <FlightShopRow
          airlineCode={airline.code}
          airlineName={airline.displayName}
          arrivalTime={slice.arrival}
          className="small-flight-shop-row b2b"
          currentPriceText={currentPriceCopy}
          departureTime={slice.departure}
          destinationCode={slice.destination}
          duration={formatInterval(slice.totalDurationMinutes)}
          key={slice.id}
          layoverString={getStopsString(slice.stops)}
          onClickTag={onAlgomerchTagClick}
          originCode={slice.origin}
          rewardsPriceText={rewardsPriceCopy}
          tags={tags as any}
          tagsLimit={FARE_GRID_TAG_LIMIT}
          isReshop
        />
        <TriangleIcon
          className={clsx("expand-flight-row-icon", {
            "is-expanded": isExpanded,
          })}
        />
      </>
    );
  };

  return (
    <Box className="flight-list-info-root" onClick={handleOnClick}>
      {matchesLargeDesktop &&
        slice.domestic &&
        renderGridDesktopFlightListInfo()}
      {matchesLargeDesktop &&
        !slice.domestic &&
        renderRowDesktopFlightListInfo()}
      {matchesMediumDesktopOnly && renderRowDesktopFlightListInfo()}
      {matchesMobile && renderMobileFlightListInfo()}
    </Box>
  );
};

FlightListItemInfoContent.defaultProps = defaultProps;

export default FlightListItemInfoContent;

import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  CurrencyFormatters,
  FlightSummaryPanel,
  formatInterval,
  getRewardText,
} from "halifax";
import { useSelector } from "react-redux";
import { FlightShopStep } from "redmond";

import {
  tripTypeCopy,
  flightShopCopy,
  formats,
  TripType,
} from "../../../../constants";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../context/experiments";
import {
  getAirlineMap,
  getAirportMap,
  getExchangeFee,
  getFlightShopStep,
  getPrevFlightSliceSummary,
  getPrevOutboundFlight,
  getPrevReturnFlight,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getTotalPayment,
  getTripType,
} from "../../../../selectors";

import "./styles.scss";

export interface IFlightComparatorRowProps {
  isMobile: boolean;
}

const defaultProps: Partial<IFlightComparatorRowProps> = {
  isMobile: false,
};

const FlightComparatorRow = (props: IFlightComparatorRowProps): JSX.Element => {
  const { isMobile } = props;
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);
  const airlines = useSelector(getAirlineMap);
  const airports = useSelector(getAirportMap);
  const exchangeFee = useSelector(getExchangeFee);
  const prevOutbound = useSelector(getPrevOutboundFlight);
  const prevReturn = useSelector(getPrevReturnFlight);
  const prevFlightSummary = useSelector(getPrevFlightSliceSummary);
  const shopStep = useSelector(getFlightShopStep);
  const prevTotalPrice = useSelector(getTotalPayment);
  const tripType = useSelector(getTripType);
  const rewardsKey = useSelector(
    getSelectedAccountReferenceIdIfRedemptionEnabled
  );
  const onReturnStep = shopStep === FlightShopStep.ChooseReturn;

  const prevFlight = useMemo(() => {
    if (shopStep === FlightShopStep.ChooseReturn) return prevReturn;
    return prevOutbound;
  }, [prevOutbound, prevReturn, shopStep]);

  const {
    cabinClassName,
    destination: { locationCode: destCode = "" } = {},
    marketingAirline,
    origin: { locationCode: originCode = "" } = {},
    plusDays,
    stops,
    updatedArrival,
    zonedUpdatedArrival,
    zonedUpdatedDeparture,
    updatedDeparture,
  } = ((redesignExp ? prevFlightSummary : prevFlight) ?? {});
  const airline = airlines[marketingAirline?.code ?? ""]?.displayName;
  const arrival = dayjs(updatedArrival);
  const departure = dayjs(updatedDeparture);
  const departureDesc = `${redesignExp ? "" : "<b>"}to ${airports[destCode]?.cityName
    } (${destCode})${redesignExp ? "" : "</b>"} on ${departure.format(
      formats.DISPLAY_DATE
    )}`;
  let tripCategory;

  if (redesignExp) tripCategory = `${airports[originCode]?.cityName ?? ""} (${originCode})`;
  else if (shopStep === FlightShopStep.ChooseDeparture) tripCategory = TripType.Outbound;
  else tripCategory = TripType.Return;

  return (
    <Box className={clsx("flight-comparator-row", { mobile: isMobile })}>
      {!isMobile && (
        <Box
          className={clsx("flight-info-card new-flight", {
            redesign: redesignExp,
          })}
        >
          <Typography className="title">
            {flightShopCopy.SELECT_NEW_FLIGHT_TITLE(onReturnStep)}
          </Typography>
          {redesignExp ? (
            <Typography className="subtitle">
              {flightShopCopy.SAME_FLIGHT_OPTIONS}
            </Typography>
          ) : (
            <Typography
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: flightShopCopy.SELECT_NEW_FLIGHT_SUBTITLE(
                  airline,
                  exchangeFee?.amount ?? 0
                ),
              }}
            />
          )}
        </Box>
      )}
      <Box
        className={clsx("flight-info-card prev-flight", {
          redesign: redesignExp,
        })}
      >
        <Box className="flight-summary-container">
          <Typography className="title">
            {flightShopCopy.PREV_SELECTED_TITLE(onReturnStep, redesignExp)}
          </Typography>
          <FlightSummaryPanel
            airline={airline}
            airlineCode={marketingAirline?.code ?? ""}
            className="prev-flight-summary"
            // get the right duration as in itinerary details
            duration={formatInterval(dayjs(zonedUpdatedArrival).diff(dayjs(zonedUpdatedDeparture), "minutes"))}
            fareClass={cabinClassName}
            formattedArrivalTime={arrival.format(formats.SUMMARY_TIME)}
            departureDescriptionBold={tripCategory}
            departureDescription={departureDesc}
            formattedDepartureTime={departure.format(formats.SUMMARY_TIME)}
            isMobile={isMobile}
            plusDays={plusDays}
            stopString={flightShopCopy.STOPS(stops)}
          />
        </Box>
        {!redesignExp && prevTotalPrice && (
          <Box className="traveler-price">
            <Typography className="price-per-traveler" variant="caption">
              {flightShopCopy.PRICE_PER_TRAVELER}
            </Typography>
            <Box className="price-container">
              <Typography className="price-fiat" display="inline">
                {CurrencyFormatters.get(
                  prevTotalPrice.fiat.currencyCode
                ).format(prevTotalPrice.fiat.value)}
              </Typography>
              {rewardsKey && (
                <>
                  {" or "}
                  <Typography className="price-rewards" display="inline">
                    {getRewardText({
                      reward: prevTotalPrice.rewards[rewardsKey],
                    })}
                  </Typography>
                </>
              )}
            </Box>
            <Typography className="trip-type">
              {tripTypeCopy[tripType!]}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

FlightComparatorRow.defaultProps = defaultProps;

export default FlightComparatorRow;

import axios from "axios";
import { TripDetails } from "redmond";

import { config } from "../../config";
import { exchangeTripDetailsPathV2 } from "../paths";
import mockDetails from "../../../utils/mockTripDetails.json";

export const fetchTripDetailsV2 = async (tripId: string, isHackerFareReturn: boolean | undefined, mockDataExp = false) => {
  try {
    if (mockDataExp) {
      const tripDetails = {
        ...mockDetails,
        id: tripId,
      };

      return Promise.resolve(tripDetails as unknown as TripDetails);
    }

    const value: string = tripId;
    const req = {value, isHackerFareReturn};
    const res =
      await axios.post<TripDetails>(exchangeTripDetailsPathV2, req, {
        baseURL: config.baseURL,
      });

    return Promise.resolve(res.data);

  } catch (err) {
    return Promise.reject(err);
  }
};

import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import clsx from "clsx";
import { ActionLink, CloseButtonIcon, Header, PickerType } from "halifax";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  Currency,
  IDepartureCalendarReport,
  IDepartureCalendarRequestBody,
  IMonthBucket,
  Locale,
  RegionType,
  TripCategory,
  TripFilter,
} from "redmond";

import { DesktopCalendarPicker } from "../DesktopCalendarPicker";
import { MobileCalendarPicker } from "../MobileCalendarPicker";
import fetchCalendar from "../../api/v1/exchange/fetchCalendar";
import { CloseReason, searchCopy } from "../../constants";
import {
  setCurrency,
  setDepartureDate,
  setPriceLegend,
  setReturnDate,
} from "../../reducers/search";
import { getDepartureDate, getTripType } from "../../selectors";
import { transformDateBuckets } from "../../utils/helpers";

import "./styles.scss";

export interface IFlightDateRangePickerProps {
  closePicker: () => void;
  destinationCode: string;
  isMobile: boolean;
  open: boolean;
  originCode: string;
  className?: string;
}

const defaultProps: Partial<IFlightDateRangePickerProps> = {
  isMobile: false,
};

const FlightDateRangePicker = (
  props: IFlightDateRangePickerProps
): JSX.Element => {
  const {
    closePicker,
    destinationCode,
    isMobile,
    open,
    originCode,
    className,
  } = props;
  const dispatch = useDispatch();
  const departureDate = useSelector(getDepartureDate);
  const tripType = useSelector(getTripType);
  const [depDate, setDepDate] = useState<Date | null>(null);
  const [months, setMonths] = useState<IMonthBucket[]>([]);
  const [retDate, setRetDate] = useState<Date | null>(null);

  const isOneWay = tripType === TripCategory.ONE_WAY;
  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;
  const pickerType = isOneWay ? PickerType.DAY : PickerType.RANGE;
  const isValid = isOneWay ? Boolean(depDate) : Boolean(depDate && retDate);

  /**
   * @description Commit local state values to redux store and close the picker
   * @param {CloseReason} [reason]
   */
  const closeModal = useCallback(
    (reason?: CloseReason) => {
      if (reason === CloseReason.submit) {
        const strDepDate = dayjs(depDate).toString();
        const strRetDate = dayjs(retDate).toString();

        dispatch(setDepartureDate(strDepDate));
        !isOneWay && dispatch(setReturnDate(strRetDate));
      }

      closePicker();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOneWay, depDate, retDate]
  );

  useEffect(() => {
    if (destinationCode && originCode && tripType) {
      const request: IDepartureCalendarRequestBody = {
        tripType,
        filter: { TripFilter: TripFilter.NO_FILTER },
        preferences: { locale: Locale.EN_US, currency: Currency.USD },
        route: {
          origin: { code: originCode, regionType: RegionType.Airport },
          destination: {
            code: destinationCode,
            regionType: RegionType.Airport,
          },
        },
      };

      fetchCalendar(request)
        .then((calendar: IDepartureCalendarReport) => {
          const { currency, departureDateBuckets } = calendar;
          const transformedMonths = transformDateBuckets(departureDateBuckets);
          const priceLegend = departureDateBuckets.map(
            (bucket) => bucket.legend
          );

          batch(() => {
            setMonths(transformedMonths);
            dispatch(setCurrency(currency));
            dispatch(setPriceLegend(priceLegend));
          });
        })
        .catch(() => {
          batch(() => {
            setMonths([]);
            dispatch(setCurrency(""));
            dispatch(setPriceLegend([]));
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationCode, originCode, tripType]);

  return isMobile && open ? (
    <Box className={clsx("date-picker-mobile-container", className)}>
      <Header
        fullWidth
        center={
          <Box className="header-center-section">
            <Box className="trip-origin-destination-summary">
              <Box className="origin-destination-container">
                <Typography variant="body2">
                  {searchCopy.CHOOSE_DATES}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
        isMobile={isMobile}
        right={
          <ActionLink
            className="mobile-flight-search-header-close"
            onClick={() => closeModal(CloseReason.exit)}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
      />
      <MobileCalendarPicker
        departureDate={depDate}
        focusedMonthIndex={focusedMonthIndex}
        months={months}
        onComplete={closeModal}
        pickerType={pickerType}
        readyToSearch={isValid}
        returnDate={retDate}
        setDepartureDate={setDepDate}
        setReturnDate={setRetDate}
      />
    </Box>
  ) : (
    <DesktopCalendarPicker
      open={open}
      closePopup={closeModal}
      departureDate={depDate}
      months={months}
      pickerType={pickerType}
      readyToSearch={isValid}
      returnDate={retDate}
      setDepartureDate={setDepDate}
      setReturnDate={setRetDate}
      tripCategory={tripType}
    />
  );
};

FlightDateRangePicker.defaultProps = defaultProps;

export default FlightDateRangePicker;

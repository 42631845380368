import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  B2BSpinnerWithText,
  GenericModalContent,
  Icon,
  IconName,
} from "halifax";

import { FlightAirportPicker } from "../FlightAirportPicker";
import { buttonText, searchCopy } from "../../constants";

import "./styles.scss";
import { getPolicyAttr, openSupportInNewTab } from "../../utils/helpers";
import { IFlightExchangePolicyRes } from "redmond";

/**
 * @description Opens a form for the user to modify their travel dates
 * @return {ReactNode}
 */
export const renderChangeAirports = (
  matchesMobile: boolean,
  closePicker: () => void
) => (
  <Box className="change-airports-modal-content modal-content">
    <Typography className="title">{searchCopy.CHANGE_AIRPORTS}</Typography>
    <Typography className="subtitle">
      {searchCopy.CHANGE_AIRPORTS_SUBTITLE}
    </Typography>
    <FlightAirportPicker
      closePicker={closePicker}
      isMobile={matchesMobile}
      className="flight-airport-picker-container"
    />
  </Box>
);

/**
 * @description
 * @return {ReactNode}
 */
export const renderChangeFailed = () => (
  <GenericModalContent
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={buttonText.CONTACT_SUPPORT}
        onClick={openSupportInNewTab}
      />
    }
    className="change-failed-modal-content modal-content"
    image={<Icon name={IconName.ErrorState} />}
    subtitle={searchCopy.CHANGE_FAILED_SUBTITLE}
    title={searchCopy.CHANGE_FAILED_TITLE}
  />
);

/**
 * @description
 * @return {ReactNode}
 */
export const renderChangeRequested = (goBackToMyTrips: () => void) => (
  <GenericModalContent
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={buttonText.DONE}
        onClick={goBackToMyTrips}
      />
    }
    className="change-requested-modal-content modal-content"
    image={<Icon className="success-icon" name={IconName.Checked} />}
    subtitle={searchCopy.CHANGE_REQUESTED_SUBTITLE}
    title={searchCopy.CHANGE_REQUESTED_TITLE}
  />
);

/**
 * @description
 * @return {ReactNode}
 */
export const renderContactSupportInfo = (
  modalSubtitleRef: React.ReactNode,
  modalTitleRef: string
) => {
  return (
    <GenericModalContent
      className="contact-support-modal-content modal-content"
      image={<Icon aria-hidden name={IconName.UnableToProcess} />}
      subtitle={modalSubtitleRef}
      title={modalTitleRef}
    />
  );
};

/**
 * @description
 * @return {ReactNode}
 */
export const renderIneligibleForExchange = (exitExchange: () => void) => (
  <GenericModalContent
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={buttonText.CONTACT_SUPPORT}
        onClick={() => {
          openSupportInNewTab();
          exitExchange();
        }}
      />
    }
    className="itin-not-found-modal-content modal-content"
    image={<Icon name={IconName.ErrorState} />}
    subtitle={searchCopy.INELIGIBLE_FOR_EXCHANGE_SUBTITLE}
    title={searchCopy.INELIGIBLE_FOR_EXCHANGE_TITLE}
  />
);

/**
 * @description
 * @return {ReactNode}
 */
export const renderItineraryNotFound = (exitExchange: () => void) => (
  <GenericModalContent
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={buttonText.CONTACT_SUPPORT}
        onClick={() => {
          openSupportInNewTab();
          exitExchange();
        }}
      />
    }
    className="itin-not-found-modal-content modal-content"
    image={<Icon name={IconName.ErrorState} />}
    subtitle={searchCopy.ITINERARY_NOT_FOUND_SUBTITLE}
    title={searchCopy.ITINERARY_NOT_FOUND_TITLE}
  />
);

/**
 * @description
 * @return {ReactNode}
 */
export const renderLoadFailed = (handleTryAgain: () => void) => (
  <GenericModalContent
    actions={
      <>
        <ActionButton
          defaultStyle="h4r-primary"
          message={buttonText.TRY_AGAIN}
          onClick={handleTryAgain}
        />
        <ActionButton
          defaultStyle="h4r-secondary"
          message={buttonText.CONTACT_SUPPORT}
          onClick={openSupportInNewTab}
        />
      </>
    }
    className="load-failed-modal-content modal-content"
    image={<Icon name={IconName.UnableToProcess} />}
    subtitle={searchCopy.LOAD_FAILED_SUBTITLE}
    title={searchCopy.LOAD_FAILED_TITLE}
  />
);

/**
 * @description
 * @return {ReactNode}
 */
export const renderLoadingOrProcessing = (
  subtitle: React.ReactNode,
  title: string
) => <B2BSpinnerWithText subtitle={subtitle} title={title} />;

/**
 * @description
 * @return {ReactNode}
 */
export const renderTicketedVoidable = (
  onClick: () => void,
  policyRef?: IFlightExchangePolicyRes
) => {
  const voidWindowEnd = getPolicyAttr("voidWindowEnd", policyRef);
  const subtitle = searchCopy.VOIDABLE_SUBTITLE(voidWindowEnd);

  return (
    <GenericModalContent
      actions={
        <ActionButton
          defaultStyle="h4r-primary"
          message={buttonText.VOIDABLE}
          onClick={onClick}
        />
      }
      className="ticketed-voidable-modal-content modal-content"
      image={<Icon aria-hidden name={IconName.UnableToProcess} />}
      subtitle={subtitle}
      title={searchCopy.VOIDABLE_TITLE}
    />
  );
};

/**
 * @description
 * @return {ReactNode}
 */
export const renderTooManyAttemptsSupport = () => (
  <GenericModalContent
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={buttonText.CONTACT_SUPPORT}
        onClick={openSupportInNewTab}
      />
    }
    className="too-many-attempts-modal-content modal-content"
    image={<Icon name={IconName.ErrorState} />}
    subtitle={searchCopy.TOO_MANY_ATTEMPTS_SUPPORT_SUBTITLE}
    title={searchCopy.TOO_MANY_ATTEMPTS_SUPPORT_TITLE}
  />
);

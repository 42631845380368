import { CallState, RewardsAccount } from "redmond";
import { put } from "redux-saga/effects";

import { fetchRewardsAccounts } from "../../api/v1/rewards/fetchRewardsAccounts";
import {
  setRewardsAccounts,
  setRewardsAccountsCallState,
} from "../../reducers/user";

export function* fetchRewardsAccountsSaga() {
  try {
    const rewardsAccounts: RewardsAccount[] = yield fetchRewardsAccounts();

    yield put(setRewardsAccounts(rewardsAccounts));
  } catch (e) {
    yield put(setRewardsAccountsCallState(CallState.Failed));
  }
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CallState, RewardsAccount } from "redmond";

export interface ExchangeModuleUserState {
  agentEmail: string;
  isFirstLaunch: boolean;
  rewardsAccountCallState: CallState;
  rewardsAccounts: RewardsAccount[];
  selectedAccountRefId: string | null;
}

const initialState: ExchangeModuleUserState = {
  agentEmail: "",
  isFirstLaunch: false,
  rewardsAccountCallState: CallState.NotCalled,
  rewardsAccounts: [],
  selectedAccountRefId: null,
};

export const userSlice = createSlice({
  initialState,
  name: "exchange/user",
  reducers: {
    fetchRewardsAccounts: (state: ExchangeModuleUserState) => {
      state.rewardsAccountCallState = CallState.InProcess;
    },
    setAgentEmail: (
      state: ExchangeModuleUserState,
      action: PayloadAction<string>
    ) => {
      state.agentEmail = action.payload;
    },
    setIsFirstLaunch: (
      state: ExchangeModuleUserState,
      action: PayloadAction<boolean>
    ) => {
      state.isFirstLaunch = action.payload;
    },
    setRewardsAccountsCallState: (
      state: ExchangeModuleUserState,
      action: PayloadAction<CallState>
    ) => {
      state.rewardsAccountCallState = action.payload;
    },
    setRewardsAccounts: (
      state: ExchangeModuleUserState,
      action: PayloadAction<RewardsAccount[]>
    ) => {
      const { payload: accounts } = action;

      state.rewardsAccounts = accounts;
      state.rewardsAccountCallState = CallState.Success;

      if (accounts.length) {
        state.selectedAccountRefId = accounts[0].accountReferenceId;
      }
    },
    setSelectedAccountRefId: (
      state: ExchangeModuleUserState,
      action: PayloadAction<string | null>
    ) => {
      state.selectedAccountRefId = action.payload;
    },
  },
});

export const {
  fetchRewardsAccounts,
  setAgentEmail,
  setIsFirstLaunch,
  setRewardsAccountsCallState,
  setRewardsAccounts,
  setSelectedAccountRefId,
} = userSlice.actions;

export default userSlice.reducer;

import { CurrencyFormatters } from "halifax";

export const headerTitles = Object.freeze({
  CHANGE_FLIGHT: "Change Flight",
  CHOOSE_FLIGHT: (cityName: string, onReturnStep: boolean = false) =>
    `Choose ${onReturnStep ? "Return" : "Outbound"} flight to ${cityName}`,
  REVIEW_ITINERARY: "Review itinerary",
  SUBMIT_REQUEST: "Change Your Flight",
  USE_FTC: "Use Your Travel Credit",
});

export const headerSubtitles = Object.freeze({
  FTC_WORTH: (tcAmount: number) =>
    `Your travel credit is worth <b>${CurrencyFormatters.get().format(
      tcAmount
    )}</b>`,
  PRICES_ARE: (isOneWay: boolean = false) =>
    `Prices are ${isOneWay ? "one-way" : "round-trip"}, per traveler`,
  REQUEST_CHANGE: "Request a change to your flight itinerary",
  REVIEW: "Review the details of your trip before you continue",
  SUBMIT: "Review and submit your request",
});

import axios from "axios";
import { IFlightExchangePolicyRes } from "redmond";

import { config } from "../../config";
import {
  fetchFlightExchangePolicyPath,
} from "../paths";

const fetchFlightExchangePolicy = (
  itineraryId: string,
): Promise<IFlightExchangePolicyRes> =>
  new Promise(async (resolve, reject) => {
    try {
      const req = { itineraryId };
      const res = await axios.post(fetchFlightExchangePolicyPath, req, {
        baseURL: config.baseURL,
      });

      if (res.data.Response === "Success") {
        resolve(res.data.value);
      } else {
        reject(res.data.error);
      }

    // mock fetch load time & response
    // setTimeout(reject, 2500);
    } catch (e) {
      reject(e);
    }
  });

export default fetchFlightExchangePolicy;

import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import {
  Icon,
  IconName,
  MobileAutocompleteOptions,
  MobileOutlinedTextInput,
} from "halifax";
import clsx from "clsx";
import { debounce } from "lodash";
import { ILocationQueryLabel, ITripTerminus, LocationQueryEnum } from "redmond";

import fetchLocations from "../../api/v1/exchange/fetchLocations";
import { CloseReason, searchCopy } from "../../constants";

import "./styles.scss";

export interface IMobileAirportPickerProps {
  destination: ITripTerminus | null;
  onClose: (reason: CloseReason) => void;
  origin: ITripTerminus | null;
  setDestination: (newDest: ITripTerminus | null) => void;
  setOrigin: (newOrigin: ITripTerminus | null) => void;
  className?: string;
}

enum FormElement {
  Destination,
  None,
  Origin,
}

const MobileAirportPicker = (props: IMobileAirportPickerProps): JSX.Element => {
  const { destination, origin, setDestination, setOrigin, className } = props;
  const [destInput, setDestInput] = useState(destination?.label ?? "");
  const [destLoading, setDestLoading] = useState(false);
  const [destOpts, setDestOpts] = useState<any[]>([]);
  const [focusedEl, setFocusedEl] = useState(FormElement.None);
  const [lockedEl, setLockedEl] = useState(FormElement.None);
  const [originInput, setOriginInput] = useState(origin?.label ?? "");
  const [originLoading, setOriginLoading] = useState(false);
  const [originOpts, setOriginOpts] = useState<any[]>([]);

  /**
   * @description
   */
  const fetchLocationCategories = debounce(
    (forOrigin: boolean, searchText: string) => {
      const requestBody: ILocationQueryLabel = {
        LocationQuery: LocationQueryEnum.Label,
        l: searchText,
      };

      if (forOrigin) {
        setOrigin(null);
        setOriginLoading(true);
      } else {
        setDestination(null);
        setDestLoading(true);
      }

      fetchLocations(requestBody)
        .then((res) => {
          const { categories } = res;

          if (forOrigin) setOriginOpts(categories);
          else setDestOpts(categories);
        })
        .catch(() => {
          if (forOrigin) setOriginOpts([]);
          else setDestOpts([]);
        })
        .finally(() => {
          if (forOrigin) setOriginLoading(false);
          else setDestLoading(false);
        });
    },
    300
  );

  useEffect(() => {}, [lockedEl]);

  return (
    <Box className={clsx("mobile-airport-picker-container", className)}>
      <MobileOutlinedTextInput
        autoFocus
        className={clsx("exchange-origin-autocomplete", {
          focused: focusedEl === FormElement.Origin,
        })}
        customIcon={<Icon name={IconName.B2BOriginIcon} />}
        onBlur={() => setLockedEl(FormElement.None)}
        onChange={(searchVal: string) => {
          setOriginInput(searchVal);
          fetchLocationCategories(true, searchVal);
        }}
        onFocus={() => {
          setFocusedEl(FormElement.Origin);
          setLockedEl(FormElement.Origin);
        }}
        prefixString={searchCopy.WHERE_FROM}
        value={originInput}
      />
      <MobileOutlinedTextInput
        className={clsx("exchange-destination-autocomplete", {
          focused: focusedEl === FormElement.Destination,
        })}
        customIcon={<Icon name={IconName.B2BAirplaneIcon} />}
        onBlur={() => setLockedEl(FormElement.None)}
        onChange={(searchVal: string) => {
          setDestInput(searchVal);
          fetchLocationCategories(false, searchVal);
        }}
        onFocus={() => {
          setFocusedEl(FormElement.Destination);
          setLockedEl(FormElement.Destination);
        }}
        prefixString={searchCopy.WHERE_TO}
        value={destInput}
      />
      {focusedEl === FormElement.Origin && (
        <MobileAutocompleteOptions
          loading={originLoading}
          loadingText="Loading"
          setValue={(value: any) => {
            setFocusedEl(FormElement.None);
            setOrigin(value);
            setOriginInput(value.label);
          }}
          value={origin}
          valueCategories={originOpts}
        />
      )}
      {focusedEl === FormElement.Destination && (
        <MobileAutocompleteOptions
          loading={destLoading}
          loadingText="Loading"
          setValue={(value: any) => {
            setDestination(value);
            setDestInput(value.label);
            setFocusedEl(FormElement.None);
          }}
          value={destination}
          valueCategories={destOpts}
        />
      )}
    </Box>
  );
};

export default MobileAirportPicker;

export const apiVersionPrefix = "/api/v0";

export const fetchCalendarPath: string = `${apiVersionPrefix}/calendar`;

const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;
export const fetchExperimentsPath = `${experimentsApiPrefix}/active`;

const itineraryApiPrefix = `${apiVersionPrefix}/itinerary`;
export const fetchFlightItineraryPath = `${itineraryApiPrefix}/flight/single`;

export const fetchLocationsPath = `${apiVersionPrefix}/autocomplete/location`;

export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;
export const fetchRewardsAccountsPath = `${rewardsApiPrefix}/accounts`;

export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;

const selfServeApiPrefix = `${apiVersionPrefix}/selfserve`;
export const fetchFlightExchangePolicyPath = `${selfServeApiPrefix}/farerules/exchange`;
export const fetchFlightExchangeEligibilityQuotePath = `${selfServeApiPrefix}/flight/exchangeEligibilityQuote`;

export const fetchFlightSummariesPathV1 = `/api/v1/selfserve/exchangeShop`;
export const fetchFlightSummariesPathV2 = `/api/v0/selfserve/flight/exchangeShop`;
export const submitFlightExchangePath = `${selfServeApiPrefix}/exchangeSubmit`;

export const exchangeTripDetailsPath = `${selfServeApiPrefix}/tripDetails`;
export const exchangeTripDetailsPathV2 = `${selfServeApiPrefix}/flight/tripDetails`;

export const scheduleExchangePriceQuotePath = `${selfServeApiPrefix}/flight/exchangeQuoteSchedule`;
export const pollExchangePriceQuotePath = `${selfServeApiPrefix}/flight/exchangeQuotePoll`;
export const scheduleExchangeBookFinalizePath = `${selfServeApiPrefix}/flight/exchangeBookSchedule`;
export const pollExchangeBookFinalizePath = `${selfServeApiPrefix}/flight/exchangeBookPoll`;

const trackingApiPrefix = `${apiVersionPrefix}/tracking`;
export const postAnalyticEventPath = `${trackingApiPrefix}/event`;

export const fetchCityNamePath = `${apiVersionPrefix}/cityName`; //get the name of a city by city code or airport code

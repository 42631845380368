import React from "react";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { FareClasses } from "redmond";

import { fareClasses } from "../../../../constants";
import { setFareClassesFilter } from "../../../../reducers/flightShop";
import { getFareClassesFilter } from "../../../../selectors";

import "./styles.scss";

export interface IFareClassTogglesProps {
  isMobile: boolean;
}

const defaultProps: Partial<IFareClassTogglesProps> = {
  isMobile: false,
};

const FareClassToggles = (/*props: IFareClassRogglesProps*/): JSX.Element => {
  // const { } = props;
  const { matchesDesktop, matchesLargeDesktop, matchesMobile } =
    useDeviceTypes();
  const dispatch = useDispatch();
  const fareClassesFilter = useSelector(getFareClassesFilter);
  const fareClassesOpts = [
    fareClasses.BASIC,
    fareClasses.STANDARD,
    fareClasses.ENHANCED,
    fareClasses.PREMIUM,
    fareClasses.LUXURY,
  ];
  const fareClassFilterSet = Object.values(fareClassesFilter).includes(true);

  /**
   * @param {string} fareClass The class to toggle. Sets all fare classes to `true` if not provided
   */
  const toggleFareClass = (fareClass?: string) => {
    let fareClasses: FareClasses;

    if (fareClass) {
      fareClasses = {
        ...fareClassesFilter,
        [fareClass]: !fareClassesFilter[fareClass],
      };
    } else {
      fareClasses = {
        basic: true,
        enhanced: true,
        luxury: true,
        premium: true,
        standard: true,
      };
    }

    dispatch(setFareClassesFilter(fareClasses));
  };

  return (
    <Box
      className={clsx("fare-class-toggles-container", {
        mobile: matchesMobile,
        "medium-desktop": matchesDesktop && !matchesLargeDesktop,
      })}
    >
      {matchesMobile && (
        <Box
          className={clsx("fare-class-toggle", {
            selected: matchesMobile && fareClassFilterSet,
          })}
          key="all"
          onClick={() => toggleFareClass()}
        >
          <Button variant="outlined">All</Button>
        </Box>
      )}
      {fareClassesOpts.map((fareClass) => (
        <Box
          className={clsx("fare-class-toggle", {
            selected: fareClassesFilter[fareClass.tag],
          })}
          id={`fare-class-${fareClass.title}`}
          key={fareClass.title}
        >
          <Tooltip
            classes={{ tooltip: "fare-class-tooltip" }}
            id={`fare-class-${fareClass.title}-tooltip`}
            key={fareClass.title}
            placement="top"
            title={
              matchesMobile ? (
                ""
              ) : (
                <Box aria-hidden>
                  <Typography className="tooltip-title" variant="h6">
                    {fareClass.title}
                  </Typography>
                  <Typography className="tooltip-desc" variant="caption">
                    {fareClass.desc}
                  </Typography>
                </Box>
              )
            }
          >
            <Button
              aria-label={
                matchesMobile ? "" : `${fareClass.title} - ${fareClass.desc}`
              }
              aria-pressed={Boolean(fareClassesFilter[fareClass.tag])}
              onClick={() => toggleFareClass(fareClass.tag)}
              variant="outlined"
            >
              {fareClass.title}
            </Button>
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
};

FareClassToggles.defaultProps = defaultProps;

export default FareClassToggles;

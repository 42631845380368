import React, { useContext, useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import {ConfirmFlightExchangeAutomated, ConfirmFlightExchangeManual} from "../ConfirmFlightExchange";
import { ErrorBoundary } from "../ErrorBoundary";
import { FlightExchangeHeader } from "../FlightExchangeHeader";
import { FlightReshop } from "../FlightReshop";
import { FlightSearch } from "../FlightSearch";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import {
  fetchRewardsAccounts,
  setAgentEmail,
  setIsFirstLaunch,
} from "../../reducers/user";
import {
  BASE_PATH_HOME,
  PATH_FLIGHT_CONFIRM,
  PATH_FLIGHT_CONFIRM_AUTOMATED,
  PATH_FLIGHT_EXCHANGE,
  PATH_FLIGHT_SEARCH,
  PATH_FLIGHT_SHOP,
} from "../../utils/paths";
import { ClientContext } from "../../App";

const BOOKING_ID = "bookingId";

const Body = (): JSX.Element => {
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const location = useLocation();
  const { sessionInfo } = useContext(ClientContext);
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const bookingId = searchParams.get(BOOKING_ID) ?? "";

  useEffect(() => {
    dispatch(fetchRewardsAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionInfo?.csrfToken) {
      const { isDelegatedSession, isFirstSession } = sessionInfo;

      dispatch(setIsFirstLaunch(isFirstSession));

      if (isDelegatedSession) {
        dispatch(setAgentEmail(isDelegatedSession));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo]);

  return (
    <Box className="main-section">
      <ErrorBoundary bookingId={bookingId} isMobile={matchesMobile}>
        <FlightExchangeHeader isMobile={matchesMobile} />
        <Route exact path={PATH_FLIGHT_CONFIRM}>
          <ConfirmFlightExchangeManual isMobile={matchesMobile} />
        </Route>
        <Route exact path={[PATH_FLIGHT_EXCHANGE, PATH_FLIGHT_SEARCH]}>
          <FlightSearch bookingId={bookingId} />
        </Route>
        <Route path={PATH_FLIGHT_SHOP}>
          <FlightReshop isMobile={matchesMobile} />
        </Route>
        <Route exact path={PATH_FLIGHT_CONFIRM_AUTOMATED}>
          <ConfirmFlightExchangeAutomated isMobile={matchesMobile} />
        </Route>
        <Route exact path={BASE_PATH_HOME}>
          <Redirect to={PATH_FLIGHT_EXCHANGE} />
        </Route>
      </ErrorBoundary>
    </Box>
  );
};

export default Body;

import {PaymentOpaqueValue} from "@b2bportal/purchase-api/lib/api";
import {Payment} from "@b2bportal/purchase-api";
import {roundToTwoDecimals} from "halifax";
import {ConvertUsdToRewardsRequest, CreditCard, PaymentV2Enum, RewardsAccount} from "redmond/build/apis";
import {FiatAmountV2} from "redmond/build/apis/tysons/payment-machine";
import {convertUsdToRewards} from "../../api/v1/rewards/convertUsdToRewards";

export interface IExchangeUserPaymentsProps {
  totalPaymentFiatAmount: FiatAmountV2;
  paymentMethods: CreditCard[];
  rewards: RewardsAccount[];
}

const defaultProps: Partial<IExchangeUserPaymentsProps> = {
  totalPaymentFiatAmount: {
    amount: 0,
    currency: "",
  },
  paymentMethods: [],
  rewards: [],
};

const defaultCurrency: string = "USD";

export const ExchangeUserPayments = (
  props: IExchangeUserPaymentsProps
) => {
  let totalPaymentFiatAmount: FiatAmountV2, cardPaymentMethods: CreditCard[], rewards: RewardsAccount[];
  ({totalPaymentFiatAmount, paymentMethods: cardPaymentMethods, rewards} = props);

  const buildRewardsPayment = async (
    accountReferenceId: string,
    rewardsFiatAmount: number,
  ): Promise<PaymentOpaqueValue> => {

    const rewardsRequest: ConvertUsdToRewardsRequest = {
      amount: rewardsFiatAmount,
      accountReferenceId: accountReferenceId,
    }

    return convertUsdToRewards(rewardsRequest)
      .then((rewardsPrice) => {
        return {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: accountReferenceId,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatAmount),
                currency: defaultCurrency,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPrice?.value ?? 0),
                currency: rewardsPrice?.currency ?? "Rewards",
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          } as any,
        };
      })
      .catch((e) => {
        const message = "Unable to fetch rewards account for " + accountReferenceId + ", " + e;
        return Promise.reject(message);
      });
  };

  const buildUserCardPayment = (
    accountReferenceId: string,
    cardFiatAmount: FiatAmountV2,
    paymentMethodId: string,
    last4: string | undefined,
  ) => {
    return {
      type: Payment.Card,
      value: {
        paymentId: paymentMethodId,
        accountReferenceId: accountReferenceId,
        paymentAmount: {
          currency: cardFiatAmount.currency ? cardFiatAmount.currency : defaultCurrency,
          amount: roundToTwoDecimals(cardFiatAmount.amount),
        },
        last4: last4,
        PaymentV2: PaymentV2Enum.UserCard,
      } as any,
    };
  };

  const rewardsCoverTotalAmount = (rewards: RewardsAccount): boolean => {
    return rewards.rewardsCashEquivalent.value >= totalPaymentFiatAmount.amount
  };

  // TODO - account for multiple payment methods, to give user a choice.
  //  The backend currently only handles one card and one rewards account
  //  at least until the purchase api is used
  const cardPaymentMethod: CreditCard = cardPaymentMethods[0];

  let cardFiatAmount: FiatAmountV2 = {
    amount: 0.0,
    currency: defaultCurrency,
  };
  let rewardsFiatAmount = 0.0;

  if (rewardsCoverTotalAmount(rewards[0])) {
    rewardsFiatAmount = totalPaymentFiatAmount.amount;
  } else {
    // safeguard against overcharging rewards
    rewardsFiatAmount = Math.min(rewards[0].rewardsCashEquivalent.value, totalPaymentFiatAmount.amount);

    cardFiatAmount = {
      amount: totalPaymentFiatAmount.amount - rewardsFiatAmount,
      currency: totalPaymentFiatAmount.currency,
    };
  }

  return buildRewardsPayment(
    rewards[0].accountReferenceId,
    rewardsFiatAmount,
  ).then((rewardsPayment) => {

    const cardPayment: PaymentOpaqueValue = buildUserCardPayment(
      rewards[0].accountReferenceId,
      cardFiatAmount,
      cardPaymentMethod.id,
      cardPaymentMethod.last4,
    );
    const payments: Array<PaymentOpaqueValue> = [
      rewardsPayment,
      cardPayment,
    ];

    return payments;
  });
};

ExchangeUserPayments.defaultProps = defaultProps;

export default ExchangeUserPayments;

import axios, { AxiosResponse } from "axios";
import { ConvertUsdToRewardsRequest, RewardsPrice } from "redmond";

import { config } from "../../config";

import { rewardsApiPrefix } from "../paths";

const END_POINT: string = `${rewardsApiPrefix}/convert/redeem/usdToRewards`;

export const convertUsdToRewards = (
  request: ConvertUsdToRewardsRequest
): Promise<RewardsPrice> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<RewardsPrice> = await axios.put(END_POINT, request, {
        baseURL: config.baseURL,
      });

      resolve((res.data as any).value);
    } catch (e) {
      reject(e);
    }
  });

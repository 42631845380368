import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { AirlineFilter } from "../AirlineFilter";
import { AirportFilter } from "../AirportFilter";
import { DepartureArrivalFilter } from "../DepartureArrivalFilter";
import { FlightNumberFilter } from "../FlightNumberFilter";
import { MaxPriceFilter } from "../MaxPriceFilter";
import { MobileFilterRow } from "../MobileFilterRow";
import { StopsCountFilter } from "../StopsCountFilter";
import { getFlightFilterOptions } from "../../../../../../selectors";

import "./styles.scss";

export interface IFlightReshopSearchFilterProps {
  isMobile: boolean;
}

const defaultProps: Partial<IFlightReshopSearchFilterProps> = {
  isMobile: false,
};

const FlightReshopSearchFilter = (props: IFlightReshopSearchFilterProps) => {
  const { isMobile } = props;
  const filters = useSelector(getFlightFilterOptions);
  const { airlineOpts, airportOpts, flightNumberOpts, priceMax, priceMin } =
    filters;

  return isMobile ? (
    <MobileFilterRow filters={filters} />
  ) : (
    <Box className="desktop-reshop-filters-root">
      <StopsCountFilter />
      <MaxPriceFilter maxPrice={priceMax.value} minPrice={priceMin.value} />
      <AirlineFilter airlines={airlineOpts} />
      <DepartureArrivalFilter />
      <AirportFilter airports={airportOpts} />
      <FlightNumberFilter
        airlines={airlineOpts}
        flightNumberOpts={flightNumberOpts}
      />
    </Box>
  );
};

FlightReshopSearchFilter.defaultProps = defaultProps;

export default FlightReshopSearchFilter;

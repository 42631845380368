import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreditCard,
  IExchangePriceQuote,
  Maybe,
  RewardsPrice,
  Uuid,
} from "redmond";

import { resetState } from "./search";

export interface FlightBookState {
  sessionId: Maybe<Uuid>;
  cartQuoteId?: Uuid;
  idempotencyKey?: Uuid;
  priceQuote: Maybe<IExchangePriceQuote>;
  paymentMethods: CreditCard[];
  rewardsPrice: Maybe<RewardsPrice>;
}

export const initialPriceQuote: IExchangePriceQuote = {
  reservationId: "",
  totalPricing: {
    baseAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    passengerFeeAmount: 0,
    currencyCode: "",
  },
  records: [],
};

const initialState: FlightBookState = {
  sessionId: "",
  cartQuoteId: "",
  idempotencyKey: "",
  priceQuote: initialPriceQuote,
  paymentMethods: [],
  rewardsPrice: null,
};

export const FlightBookSlice = createSlice({
  initialState,
  name: "exchange/flightBook",
  reducers: {
    setExchangeBook(state: FlightBookState, action: PayloadAction<Uuid>) {
      state.sessionId = action.payload;
    },
    setSessionId: (state: FlightBookState, action: PayloadAction<Uuid>) => {
      state.sessionId = action.payload;
    },
    setCartQuoteId: (
      state: FlightBookState,
      action: PayloadAction<Uuid | undefined>
    ) => {
      state.cartQuoteId = action.payload;
    },
    setIdempotencyKey: (
      state: FlightBookState,
      action: PayloadAction<Uuid | undefined>
    ) => {
      state.idempotencyKey = action.payload;
    },
    setPriceQuote: (
      state: FlightBookState,
      action: PayloadAction<IExchangePriceQuote>
    ) => {
      state.priceQuote = action.payload;
    },
    setPaymentMethods: (
      state: FlightBookState,
      action: PayloadAction<CreditCard[]>
    ) => {
      state.paymentMethods = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addDefaultCase((_state, _action) => {});
  },
});

export const {
  setExchangeBook,
  setSessionId,
  setCartQuoteId,
  setIdempotencyKey,
  setPriceQuote,
  setPaymentMethods,
} = FlightBookSlice.actions;

export default FlightBookSlice.reducer;

import { Box, Typography } from "@material-ui/core";
import { FlightPricingLineItem, PricingRow } from "halifax";
import React from "react";

import { confirmCopy } from "../../../../constants";

import "./styles.scss";

export interface ITravelerRowProps {
  expanded: boolean;
  pricing: FlightPricingLineItem;
}

const defaultProps: Partial<ITravelerRowProps> = {};

const TravelerRow = (props: ITravelerRowProps): JSX.Element => {
  const { expanded, pricing } = props;
  const {
    baseAmount,
    cfarFee,
    changeFee,
    lineTitle: travelerName,
    taxesAndFees,
  } = pricing;

  return (
    <Box className="traveler-row">
      <Typography className="traveler-name" variant="subtitle1">
        {travelerName}
      </Typography>
      {expanded && (
        <>
          {baseAmount ? (
            <PricingRow
              amount={baseAmount}
              currency="USD"
              key="base-amount"
              label={confirmCopy.BASE_FARE}
            />
          ) : null}
          {taxesAndFees ? (
            <PricingRow
              amount={taxesAndFees}
              currency="USD"
              key="taxes-and-fees"
              label={confirmCopy.TAXES_FEES}
            />
          ) : null}
          {changeFee ? (
            <PricingRow
              amount={changeFee}
              currency="USD"
              key="change-fee"
              label={confirmCopy.CHANGE_FEE}
            />
          ) : null}
          {cfarFee ? (
            <PricingRow
              amount={cfarFee}
              currency="USD"
              key="cfar-fee"
              label={confirmCopy.CFAR_COVERAGE}
            />
          ) : null}
        </>
      )}
    </Box>
  );
};

TravelerRow.defaultProps = defaultProps;

export default TravelerRow;

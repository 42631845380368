import React, { useContext } from "react";
import { GenericBanner } from "halifax";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { B2B_PORTAL_BANNER_CONTAINER_ID, CustomerAccountRole } from "redmond";
import { REWARDS_BANNER_TEXT } from "@capone/common";

import { ClientContext } from "../../App";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import { getRewardsAccountWithLargestEarnBanner } from "../../selectors";
import { config } from "../../api/config";

export interface IRewardsBannerProps extends RouteComponentProps {}

const defaultProps: Partial<IRewardsBannerProps> = {};

const RewardsBanner = (_props: IRewardsBannerProps) => {
  const { firstName = "" } =
    useContext(ClientContext).sessionInfo?.userInfo ?? {};
  const { matchesMobile } = useDeviceTypes();
  const rewardsAccount = useSelector(getRewardsAccountWithLargestEarnBanner);

  if (!rewardsAccount) return null;

  const {
    earn: {
      hotelsMultiplier: earnRate = "",
      flightsMultiplier: flightEarnRate = "",
    },
    rewardsBalance,
    lastFour = "",
    productDisplayName: productId = "",
  } = rewardsAccount;
  const { currency } = rewardsBalance || {};
  let contentText = "";

  if (!matchesMobile) {
    contentText = REWARDS_BANNER_TEXT({
      firstName,
      earnRate,
      productId,
      lastFour,
      flightEarnRate,
      currency,
      isPrimary:
        rewardsAccount.customerAccountRole === CustomerAccountRole.Primary,
      tenant: config.TENANT,
    });
  } else {
    contentText = "";
  }

  if (!contentText) return null;

  return (
    <GenericBanner
      className="rewards-banner-root"
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      content={contentText}
      isMobile={matchesMobile}
    />
  );
};

RewardsBanner.defaultProps = defaultProps;

export default withRouter(RewardsBanner);

import dayjs from "dayjs";
import { Flights, IFlightListData } from "redmond";

export const orderByArrivalTime = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.slice].arrival).diff(
      dayjs(flights.slices[b.slice].arrival)
    )
  );
};

export const orderByDepartureTime = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.slice].departure).diff(
      dayjs(flights.slices[b.slice].departure)
    )
  );
};

export const orderByDuration = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) => {
    const aMin = dayjs(flights.slices[a.slice].arrival).diff(
      dayjs(flights.slices[a.slice].departure),
      "minute",
      true
    );
    const bMin = dayjs(flights.slices[b.slice].arrival).diff(
      dayjs(flights.slices[b.slice].departure),
      "minute",
      true
    );

    return aMin - bMin;
  });
};

export const orderByPrice = (filteredFlights: IFlightListData[]) => {
  return filteredFlights.sort(
    (a, b) =>
      Math.min(...a.fares.map((f: any) => f.amount.fiat.value)) -
      Math.min(...b.fares.map((f: any) => f.amount.fiat.value))
  );
};

export const orderByRecommended = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort(
    (a, b) =>
      flights.slices[b.slice].highestScore.value -
      flights.slices[a.slice].highestScore.value
  );
};

export const orderByStops = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort(
    (a, b) => flights.slices[a.slice].stops - flights.slices[b.slice].stops
  );
};

const allSorters = {
  orderByArrivalTime,
  orderByDepartureTime,
  orderByDuration,
  orderByPrice,
  orderByRecommended,
  orderByStops,
};

export default allSorters;

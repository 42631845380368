import { ActionPattern, all, takeEvery, takeLatest } from "redux-saga/effects";

import { fetchTripSummariesSaga } from "./flightShop/fetchTripSummariesSaga";
import { fetchTripDetails, fetchTripSummaries } from "../reducers/flightShop";
import { watchFetchRewardsAccounts } from "./rewards";
import fetchTripDetailsSaga from "./flightShop/fetchTripDetailsSaga";

export function* watchFetchFlightDetailsSaga() {
  yield takeEvery(
    fetchTripDetails.toString() as ActionPattern<any>,
    fetchTripDetailsSaga
  );
}

export function* watchFetchFlightSummariesSaga() {
  yield takeLatest(
    fetchTripSummaries.toString() as ActionPattern<any>,
    fetchTripSummariesSaga
  );
}

export default function* rootSaga() {
  yield all([
    watchFetchFlightDetailsSaga(),
    watchFetchFlightSummariesSaga(),
    watchFetchRewardsAccounts(),
  ]);
}

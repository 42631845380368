import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { ActionButton, B2BSearchAutocomplete, Icon, IconName } from "halifax";
import { debounce } from "lodash";
import { ILocationQueryLabel, ITripTerminus, LocationQueryEnum } from "redmond";

import fetchLocations from "../../api/v1/exchange/fetchLocations";
import { CloseReason, buttonText, searchCopy } from "../../constants";

export interface IDesktopAirportPickerProps {
  destination: ITripTerminus | null;
  onClose: (reason: CloseReason) => void;
  origin: ITripTerminus | null;
  setDestination: (newDestination: ITripTerminus | null) => void;
  setOrigin: (newOrigin: ITripTerminus | null) => void;
}

const DesktopAirportPicker = (
  props: IDesktopAirportPickerProps
): JSX.Element => {
  const { destination, onClose, origin, setDestination, setOrigin } = props;
  const [destOpts, setDestOpts] = useState<any[]>([]);
  const [destLoading, setDestLoading] = useState(false);
  const [originOpts, setOriginOpts] = useState<any[]>([]);
  const [originLoading, setOriginLoading] = useState(false);

  /**
   * @description
   */
  const fetchLocationCategories = debounce(
    (forOrigin: boolean, searchText: string) => {
      const requestBody: ILocationQueryLabel = {
        LocationQuery: LocationQueryEnum.Label,
        l: searchText,
      };

      if (forOrigin) {
        setOrigin(null);
        setOriginLoading(true);
      } else {
        setDestination(null);
        setDestLoading(true);
      }

      fetchLocations(requestBody)
        .then((res: any) => {
          const { categories } = res;

          if (forOrigin) setOriginOpts(categories);
          else setDestOpts(categories);
        })
        .catch(() => {
          if (forOrigin) setOriginOpts([]);
          else setDestOpts([]);
        })
        .finally(() => {
          if (forOrigin) setOriginLoading(false);
          else setDestLoading(false);
        });
    },
    300
  );

  /**
   * @description Underlying MapFn used by Autocomplete to find the selected
   * option in the list of options
   * @param {(ITripTerminus | null)} option
   * @param {(ITripTerminus | null)} value
   */
  const isOptionSelected = (
    option: ITripTerminus | null,
    value: ITripTerminus | null
  ) => value?.id?.code?.code === option?.id?.code?.code;

  return (
    <Box>
      <B2BSearchAutocomplete
        autoFocus
        className="exchange-origin-autocomplete"
        customIcon={<Icon name={IconName.B2BMapPin} />}
        fetchValueCategories={(searchText) =>
          fetchLocationCategories(true, searchText)
        }
        getOptionSelected={isOptionSelected}
        label={searchCopy.WHERE_FROM}
        loading={originLoading}
        setValue={setOrigin}
        value={origin}
        valueCategories={originOpts}
      />
      <B2BSearchAutocomplete
        className="exchange-destination-autocomplete"
        customIcon={<Icon name={IconName.B2BMapPin} />}
        fetchValueCategories={(searchText) =>
          fetchLocationCategories(false, searchText)
        }
        getOptionSelected={isOptionSelected}
        label={searchCopy.WHERE_TO}
        loading={destLoading}
        setValue={setDestination}
        value={destination}
        valueCategories={destOpts}
      />
      <ActionButton
        className="change-airports-submit-btn"
        defaultStyle="h4r-primary"
        disabled={!destination || !origin}
        message={buttonText.CONTINUE}
        onClick={() => onClose(CloseReason.submit)}
      />
    </Box>
  );
};

export default DesktopAirportPicker;

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { CurrencyFormatters } from "halifax";
import { Amount, TripCategory } from "redmond";
import { config } from "../api/config";
import { PATH_CORP_FAQ, isCorpTenant } from "@capone/common";

// access more date formatting options
dayjs.extend(advancedFormat);

export * from "./flightConfirm";
export * from "./flightShop";
export * from "./header";

export enum CloseReason {
  exit,
  submit,
}

export enum MobileFlightSearchStep {
  LocationSearch = 1,
  CalendarPicker = 2,
  Complete = 3,
}

export enum ModalState {
  ChangeAirports,
  ChangeDateTime,
  ChangeFailed,
  ChangeRequested,
  Closed,
  ContactSupport,
  FTCSearch,
  IneligibleForExchange,
  ItineraryNotFound,
  LoadFailed,
  LoadingOrProcessing,
  SliceSelection,
  TicketedVoidable,
  TooManyAttemptsSupport,
  PriceQuoteLoadingOrProcessing,
  BookFinalizeLoadingOrProcessing,
}

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}

export enum SliceType {
  departure = "departure",
  return = "return",
}

export enum TripType {
  Outbound = "Outbound",
  Return = "Return",
}

/**
 * Number of minutes in a day = 60 min/hour * 24 hour = 1440 min.
 */
export const DAY_IN_MINUTES = 1439;

export const FAQ_URL =
  "https://www.capitalone.com/help-center/capital-one-travel/";
export const SUPPORT_URL = isCorpTenant(config.TENANT)
  ? PATH_CORP_FAQ
  : "https://www.capitalone.com/help-center/capital-one-travel/";

export const buttonText = Object.freeze({
  APPLY_FILTERS: "Apply Filters",
  BACK_BTN: "Back to My Trips",
  CHANGE: "Change",
  CHANGE_DATES: "Change Dates",
  CHANGE_FLIGHT: "Change Flight",
  CHOOSE_DEPARTURE: "Choose Departure",
  CHOOSE_RETURN: "Choose Return",
  CONFIRM_AND_EXCHANGE: "Confirm and Exchange",
  CONTACT_SUPPORT: "Contact Support",
  CONTINUE: "Continue",
  DONE: "Done",
  FILTERS: "Filters",
  MODIFY_SEARCH: "Modify Search",
  MY_TRIPS: "My Trips",
  MODIFY: "Modify",
  OK: "Ok",
  RELOAD: "Reload",
  RESET_FILTERS: "Reset Filters",
  REVIEW_ITINERARY: "Review Itinerary",
  SAVE: "Save",
  SEARCH: "Search",
  SEARCH_AGAIN: "Search Again",
  SEARCH_FLIGHTS: "Search Flights",
  SEARCH_FOR_FLIGHTS: "Search for Flights",
  SUBMIT_REQUEST: "Submit Request",
  TRY_AGAIN: "Try Again",
  VIEW_DETAILS: "View Details",
  VIEW_FAQS: "View FAQs",
  VIEW_PRICING: "View Pricing Summary",
  VOIDABLE: "Cancel via My Trips",
});

export const tripTypeCopy = Object.freeze({
  [TripCategory.ONE_WAY]: "One way",
  [TripCategory.ROUND_TRIP]: "Round trip",
});

export const formats = Object.freeze({
  DISPLAY_DATE: "ddd, MMM DD", // Sat, Jan 01
  SHORT_DATE: "MMM DD", // Sat, Jan 01
  REQUEST_DATE: "YYYY-MM-DD", // 2021-01-13
  SUMMARY_TIME: "h:mm A", // 5:30 PM
  VOID_BY_DATE: "ddd, MMM Do [at] h:mm A", // Sat, Jan 1st at 5:30 PM
});

export const errorFallbackCopy = {
  SUBTITLE:
    "We're currently working to resolve an issue, please try again later.",
  TITLE: "Oops...something went wrong!",
};

export const searchCopy = Object.freeze({
  CALENDAR_UPDATING: "Updating your calendar",
  CHANGE_AIRPORTS: "Change Airports",
  CHANGE_AIRPORTS_SUBTITLE:
    "Enter your destinations to submit a request to change airports.",
  CHANGE_DATES_AND_TIMES: "Change Dates and Times",
  CHANGE_DATES: "Change Dates",
  CONFIRM_SEGMENTS_TO_CHANGE: "Select the flights you’d like to change.",
  CHANGE_DATES_SUBTITLE:
    "Select the arrival and departure dates for your flight",
  CHANGE_FAILED_SUBTITLE: "Change failed",
  CHANGE_FAILED_TITLE: "Please contact support for further assistance.",
  CHANGE_REQUESTED_SUBTITLE:
    "Our team is reviewing and will reach out to you directly within 24hr to complete your request.",
  CHANGE_REQUESTED_TITLE: "Change Requested!",
  CHOOSE_DATES: "Choose your dates",
  CHOOSE_FLIGHTS: "Choose your flights",
  CONTACT_SUPPORT: "Contact Support",
  DEPARTURE: "Departure",
  DESKTOP_CALENDAR_TITLE_ONE_WAY: "Select your departure date for your flight",
  DESKTOP_CALENDAR_TITLE_ROUND_TRIP:
    "Select the arrival and departure dates for your flight",
  INELIGIBLE_FOR_EXCHANGE_SUBTITLE: "contact support",
  INELIGIBLE_FOR_EXCHANGE_TITLE: "Your flight is ineligible for exchange",
  ITINERARY_NOT_FOUND_SUBTITLE:
    "We could not find your itinerary, please contact support",
  ITINERARY_NOT_FOUND_TITLE: "Itinerary not found",
  FTC_SUBTITLE:
    "You have a travel credit worth <b>$315.00</b> that you can use to book a trip with Air Canada.",
  LOAD_FAILED_SUBTITLE: "Please try again or contact support.",
  LOAD_FAILED_TITLE: "We couldn't load your flight change information",
  LOADING: "Loading flight details and exchange policy...",
  OUTBOUND: "Outbound",
  OUTBOUND_TO: "Outbound to",
  QUESTIONS: "Questions about your trip?",
  REDEEM_FTC: (airlineName: string) =>
    `Redeem Your ${airlineName} Travel Credit`,
  REDESIGN_SEARCH_SUBTITLE: (airlineName?: string, changeFee?: Amount) => {
    if (changeFee && changeFee.amount !== 0) {
      const { amount, currency } = changeFee;
      const airline = airlineName ? `${airlineName} ` : "";
      const strChangeFee = CurrencyFormatters.get(currency).format(amount);

      return `Note that the ${airline}change fee is ${strChangeFee} per passenger, plus any applicable fare difference`;
    }

    return `${
      airlineName || "Your airline"
    } allows changes for no fee, just pay any applicable fare difference`;
  },
  REDESIGN_SEARCH_TITLE: (isOneWay: boolean = false) =>
    `Change Your ${isOneWay ? "One-Way" : "Round-Trip"} Flight`,
  RETURN: "Return",
  RETURN_TO: "Return to",
  REVISED: "Revised",
  SELECT_FLIGHTS_TO_MODIFY: "Select which flights you'd like to modify",
  SUBTITLE: [
    "You can make changes to your upcoming trip with Air Canada for a fee of $100 USD per traveler plus any fare difference.",
    "Note that your request to make a change must be approved by an agent in order to take effect.",
  ],
  SUPPORT_INFO:
    "For any questions regarding your trip, please contact our support team and we'd be happy to help.",
  SUPPORT_PHONE: "844-422-6922",
  TITLE: "What would you like to change?",
  TOO_MANY_ATTEMPTS_SUPPORT_SUBTITLE:
    "after too many attempts finding eligible flight",
  TOO_MANY_ATTEMPTS_SUPPORT_TITLE: "Lets have an agent help you",
  TRAVEL_ALERT_SUBTITLE:
    "Fare rules are subject to change given current global travel advisories. Any change request will be reviewed by our team and we will assist in finding you the best option.",
  TRAVEL_ALERT_TITLE: "Travel Alert: Coronavirus (COVID-19)",
  USE_FTC: "Use Your Travel Credit",
  VOIDABLE_SUBTITLE: (voidEnd?: number) => {
    let subtitle =
      "Avoid change fees and access more flight options by canceling free of charge";

    if (voidEnd) {
      const endDate = dayjs(voidEnd).format(formats.VOID_BY_DATE);

      subtitle = `${subtitle} before ${endDate}`;
    }

    return `${subtitle}.`;
  },
  VOIDABLE_TITLE: "You should cancel free and rebook",
  WHERE_FROM: "Where from?",
  WHERE_TO: "Where to?",
});

import React from "react";
import { AlgomerchModal } from "halifax";
import { AlgomerchTag } from "redmond";

import {
  algomerchTitles,
  flightShopCopy,
  scoreFactorTitles,
} from "../../../../constants";

export interface IFlightAlgomerchModalProps {
  isMobile: boolean;
  isOpen: boolean;
  onClose: () => void;
  selectedCategory: AlgomerchTag;
  setSelectedCategory: (newTag: AlgomerchTag) => void;
}

const defaultProps: Partial<IFlightAlgomerchModalProps> = {
  isMobile: false,
  isOpen: false,
};

const FlightAlgomerchModal = (
  props: IFlightAlgomerchModalProps
): JSX.Element => {
  const { isMobile, isOpen, onClose, selectedCategory, setSelectedCategory } =
    props;

  return (
    <AlgomerchModal
      algomerchInfoTitle={flightShopCopy.ALGOMERCH_INFO_TITLE}
      algomerchTitles={algomerchTitles}
      className="b2b-algomerch-modal"
      isMobile={isMobile}
      onClose={onClose}
      openModal={isOpen}
      scoreFactorTitles={scoreFactorTitles}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
    />
  );
};

FlightAlgomerchModal.defaultProps = defaultProps;

export default FlightAlgomerchModal;

import axios from "axios";
import dayjs from "dayjs";
import { IFlightBookingInfoRes } from "redmond";

import { config } from "../../config";
import { fetchFlightItineraryPath } from "../paths";

const fetchBookingInfo = (
  bookingId: string
): Promise<IFlightBookingInfoRes> => {
  const req = {
    itineraryId: bookingId,
    referenceDateTime: dayjs().format(),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(fetchFlightItineraryPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};

export default fetchBookingInfo;

import { PayloadAction } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";
import { TripDetails } from "redmond";

import { fetchTripDetails } from "../../api/v1/shop/fetchTripDetails";
import { fetchTripDetailsV2 } from "../../api/v1/shop/fetchTripDetailsV2";
import { IFetchTripDetailsPayload, setTripDetails } from "../../reducers/flightShop";
import Logger from "../../utils/logger";

function* fetchTripDetailsSaga(
  action: PayloadAction<IFetchTripDetailsPayload>
) {

  const { tripId, isHackerFareReturn, mockData, isB2bExchangeShopExpEnabled } = action.payload;

  try {
    let tripDetails: TripDetails;

    if (isB2bExchangeShopExpEnabled) {
      tripDetails = yield fetchTripDetailsV2(tripId, isHackerFareReturn, mockData);

    } else {
      tripDetails = yield fetchTripDetails(tripId, isHackerFareReturn, mockData);
    }

    yield put(setTripDetails(tripDetails));
  } catch (e) {
    Logger.debug(e);
  }
}

export default fetchTripDetailsSaga;

import axios from "axios";
import dayjs from "dayjs";
import {
  IExchangeFlightShopResponse,
  IExchangeFlightSummariesReq,
  IExchangeFlightSummariesRes,
  VIEWED_FLIGHT_SHOP_LOADING,
} from "redmond";

import { trackEvent } from "../analytics/trackEvent";
import { config } from "../../config";
import { fetchFlightSummariesPathV2 } from "../paths";
import mockSummaries from "../../../utils/mockFlightSummaries.json";

const fetchFlightSummariesV2 = async (
  body: IExchangeFlightSummariesReq,
  mockDataExp = false
) => {
  if (mockDataExp) {
    return Promise.resolve(
      mockSummaries as unknown as IExchangeFlightSummariesRes
    );
  }

  const reqStartTime = dayjs();
  const res = await axios.post<IExchangeFlightShopResponse>(
    fetchFlightSummariesPathV2,
    body,
    {
      baseURL: config.baseURL,
    }
  );

  trackEvent({
    eventName: VIEWED_FLIGHT_SHOP_LOADING,
    properties: {
      runtime: dayjs().diff(reqStartTime, "seconds", true),
    },
  });

  if (Array.isArray(res.data)) {
    const errCodes = res.data.map((data) => data.code);

    throw new Error(
      `Failed to fetch flight summaries v2 - ${errCodes.join(". ")}`
    );
  }

  return res.data;
};

export default fetchFlightSummariesV2;

import axios from "axios";
import {
  AirExchangePriceQuotePollResponse,
  IPollExchangePriceQuoteReq,
  IPollExchangePriceQuoteRes,
  Uuid,
} from "redmond";

import { config } from "../../config";
import { pollExchangePriceQuotePath } from "../paths";

const pollExchangePriceQuote = (
  sessionId: Uuid,
  cartQuoteId?: Uuid
): Promise<IPollExchangePriceQuoteRes> => {
  let intervalId: number;

  return new Promise((resolve, reject) => {
    if (!sessionId) reject("Session ID is required");
    intervalId = window.setInterval(async () => {
      try {
        const req: IPollExchangePriceQuoteReq = {
          sessionId,
          cartQuoteId,
        };
        const pollRes = await axios.put<IPollExchangePriceQuoteRes>(
          pollExchangePriceQuotePath,
          req,
          {
            baseURL: config.baseURL,
          }
        );

        if (
          pollRes.data.AirExchangePriceQuotePollResponse ===
          AirExchangePriceQuotePollResponse.success
        ) {
          clearInterval(intervalId);
          return resolve(pollRes.data);
        } else if (
          pollRes.data.AirExchangePriceQuotePollResponse !==
          AirExchangePriceQuotePollResponse.pending
        ) {
          clearInterval(intervalId);
          return reject(pollRes.data.AirExchangePriceQuotePollResponse);
        }
      } catch (err) {
        clearInterval(intervalId);
      }
    }, 3000);
  });
};

export default pollExchangePriceQuote;

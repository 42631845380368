import React, { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { GenericDropdown, IOption, LabelDropDown } from "halifax";
import { useDispatch, useSelector } from "react-redux";
import {
  AirlineCode,
  APPLIED_FILTER,
  IFlightNumberFilter,
  IFlightNumberMap,
  ISelectOption,
} from "redmond";

import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";
import { filterCopy, formats } from "../../../../../../constants";
import { setFlightNumberFilters } from "../../../../../../reducers/flightShop";
import {
  getFlightNumberFilters,
  getShoppedDepartureDate,
} from "../../../../../../selectors";

import "./styles.scss";

export interface IFlightNumberFilterProps {
  airlines: ISelectOption<AirlineCode>[];
  flightNumberOpts: IFlightNumberMap;
  showDropdownContentOnly?: boolean;
}

const defaultProps: Partial<IFlightNumberFilterProps> = {
  showDropdownContentOnly: false,
};

const FlightNumberFilter = (props: IFlightNumberFilterProps): JSX.Element => {
  const { airlines, flightNumberOpts, showDropdownContentOnly } = props;
  const dispatch = useDispatch();
  const departureDate = useSelector(getShoppedDepartureDate);
  const flightNumberFilters = useSelector(getFlightNumberFilters);
  const [selectedAirline, setSelectedAirline] = useState("");
  const strDepartureDate = departureDate?.format(formats.DISPLAY_DATE) ?? "";

  const getFilterIdx = (option: string) =>
    flightNumberFilters.findIndex(
      (filter) =>
        filter.flightNumber === option && filter.airlineCode === selectedAirline
    );

  const handleAirlineChange = (airlineCode: string) => {
    setSelectedAirline(airlineCode);
    dispatch(setFlightNumberFilters([]));
  };

  const handleFlightNumberSelection = (value: string) => {
    const filterValue: IFlightNumberFilter = {
      airlineCode: selectedAirline,
      flightNumber: value,
    };
    const filterIdx = getFilterIdx(value);
    let newFNFilters;

    if (filterIdx !== -1) {
      newFNFilters = flightNumberFilters.filter(
        (_, index) => index !== filterIdx
      );
    } else {
      newFNFilters = [...flightNumberFilters, filterValue];
    }

    dispatch(setFlightNumberFilters(newFNFilters));
    trackEvent({
      eventName: APPLIED_FILTER,
      properties: { filter_type: "flight number" },
    });
  };

  const renderFlightNumberOptions = (options: IOption[] = []) => {
    return options.map((option: IOption) => (
      <FormControlLabel
        className="flight-number-option"
        control={
          <Checkbox
            checked={getFilterIdx(option.value) !== -1}
            onChange={() => handleFlightNumberSelection(option.value)}
            value={option}
          />
        }
        key={option.value}
        label={option.label}
        labelPlacement="start"
      />
    ));
  };

  const toOptions = (values: string[], labelPrefix?: string) =>
    values.reduce((opts, value) => {
      const label = labelPrefix ? `${labelPrefix} ${value}` : value;
      const option: IOption = { label, value };

      opts.push(option);

      return opts;
    }, [] as IOption[]);

  const DropdownContent = useMemo(
    () => (
      <Box className="dropdown-root">
        <Box className="header-container">
          <Typography className="header-text" variant="body1">
            {filterCopy.HEADER(strDepartureDate)}
          </Typography>
        </Box>
        <Box className="airline-section">
          <LabelDropDown
            ariaLabel="Airline Selector"
            classes={["airline-selection-menu"]}
            label=""
            onChange={(newValue) => handleAirlineChange(newValue)}
            options={airlines}
            value={selectedAirline}
          />
        </Box>
        {selectedAirline && (
          <Box className="flight-number-selection">
            <FormControl component="fieldset">
              <FormGroup>
                {renderFlightNumberOptions(
                  toOptions(flightNumberOpts[selectedAirline], selectedAirline)
                )}
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [airlines, flightNumberFilters, flightNumberOpts, selectedAirline]
  );

  return (
    <Box className="flight-number-filter-root">
      {showDropdownContentOnly ? (
        DropdownContent
      ) : (
        <GenericDropdown
          ariaLabel="Flight Number Filter"
          buttonClassName="flight-number-filter b2b-shop-filter"
          dropdownContent={DropdownContent}
          dropdownLabel={filterCopy.FLIGHT_NUMBER}
          popoverClassName="flight-number-popover b2b"
        />
      )}
    </Box>
  );
};

FlightNumberFilter.defaultProps = defaultProps;

export default FlightNumberFilter;

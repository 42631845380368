import {
  BookedFlightItineraryWithDepartureTime, ExchangeAction,
  ExchangeActionEnum,
  getDepartureSlice,
  getReturnSlice,
  IShoppedTrip,
  Maybe, Slice
} from "redmond";

export const getItinerarySlices = (
  shoppedTrip: IShoppedTrip,
  outboundSelection: ExchangeAction,
  returnSelection: ExchangeAction | undefined,
  booking: Maybe<BookedFlightItineraryWithDepartureTime>,
  slices: { [key: string]: Slice; } ,
) => {
  const { outgoingSliceId = "", returnSliceId = "" } = shoppedTrip;
  let oSlice = null;
  let rSlice = null;

  if (outboundSelection.ExchangeAction === ExchangeActionEnum.keep && booking) {
    oSlice = getDepartureSlice(booking.bookedItinerary) ?? null;
  } else if (outgoingSliceId) {
    oSlice = slices[outgoingSliceId];
  }

  if (returnSelection?.ExchangeAction === ExchangeActionEnum.keep && booking) {
    rSlice = getReturnSlice(booking.bookedItinerary) ?? null;
  } else if (returnSliceId) {
    rSlice = slices[returnSliceId];
  }

  return { outgoingSlice: oSlice, returnSlice: rSlice };
};


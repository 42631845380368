import axios from "axios";
import {
  IDepartureCalendarReport,
  IDepartureCalendarRequestBody,
} from "redmond";

import { config } from "../../config";
import { fetchCalendarPath } from "../paths";

const fetchCalendar = (
  req: IDepartureCalendarRequestBody
): Promise<IDepartureCalendarReport> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(fetchCalendarPath, req, {
        baseURL: config.baseURL,
      });
      const {
        data: { departureDateReport },
      } = res;

      resolve(departureDateReport);
    } catch (e) {
      reject(e);
    }
  });

export default fetchCalendar;

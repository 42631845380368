import React, { useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  AccordionCollection,
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
} from "halifax";
import { IReshopFilterOpts } from "redmond";

import { AirlineFilter } from "../AirlineFilter";
import { AirportFilter } from "../AirportFilter";
import { DepartureArrivalFilter } from "../DepartureArrivalFilter";
import { FlightNumberFilter } from "../FlightNumberFilter";
import { MaxPriceFilter } from "../MaxPriceFilter";
import { StopsCountFilter } from "../StopsCountFilter";
import {
  buttonText,
  filterCopy,
  flightShopCopy,
} from "../../../../../../constants";

import "./styles.scss";

export interface IMobileFilterRowProps {
  filters: IReshopFilterOpts;
}

const defaultProps: Partial<IMobileFilterRowProps> = {};

const MobileFilterRow = (props: IMobileFilterRowProps): JSX.Element => {
  const { filters } = props;
  const [filtersOpen, setFiltersOpen] = useState(false);

  const closeFilters = () => setFiltersOpen(false);

  const AccordionContent = useMemo(() => {
    const { airlineOpts, airportOpts, flightNumberOpts, priceMax, priceMin } =
      filters;

    return [
      {
        body: (
          <Box className="mobile-reshop-filter">
            <StopsCountFilter showDropdownContentOnly />
          </Box>
        ),
        title: <Box className="header-container">{filterCopy.STOPS}</Box>,
      },
      {
        body: (
          <Box className="mobile-reshop-filter">
            <MaxPriceFilter
              showDropdownContentOnly
              maxPrice={priceMax.value}
              minPrice={priceMin.value}
            />
          </Box>
        ),
        title: <Box className="header-container">{filterCopy.PRICE}</Box>,
      },
      {
        body: (
          <Box className="mobile-reshop-filter">
            <AirlineFilter showDropdownContentOnly airlines={airlineOpts} />
          </Box>
        ),
        title: <Box className="header-container">{filterCopy.AIRLINE}</Box>,
      },
      {
        body: (
          <Box className="mobile-reshop-filter">
            <DepartureArrivalFilter
              showDeparturesOnly
              showDropdownContentOnly
            />
          </Box>
        ),
        title: (
          <Box className="header-container">{flightShopCopy.DEPARTURE}</Box>
        ),
      },
      {
        body: (
          <Box className="mobile-reshop-filter">
            <DepartureArrivalFilter showArrivalsOnly showDropdownContentOnly />
          </Box>
        ),
        title: <Box className="header-container">{flightShopCopy.ARRIVAL}</Box>,
      },
      {
        body: (
          <Box className="mobile-reshop-filter">
            <AirportFilter showDropdownContentOnly airports={airportOpts} />
          </Box>
        ),
        title: <Box className="header-container">{filterCopy.AIRPORT}</Box>,
      },
      {
        body: (
          <Box className="mobile-reshop-filter">
            <FlightNumberFilter
              showDropdownContentOnly
              airlines={airlineOpts}
              flightNumberOpts={flightNumberOpts}
            />
          </Box>
        ),
        title: (
          <Box className="header-container">{filterCopy.FLIGHT_NUMBER}</Box>
        ),
      },
    ];
  }, [filters]);

  return (
    <Box className="mobile-flight-reshop-filters-root">
      <ActionLink
        className="filter-open-btn"
        content={<Typography variant="body1">{buttonText.FILTERS}</Typography>}
        onClick={() => setFiltersOpen(true)}
      />
      <MobilePopoverCard
        fullScreen
        bottomButton={
          <ActionButton
            className="apply-filters-btn"
            message={buttonText.APPLY_FILTERS}
            onClick={closeFilters}
          />
        }
        className="mobile-filters-popover-card"
        contentClassName="filters-wrapper"
        onClose={closeFilters}
        open={filtersOpen}
        topRightButton={
          <ActionLink
            className="filter-close-btn"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={closeFilters}
          />
        }
      >
        <Box className="filters-content-container">
          <AccordionCollection
            accordionContents={AccordionContent}
            className="filters-accordion-container"
          />
        </Box>
      </MobilePopoverCard>
    </Box>
  );
};

MobileFilterRow.defaultProps = defaultProps;

export default MobileFilterRow;

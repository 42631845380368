import dayjs from "dayjs";
import { removeTimezone } from "halifax";
import {
  AirlineCode,
  FlightFare,
  FlightRatingsEnum,
  Flights,
  IFlightListData,
  IFlightNumberFilter,
  ITimeRange,
  Slice,
  SliceStopCountFilter,
} from "redmond";

export const filterByAirline = (flight: Slice, airlines: AirlineCode[]) =>
  airlines.includes(flight.segments[0].marketingAirline);

export const filterByAirport = (flight: Slice, airports: string[]) =>
  airports.includes(flight.origin);

export const filterByFareClass = (
  flights: Flights,
  flight: IFlightListData,
  fareClassFilter: string[]
) => {
  const getAllFlightRatings = () => {
    const getFareSliceId = (fare: any) => fare?.fareSlice || fare.return;
    return flight.fares.reduce((flightRating: any, fare: any) => {
      const rating = flights.fareSlices[getFareSliceId(fare)].fareShelf.value;
      if (rating || rating === 0) flightRating[rating] = true;
      return flightRating;
    }, {});
  };

  // If the flight has *any* of the users selected fare classes, return true.
  return fareClassFilter.some((fare) => {
    const allRatings = getAllFlightRatings();
    const fareRatingNum = FlightRatingsEnum[fare.toLocaleLowerCase()];

    return allRatings[fareRatingNum];
  });
};

export const filterByFlightNumber = (
  flight: Slice,
  flightNumbers: IFlightNumberFilter[]
) =>
  flightNumbers.some((filter) => {
    const [firstSegment] = flight.segments;
    const { flightNumber, marketingAirline } = firstSegment;

    return (
      marketingAirline === filter.airlineCode &&
      flightNumber === filter.flightNumber
    );
  });

export const filterByMaxPrice = (fares: FlightFare[], maxPrice: number) =>
  fares.some((fare) => (fare?.amount?.fiat?.value || 0) <= maxPrice);

export const filterByStopsCount = (
  flight: Slice,
  stopsCount: SliceStopCountFilter
): boolean => {
  switch (stopsCount) {
    case SliceStopCountFilter.ONE_OR_LESS:
      return flight.stops <= 1;
    case SliceStopCountFilter.TWO_OR_LESS:
      return flight.stops <= 2;
    case SliceStopCountFilter.NONE:
      return flight.stops === 0;
    default:
      return true;
  }
};

export const filterByTimeRange = (
  flight: Slice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightArrival = dayjs(removeTimezone(flight.arrival));
  const flightDeparture = dayjs(removeTimezone(flight.departure));

  const arrivalTimeInMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();
  const departureTimeInMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();

  const validArrival =
    arrivalTime.min <= arrivalTimeInMinutes &&
    arrivalTimeInMinutes <= arrivalTime.max;
  const validDeparture =
    departureTime.min <= departureTimeInMinutes &&
    departureTimeInMinutes <= departureTime.max;

  return validArrival && validDeparture;
};

const allFilters = {
  filterByAirline,
  filterByAirport,
  filterByFareClass,
  filterByFlightNumber,
  filterByMaxPrice,
  filterByStopsCount,
  filterByTimeRange,
};

export default allFilters;

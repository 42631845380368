import axios from "axios";

import { config } from "../../config";
import { fetchCityNamePath } from "../paths";

export interface ICityNameResponse {
  [airportCode: string]: string;
}
const fetchCityName = (
  cityOrAirportCodes: string[]
): Promise<ICityNameResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const req = { cityOrAirportCodes };
      const res = await axios.post(fetchCityNamePath, req, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchCityName;

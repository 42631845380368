import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  AirlineOpenEnum,
  BookedFlightItineraryWithDepartureTime,
  ExchangeActionEnum,
  ExchangeForTypeEnum,
  ExchangeScenario,
  FlightRatingsEnum,
  getDepartureSlice,
  getReturnSlice,
  IFlightBookingInfoRes,
  IFlightExchangePolicyRes,
  INonChfarExchangePolicy,
  INonChfarVoidableExchangeable,
  Maybe,
  MultiTravelItinerary,
  NonChfarScenario,
  PortalItineraryStatusEnum,
  SelfServeEvents,
  SingleTravelItinerary,
  TravelItinerary,
  TravelItineraryEnum,
  TripCategory,
} from "redmond";

import { trackEvent } from "../../api/v1/analytics/trackEvent";
import fetchBookedItinerary from "../../api/v1/exchange/fetchBookedItinerary";
import fetchFlightExchangePolicy from "../../api/v1/exchange/fetchFlightExchangePolicy";
import fetchFlightExchangePolicyV2 from "../../api/v1/exchange/fetchFlightExchangePolicyV2";
import {
  buttonText,
  MobileFlightSearchStep,
  ModalState,
  searchCopy,
} from "../../constants";
import { ActiveExperiments, useExperiment } from "../../context/experiments";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import { setExchangePolicy } from "../../reducers/flightPolicy";
import { fetchTripSummaries } from "../../reducers/flightShop";
import {
  resetState,
  setDepartureDate,
  setDestination,
  setExchangeType,
  setFlightBookingInfo,
  setOrigin,
  setReturnDate,
} from "../../reducers/search";
import {
  getAgentEmail,
  getAirChangesPending,
  getDateChangesPending,
  getDateSelectionDirty,
  getExchangeCopy,
  getExchangeScenario,
  getExchangeTypeEvent,
  getFlightBooking,
  getFlightExchangePolicy,
  getOriginalExchangeFee,
  getOriginalFlightInfo,
  getShoppedParams,
  getTravelItinerary,
  getTripType,
} from "../../selectors";
import { getPolicyAttr, goBackToMyTrips } from "../../utils/helpers";
import { PATH_FLIGHT_SHOP } from "../../utils/paths";
import { FlightDateRangePicker } from "../FlightDateRangePicker";
import { FlightExchangeBody } from "../FlightExchangeBody";
import { FlightExchangeForm } from "../FlightExchangeForm";
import { RedeemFTCForm } from "../RedeemFTCForm";
import { MobileFlightSearchControl } from "./components/MobileFlightSearchControl";
import {
  renderChangeAirports,
  renderChangeFailed,
  renderChangeRequested,
  renderContactSupportInfo,
  renderIneligibleForExchange,
  renderItineraryNotFound,
  renderLoadFailed,
  renderLoadingOrProcessing,
  renderTicketedVoidable,
  renderTooManyAttemptsSupport,
} from "./modals";
import SliceSelectionModal from "./SliceSelectionModal";

import "./styles.scss";
import {
  CONTACT_F9_SUBTITLE,
  CONTACT_SUPPORT_HEADER_2,
  CONTACT_SUPPORT_NUMBER,
  CONTACT_SUPPORT_TEXT_2,
} from "b2b-base/src/components/constants";
import { PostTicketingStatus } from "redmond/build/trips-module/itinerary";

export interface IFlightSearchProps extends RouteComponentProps {
  bookingId: Maybe<string>;
}

const mobileSearchFlowModals = [
  ModalState.ChangeAirports,
  ModalState.ChangeDateTime,
  ModalState.FTCSearch,
];

const defaultProps: Partial<IFlightSearchProps> = {
  bookingId: "",
};

const FlightSearch = (props: IFlightSearchProps): JSX.Element => {
  const { bookingId, history } = props;
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const mockDataExp = useExperiment(ActiveExperiments.MockShopData);
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);
  const useFareRulesServiceExp = useExperiment(
    ActiveExperiments.UseFareRulesService
  );
  const isB2bExchangeShopExpEnabled = useExperiment(
    ActiveExperiments.FlightExchangeUseB2bShop
  );

  const hideXButtonRef = useRef(true);
  const allowExchange = useRef(true);
  const modalDisclaimerRef = useRef("");
  const modalSubtitleRef = useRef<ReactNode>([searchCopy.LOADING]);
  const modalTitleRef = useRef("");
  const mobileSearchStepsRef = useRef<MobileFlightSearchStep[]>([]);

  const agentEmail = useSelector(getAgentEmail);
  const airChangesPending = useSelector(getAirChangesPending);
  const booking = useSelector(getFlightBooking);
  const dateChangesPending = useSelector(getDateChangesPending);
  const dateSelectionDirty = useSelector(getDateSelectionDirty);
  const exchangeCopy = useSelector(getExchangeCopy);
  const policy = useSelector(getFlightExchangePolicy);
  const originalFlightInfo = useSelector(getOriginalFlightInfo);
  const ogChangeFee = useSelector(getOriginalExchangeFee);
  const scenario = useSelector(getExchangeScenario);
  const shoppedParams = useSelector(getShoppedParams);
  const tripType = useSelector(getTripType);
  const exchangeTypeEvent = useSelector(getExchangeTypeEvent);
  const travelItinerary = useSelector(getTravelItinerary)?.TravelItinerary;
  const isMultiTravelItinerary =
    travelItinerary === TravelItineraryEnum.MultiTravelItinerary;
  const [modalState, setModalState] = useState(ModalState.Closed);

  const changesPending = airChangesPending || dateChangesPending;
  const { destinationCode: destCode, originCode } = shoppedParams;

  /**
   * @description Closes the modal
   */
  const closeModal = useCallback(() => {
    setModalState(ModalState.Closed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executeSearch = useCallback(() => {
    dispatch(
      fetchTripSummaries({
        isMobile: matchesMobile,
        mockData: mockDataExp,
        isB2bExchangeShopExpEnabled,
      })
    );
    history.push({
      pathname: PATH_FLIGHT_SHOP,
      search: history.location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description
   */
  const handleGoBack = () => {
    dispatch(setFlightBookingInfo(null));
    goBackToMyTrips(history, `?tripId=${bookingId}`);
  };

  /**
   * @description
   * @param {string} itineraryId
   */
  const getBookingInfo = useCallback((itineraryId: string) => {
    setModalState(ModalState.LoadingOrProcessing);
    modalTitleRef.current = "";
    modalSubtitleRef.current = [searchCopy.LOADING];

    let flightExchangePolicy;
    // Experiment to use b2b-interfaces endpoints as V2. MultiTicket not yet supported on v2
    if (useFareRulesServiceExp && !isMultiTravelItinerary) {
      flightExchangePolicy = fetchFlightExchangePolicyV2(itineraryId);
    } else {
      flightExchangePolicy = fetchFlightExchangePolicy(itineraryId);
    }

    const reqs: any = [fetchBookedItinerary(itineraryId), flightExchangePolicy];

    Promise.all(reqs)
      .then((responses: any) => {
        const flightBooking = responses[0] as IFlightBookingInfoRes;
        const flightPolicy = responses[1] as IFlightExchangePolicyRes;
        const { ExchangeScenario: exchScenario } = flightPolicy;

        batch(() => {
          dispatch(setFlightBookingInfo(flightBooking));
          dispatch(setExchangePolicy(flightPolicy));
        });

        const marketingAirlines: string[] =
          flightBooking.itinerary.bookedItinerary.travelItinerary.slices.flatMap(
            (slice) =>
              slice.segments.map((segment) =>
                segment.marketingAirline.code.toUpperCase()
              )
          );

        // Override eligibility response for known exceptions
        // Also handled in trips-module `ExchangeFlightModalContent`
        // Frontier no longer supports exchange eligibility through our Customer Support, so they must contact Frontier
        if (marketingAirlines.includes(AirlineOpenEnum.F9)) {
          modalSubtitleRef.current = [CONTACT_F9_SUBTITLE];
          modalTitleRef.current = CONTACT_SUPPORT_HEADER_2;
          setModalState(ModalState.ContactSupport);
        } else if (
          shouldOverrideEligibilityToContactSupport(
            flightBooking.itinerary.bookedItinerary.travelItinerary,
            flightBooking.itinerary
          )
        ) {
          modalSubtitleRef.current = [
            CONTACT_SUPPORT_TEXT_2,
            "<br/><b>" + CONTACT_SUPPORT_NUMBER + "</b>",
          ];
          modalTitleRef.current = CONTACT_SUPPORT_HEADER_2;
          setModalState(ModalState.ContactSupport);
        } else {
          switch (exchScenario) {
            case ExchangeScenario.canceled:
              setModalState(ModalState.IneligibleForExchange);
              break;
            case ExchangeScenario.ftc:
              closeModal();
              break;

            case ExchangeScenario.exchangeable:
              closeModal();
              break;

            case ExchangeScenario.departed:
            case ExchangeScenario.nonExchangeable: {
              const copy = getPolicyAttr("exchangeCopy", flightPolicy);

              if (copy) {
                modalSubtitleRef.current = copy.body;
                modalTitleRef.current = copy.title;

                setModalState(ModalState.ContactSupport);
              }
              break;
            }

            case ExchangeScenario.bookingPending:
            case ExchangeScenario.cancellationPending:
            case ExchangeScenario.containsRemarks:
            case ExchangeScenario.contactCustomerService: {
              const copy = getPolicyAttr("customerServiceCopy", flightPolicy);

              if (copy) {
                modalSubtitleRef.current = copy.body;
                modalTitleRef.current = copy.title;

                setModalState(ModalState.ContactSupport);
              }
              break;
            }

            case ExchangeScenario.contactAirline: {
              const copy = getPolicyAttr("exchangeCopy", flightPolicy);

              if (copy) {
                modalDisclaimerRef.current = copy.disclaimer!;
                modalSubtitleRef.current = copy.body;
                modalTitleRef.current = copy.title;

                setModalState(ModalState.ContactSupport);
              }

              break;
            }

            case ExchangeScenario.nonChfar: {
              const NonChfar = getPolicyAttr("NonChfar", flightPolicy);

              switch (NonChfar) {
                case NonChfarScenario.contactAirline: {
                  const copy = getPolicyAttr("exchangeCopy", flightPolicy);

                  if (copy) {
                    modalDisclaimerRef.current = copy.disclaimer!;
                    modalSubtitleRef.current = copy.body;
                    modalTitleRef.current = copy.title;

                    setModalState(ModalState.ContactSupport);
                  }

                  break;
                }
                case NonChfarScenario.multiProvider: {
                  const { itinerary } = flightBooking;
                  const departureSlice = getDepartureSlice(
                    itinerary.bookedItinerary
                  );
                  const returnSlice = getReturnSlice(itinerary.bookedItinerary);
                  const outgoingDepSegment = departureSlice.segments[0];
                  const returnDepSegment = returnSlice?.segments[0] ?? null;
                  batch(() => {
                    dispatch(
                      setDepartureDate(
                        outgoingDepSegment.updatedDeparture.toString()
                      )
                    );
                    if (returnDepSegment?.updatedDeparture) {
                      dispatch(
                        setReturnDate(
                          returnDepSegment?.updatedDeparture.toString()
                        )
                      );
                    }
                  });
                  setModalState(ModalState.SliceSelection);
                  break;
                }
                case NonChfarScenario.exchangeable:
                  closeModal();
                  break;
                case NonChfarScenario.nonExchangeable: {
                  const copy = getPolicyAttr("exchangeCopy", flightPolicy);

                  if (copy) {
                    modalSubtitleRef.current = copy.body;
                    modalTitleRef.current = copy.title;

                    setModalState(ModalState.ContactSupport);
                  }
                  break;
                }
                case NonChfarScenario.contactCustomerService: {
                  const copy = getPolicyAttr(
                    "customerServiceCopy",
                    flightPolicy
                  );

                  if (copy) {
                    modalSubtitleRef.current = copy.body;
                    modalTitleRef.current = copy.title;

                    setModalState(ModalState.ContactSupport);
                  }
                  break;
                }
                case NonChfarScenario.ticketedVoidable:
                  const { exchangeableScenario, isExchangeable } =
                    flightPolicy as INonChfarVoidableExchangeable;

                  if (isExchangeable && exchangeableScenario) {
                    closeModal();
                  } else {
                    setModalState(ModalState.TicketedVoidable);
                  }

                  break;
              }
              break;
            }
            default:
              setModalState(ModalState.LoadFailed);
          }
        }
      })
      .catch(() => {
        setModalState(ModalState.LoadFailed);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldOverrideEligibilityToContactSupport = (
    travelItinerary: TravelItinerary,
    flightBooking: BookedFlightItineraryWithDepartureTime
  ): boolean => {
    let shouldOverride = false;
    switch (travelItinerary.TravelItinerary) {
      case TravelItineraryEnum.SingleTravelItinerary:
        shouldOverride =
          shouldOverrideSingleItineraryEligibilityToContactSupport(
            travelItinerary as SingleTravelItinerary,
            flightBooking
          );
        break;
      case TravelItineraryEnum.MultiTravelItinerary:
        travelItinerary = travelItinerary as MultiTravelItinerary;
        shouldOverride = travelItinerary.travelItineraries.some((itinerary) => {
          return shouldOverrideSingleItineraryEligibilityToContactSupport(
            itinerary,
            flightBooking
          );
        });
        break;
      default:
        break;
    }
    return shouldOverride;
  };

  const shouldOverrideSingleItineraryEligibilityToContactSupport = (
    travelItinerary: SingleTravelItinerary,
    flightBooking: BookedFlightItineraryWithDepartureTime
  ): boolean => {
    const isAmadeus = travelItinerary.locators?.agent.provider === "amadeus";
    const shouldBlockFareClass = travelItinerary.slices.some((slice) => {
      const fareShelf = slice.fareShelf?.rating;
      return (
        fareShelf === undefined || // Checking `!fareShelf` would also match shelf of `basic.valueOf`, which is `0`
        fareShelf === FlightRatingsEnum.premium.valueOf() ||
        fareShelf === FlightRatingsEnum.luxury.valueOf()
      );
    });

    const itineraryStatusNotEligible =
      flightBooking.status == PortalItineraryStatusEnum.Modified ||
      travelItinerary.postTicketingStatus == PostTicketingStatus.Modified;

    return itineraryStatusNotEligible || (isAmadeus && shouldBlockFareClass);
  };

  /**
   * @description
   * @returns {JSX.Element}
   */
  const getModalContent = useCallback(
    (currentModalState: ModalState) => {
      hideXButtonRef.current = true;
      allowExchange.current = true;

      switch (currentModalState) {
        case ModalState.ChangeAirports:
          hideXButtonRef.current = false;

          if (matchesMobile) {
            mobileSearchStepsRef.current = [
              MobileFlightSearchStep.LocationSearch,
            ];

            // mobile modal handled by MobileFlightSearchControl
            return null;
          }

          return renderChangeAirports(matchesMobile, closeModal);
        case ModalState.ChangeDateTime:
          mobileSearchStepsRef.current = [
            MobileFlightSearchStep.CalendarPicker,
          ];

          // mobile modal handled by MobileFlightSearchControl
          // desktop modal handled by FlightDateRangePicker
          return null;
        case ModalState.ChangeFailed:
          return renderChangeFailed();
        case ModalState.SliceSelection:
          hideXButtonRef.current = isMultiTravelItinerary;
          return (
            <SliceSelectionModal
              handleGoBack={handleGoBack}
              executeSearch={executeSearch}
            />
          );
        case ModalState.ChangeRequested:
          return renderChangeRequested(handleGoBack);
        case ModalState.ContactSupport:
          allowExchange.current = false;
          hideXButtonRef.current = false;
          return renderContactSupportInfo(
            modalSubtitleRef.current,
            modalTitleRef.current
          );
        case ModalState.FTCSearch:
          // FTCSearch can only be set on mobile view
          mobileSearchStepsRef.current = [
            MobileFlightSearchStep.LocationSearch,
            MobileFlightSearchStep.CalendarPicker,
          ];

          // handled by MobileFlightSearchControl
          return null;
        case ModalState.IneligibleForExchange:
          allowExchange.current = false;
          return renderIneligibleForExchange(handleGoBack);
        case ModalState.ItineraryNotFound:
          return renderItineraryNotFound(handleGoBack);
        case ModalState.LoadFailed:
          return renderLoadFailed(() => getBookingInfo(bookingId || ""));
        case ModalState.LoadingOrProcessing:
          return renderLoadingOrProcessing(
            modalSubtitleRef.current,
            modalTitleRef.current
          );
        case ModalState.TicketedVoidable:
          hideXButtonRef.current = false;
          return renderTicketedVoidable(handleGoBack, policy!);
        case ModalState.TooManyAttemptsSupport:
          return renderTooManyAttemptsSupport();
        default:
          return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shoppedParams,
      matchesMobile,
      modalSubtitleRef,
      modalTitleRef,
      bookingId,
      policy,
      hideXButtonRef,
    ]
  );

  /**
   * @description handles "Submit Request" click
   */
  const handleSubmit = useCallback(() => {
    const {
      returnDate: origReturnDate,
      departureDate: origDepartureDate,
      originCode: origOriginCode,
      destinationCode: origDestinationCode,
      destination: origDestination,
      origin: origOrigin,
    } = originalFlightInfo;
    const {
      departureDate: depDate,
      destinationCode: desCode,
      originCode: oriCode,
      returnDate: retDate,
    } = shoppedParams;

    const isOneWay: boolean = tripType === TripCategory.ONE_WAY;
    const isDifferentDepartureDate: boolean = depDate
      ? !depDate.isSame(origDepartureDate, "day")
      : false;
    const isDifferentReturnDate: boolean = retDate
      ? !retDate.isSame(origReturnDate, "day")
      : false;
    const bothDatesChanged: boolean =
      isDifferentDepartureDate && isDifferentReturnDate;
    const isDifferentOrigin: boolean = oriCode
      ? origOriginCode !== oriCode
      : false;
    const isDifferentDest: boolean = desCode
      ? origDestinationCode !== desCode
      : false;

    batch(() => {
      if (!dateSelectionDirty) {
        dispatch(
          setDepartureDate(
            origDepartureDate ? origDepartureDate.toString() : null
          )
        );
        dispatch(
          setReturnDate(origReturnDate ? origReturnDate.toString() : null)
        );
      }

      if (origDestinationCode && !desCode) {
        dispatch(setDestination(origDestination));
      }

      if (origOriginCode && !oriCode) {
        dispatch(setOrigin(origOrigin));
      }
    });

    if (scenario === ExchangeScenario.ftc) {
      const originalOneWay = Boolean(origDepartureDate && !origReturnDate);
      const newExchangeType = {
        AirExchangeForType: isOneWay
          ? ExchangeForTypeEnum.oneWay
          : ExchangeForTypeEnum.roundTrip,
        outboundSelection: { ExchangeAction: ExchangeActionEnum.change },
        returnSelection: isOneWay
          ? undefined
          : { ExchangeAction: ExchangeActionEnum.change },
      };

      if (originalOneWay !== isOneWay) {
        newExchangeType.returnSelection = {
          ExchangeAction: isOneWay
            ? ExchangeActionEnum.remove
            : ExchangeActionEnum.add,
        };
      }

      dispatch(setExchangeType(newExchangeType));
      executeSearch();
    } else if (
      isOneWay ||
      bothDatesChanged ||
      isDifferentDest ||
      isDifferentOrigin
    ) {
      dispatch(
        setExchangeType({
          AirExchangeForType: isOneWay
            ? ExchangeForTypeEnum.oneWay
            : ExchangeForTypeEnum.roundTrip,
          outboundSelection: { ExchangeAction: ExchangeActionEnum.change },
          returnSelection:
            retDate && !isOneWay
              ? { ExchangeAction: ExchangeActionEnum.change }
              : undefined,
        })
      );
      executeSearch();
    } else {
      setModalState(ModalState.SliceSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateSelectionDirty,
    originalFlightInfo,
    scenario,
    shoppedParams,
    tripType,
  ]);

  const ModalContent = getModalContent(modalState);
  const FlightExchangeLandingView = useMemo(
    () => {
      const exchScenario = getPolicyAttr("ExchangeScenario", policy);
      const isFTC = exchScenario === ExchangeScenario.ftc;
      // if the itinerary is hfv2, we want to force the customer to select a slice, without changing dates and airports
      if (isMultiTravelItinerary) {
        return <Box className="multi-travel-itinerary" />;
      }
      return (
        <Box
          style={
            matchesMobile && modalState === ModalState.ChangeDateTime
              ? {
                  display: "none",
                }
              : {
                  display: "flex",
                  flexFlow: "column",
                  flex: 1,
                }
          }
        >
          <Box className="flight-exchange-request-container">
            {!matchesMobile &&
              modalState !== ModalState.LoadingOrProcessing && (
                <Box className="go-back-container">
                  <ActionLink
                    content={
                      <>
                        <Icon
                          aria-hidden
                          className="go-back-chevron"
                          name={IconName.NavigationForward}
                        />
                        <Typography className="action-link-text">
                          {buttonText.BACK_BTN}
                        </Typography>
                      </>
                    }
                    onClick={handleGoBack}
                  />
                </Box>
              )}
            {modalState !== ModalState.LoadingOrProcessing && (
              <FlightExchangeBody
                className={isFTC ? "ftc-redeem" : "exchange"}
                shopForm={
                  isFTC ? (
                    <RedeemFTCForm
                      isMobile={matchesMobile}
                      onSubmit={handleSubmit}
                    />
                  ) : (
                    <FlightExchangeForm
                      onSubmit={handleSubmit}
                      isMobile={matchesMobile}
                    />
                  )
                }
                subtitle={exchangeCopy?.body}
                title={exchangeCopy?.title}
              />
            )}
            {isFTC && matchesMobile && (
              <ActionButton
                className="mobile-ftc-search-flights"
                defaultStyle="h4r-primary"
                message={buttonText.SEARCH_FOR_FLIGHTS}
                onClick={() => setModalState(ModalState.FTCSearch)}
              />
            )}
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changesPending, exchangeCopy, handleSubmit, matchesMobile, modalState]
  );

  useEffect(() => {
    if (bookingId && (!booking || bookingId !== booking?.bookedItinerary?.id)) {
      dispatch(resetState());
      getBookingInfo(bookingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    if (isMultiTravelItinerary && modalState === ModalState.Closed) {
      setModalState(ModalState.SliceSelection);
    }
  }, []);

  useEffect(() => {
    if (policy) {
      if (scenario === ExchangeScenario.ftc) {
        trackEvent({
          eventName: SelfServeEvents.ViewedFTCExchangeLanding,
          properties: {
            delegated_to: agentEmail || "",
            exchange_fee: ogChangeFee.amount,
            exchange_type: exchangeTypeEvent,
            url: window.location.pathname,
          },
        });
      } else if (scenario === ExchangeScenario.nonChfar) {
        const { NonChfar } = policy as INonChfarExchangePolicy;
        const { isExchangeable } = policy as INonChfarVoidableExchangeable;

        if (
          NonChfar === NonChfarScenario.exchangeable ||
          (NonChfar === NonChfarScenario.ticketedVoidable && isExchangeable)
        ) {
          trackEvent({
            eventName: SelfServeEvents.ViewedExchangeLanding,
            properties: {
              delegated_to: agentEmail || "",
              exchange_fee: ogChangeFee.amount,
              exchange_type: exchangeTypeEvent,
              url: window.location.pathname,
            },
          });
        }
      }
    }
  }, [agentEmail, policy]);

  return (
    <Box
      className={clsx("flight-exchange-container", {
        mobile: matchesMobile,
        redesign: redesignExp,
      })}
    >
      {matchesMobile ? (
        <>
          {FlightExchangeLandingView}
          <MobileFlightSearchControl
            onFlowComplete={() => {
              scenario === ExchangeScenario.ftc ? handleSubmit() : closeModal();
            }}
            open={mobileSearchFlowModals.includes(modalState)}
            setOpen={closeModal}
            steps={mobileSearchStepsRef.current}
          />
          {ModalContent && (
            <MobilePopoverCard
              centered
              className="flight-exchange-mobile-modal"
              contentClassName="modal-content"
              onClose={() => {
                if (isMultiTravelItinerary) {
                  return;
                }
                return allowExchange.current ? closeModal() : handleGoBack();
              }}
              open={modalState !== ModalState.Closed}
              topRightButton={
                hideXButtonRef.current ? undefined : (
                  <ActionLink
                    className="close-mobile-modal-btn"
                    content={<CloseButtonIcon />}
                    label="Close"
                    onClick={closeModal}
                  />
                )
              }
            >
              {ModalContent}
            </MobilePopoverCard>
          )}
        </>
      ) : (
        <>
          {FlightExchangeLandingView}
          {ModalContent && (
            <DesktopPopupModal
              className="flight-exchange-desktop-modal"
              hideXButton={hideXButtonRef.current}
              invisibleBackdrop={false}
              onClose={() => {
                if (isMultiTravelItinerary) {
                  return;
                }
                return allowExchange.current ? closeModal() : handleGoBack();
              }}
              open={modalState !== ModalState.Closed}
            >
              {ModalContent}
            </DesktopPopupModal>
          )}
          {/* In desktop view, date range picker is just a modal */}
          <FlightDateRangePicker
            closePicker={() => setModalState(ModalState.Closed)}
            destinationCode={destCode}
            isMobile={matchesMobile}
            open={modalState === ModalState.ChangeDateTime}
            originCode={originCode}
          />
        </>
      )}
    </Box>
  );
};

FlightSearch.defaultProps = defaultProps;

export default withRouter(FlightSearch);

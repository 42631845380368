import { useMediaQuery } from "@material-ui/core";

import {
  IS_ON_DESKTOP_MEDIA_QUERY,
  IS_ON_MOBILE_MEDIA_QUERY,
  IS_ON_SMALL_DESKTOP_MEDIA_QUERY,
} from "./constants";

export const useDeviceTypes = () => {
  const matchesDesktop = useMediaQuery(IS_ON_DESKTOP_MEDIA_QUERY);
  const matchesSmallDesktop = useMediaQuery(IS_ON_SMALL_DESKTOP_MEDIA_QUERY);
  const matchesMobile = useMediaQuery(IS_ON_MOBILE_MEDIA_QUERY);

  return { matchesDesktop, matchesSmallDesktop, matchesMobile };
};

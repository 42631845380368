import React, { useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  GenericModalContent,
  FlightInfoCheckbox,
  formatDateTime,
  removeTimezone,
  NotificationBanner,
  Icon,
  IconName,
  BannerSeverity,
} from "halifax";
import { useDispatch, useSelector } from "react-redux";
import { ExchangeActionEnum, ExchangeForTypeEnum, TravelItineraryEnum } from "redmond";

import { setExchangeType, setSelectedPnrHfv2 } from "../../reducers/search";
import {
  getOriginalFlightInfo,
  getModifiedFlightInfo,
  getAirlineMap,
  getAirportMap,
  getOutboundLocator,
  getReturnLocator,
  getTravelItinerary,
} from "../../selectors/search";
import { buttonText, searchCopy } from "../../constants";
import { getAirportCityName } from "../../utils/helpers";
import { HOUR_FORMAT, getItineraryDetailsHeader, DISABLED_FLIGHT, isSliceDisabled } from "./constants"

import "./styles.scss";
import { getPolicies } from "../../selectors";
export interface ISliceSelectionModalProps {
  executeSearch: () => void;
  handleGoBack: () => void;
}

const defaultProps: Partial<ISliceSelectionModalProps> = {};

const SliceSelectionModal = (props: ISliceSelectionModalProps): JSX.Element => {
  const { executeSearch, handleGoBack } = props;
  const dispatch = useDispatch();
  const originalFlightInfo = useSelector(getOriginalFlightInfo);
  const policies = useSelector(getPolicies);
  const modifiedFlightInfo = useSelector(getModifiedFlightInfo);
  const airlineMap = useSelector(getAirlineMap);
  const airports = useSelector(getAirportMap);
  const travelItinerary = useSelector(getTravelItinerary)?.TravelItinerary;
  const outboundLocator = useSelector(getOutboundLocator);
  const returnLocator = useSelector(getReturnLocator);
  const isMultiTravelItinerary = travelItinerary === TravelItineraryEnum.MultiTravelItinerary;
  const {
    departureDate: origDepartureDate,
    destinationArrival,
    returnArrival,
    departureAirlineCode,
    originCode,
    destinationCode,
    returnAirlineCode,
    returnDate: origReturnDate,
  } = originalFlightInfo;

  const { departureDate: modDepartureDate, returnDate: modReturnDate } =
    modifiedFlightInfo;
  const isSameDepartureDate = useMemo(
    () => origDepartureDate!.isSame(modDepartureDate, "day"),
    [origDepartureDate, modDepartureDate]
  );
  const isSameReturnDate = useMemo(
    () =>
      origReturnDate ? origReturnDate.isSame(modReturnDate, "day") : false,
    [origReturnDate, modReturnDate]
  );
  const [departSliceChecked, setDepartSliceChecked] = useState<boolean>(false);
  const [returnSliceChecked, setReturnSliceChecked] = useState<boolean>(false);
  const shouldDisplayWarningBanner = useMemo(() => {
    const disableOutboundSlice = isSliceDisabled({
      unscopedValue: outboundLocator?.agent.unscopedValue,
      policies,
    });
    const disableReturnSlice = isSliceDisabled({
      unscopedValue: returnLocator?.agent.unscopedValue,
      policies,
    });
    return disableOutboundSlice || disableReturnSlice;
  }, [policies, outboundLocator, returnLocator]);

  const handleRadioChange = (isOutbound: boolean) => {
    dispatch(setSelectedPnrHfv2(policies[isOutbound ? 0 : 1].agentLocator.unscopedValue))
    if (isOutbound) {
      setDepartSliceChecked(true);
      setReturnSliceChecked(false);
    } else {
      setDepartSliceChecked(false);
      setReturnSliceChecked(true);
    }
  }

  const handleSubmit = useCallback(() => {
    const isRoundTrip = !!modReturnDate;
    const AirExchangeForType = isRoundTrip
      ? ExchangeForTypeEnum.roundTrip
      : ExchangeForTypeEnum.oneWay;
    const outboundSelection = {
      ExchangeAction: isSameDepartureDate && !departSliceChecked
          ? ExchangeActionEnum.keep
          : ExchangeActionEnum.change
    };

    let returnAction;
    if (isRoundTrip && !origReturnDate) {
      //a return date was added (oneWay --> roundTrip)
      returnAction = ExchangeActionEnum.add;
    } else if (isRoundTrip && isSameReturnDate && !returnSliceChecked) {
      //same date, customer wants to keep the slice
      returnAction = ExchangeActionEnum.keep;
    } else if (isRoundTrip && (!isSameReturnDate || returnSliceChecked)) {
      //same date, customer wants to change slice
      returnAction = ExchangeActionEnum.change;
    } else if (!isRoundTrip && origReturnDate) {
      //a return date was removed (roundTrip --> oneWay)
      returnAction = ExchangeActionEnum.remove;
    }

    const returnSelection = returnAction
      ? { ExchangeAction: returnAction }
      : undefined;

    const exchangeType = {
      AirExchangeForType: AirExchangeForType,
      outboundSelection,
      returnSelection,
    };

    dispatch(setExchangeType(exchangeType));
    executeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    departSliceChecked,
    returnSliceChecked,
    modReturnDate,
    isSameReturnDate,
    isSameDepartureDate,
  ]);

  return (
    <GenericModalContent
      title={searchCopy.CONFIRM_SEGMENTS_TO_CHANGE}
      className="slice-selection-modal"
      content={
        <Box className="slice-selection-modal-content">
          {shouldDisplayWarningBanner && (
            <NotificationBanner
              className="status-banner"
              icon={<Icon name={IconName.FlightFunnelIcon} />}
              label={DISABLED_FLIGHT}
              severity={BannerSeverity.WARNING}
            />
          )}
          <Typography className="flight-info-checkbox-outbound" variant="h4">
            <span
              dangerouslySetInnerHTML={{
                __html: getItineraryDetailsHeader({
                  airports,
                  isOutgoing: true,
                  airportCode: destinationCode,
                  date: origDepartureDate
                })
              }}
            />
          </Typography>
          <FlightInfoCheckbox
            variant={isMultiTravelItinerary ? "radio" : "checkbox"}
            checked={departSliceChecked || !isSameDepartureDate}
            disabled={isSliceDisabled({ unscopedValue: outboundLocator?.agent.unscopedValue, policies })}
            onChange={() => {
              if(isMultiTravelItinerary) {
                return handleRadioChange(true)
              }
              return setDepartSliceChecked(!departSliceChecked)
            }}
            origin={`${getAirportCityName(airports, originCode)} (${originCode})`}
            checkboxId={origDepartureDate?.format(HOUR_FORMAT)}
            destination={`${getAirportCityName(airports, destinationCode)} (${destinationCode})`}
            departureDateTime={origDepartureDate?.format(HOUR_FORMAT)}
            arrivalDateTime={formatDateTime(removeTimezone(destinationArrival!), HOUR_FORMAT)}
            airlineCode={departureAirlineCode!}
            flightNumber={airlineMap[departureAirlineCode || ""].displayName}
            className="outbound-slice-card"
          />
          {!!origReturnDate && (
            <>
              <Typography className="flight-info-checkbox-return" variant="h4">
                <span
                  dangerouslySetInnerHTML={{
                    __html: getItineraryDetailsHeader({
                      airports,
                      isOutgoing: false,
                      airportCode: originCode,
                      date: origReturnDate
                    })
                  }}
                />
              </Typography>
              <FlightInfoCheckbox
                variant={isMultiTravelItinerary ? "radio" : "checkbox"}
                checked={returnSliceChecked || !isSameReturnDate}
                disabled={isSliceDisabled({ unscopedValue: returnLocator?.agent.unscopedValue, policies })}
                onChange={() => {
                  if(isMultiTravelItinerary) {
                    return handleRadioChange(false)
                  }
                  return setReturnSliceChecked(!returnSliceChecked)
                }}
                origin={`${getAirportCityName(airports, destinationCode)} (${destinationCode})`}
                checkboxId={origReturnDate?.format(HOUR_FORMAT)}
                destination={`${getAirportCityName(airports, originCode)} (${originCode})`}
                departureDateTime={origReturnDate?.format(HOUR_FORMAT)}
                arrivalDateTime={formatDateTime(removeTimezone(returnArrival!), HOUR_FORMAT)}
                airlineCode={returnAirlineCode!}
                flightNumber={airlineMap[returnAirlineCode || ""].displayName}
                className="return-slice-card"
              />
            </>
          )}
        </Box>
      }
      actionsClassName="slice-selection-actions"
      actions={
        <div className="actions">
          {isMultiTravelItinerary && (
            <ActionButton
              ariaLabelText={buttonText.BACK_BTN}
              defaultStyle="h4r-secondary"
              message={buttonText.BACK_BTN}
              onClick={handleGoBack}
            />
          )}
          <ActionButton
            ariaLabelText={buttonText.SEARCH_FLIGHTS}
            defaultStyle="h4r-primary"
            disabled={
              // disable continue if neither slice is checked
              !(departSliceChecked || !isSameDepartureDate) &&
              !(returnSliceChecked || !isSameReturnDate)
            }
            message={buttonText.CONTINUE}
            onClick={handleSubmit}
          />
        </div>
      }
    />
  );
};

SliceSelectionModal.defaultProps = defaultProps;

export default SliceSelectionModal;

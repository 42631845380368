import { createSelector } from "@reduxjs/toolkit";
import { filter, last, pickBy } from "lodash";
import {
  AirlineCode,
  AirportCode,
  FlightItinerarySegment,
  FlightItinerarySlice,
  FlightShopStep,
  FlightSortOption,
  IFlightListData,
  IFlightNumberMap,
  IReshopFilterOpts,
  ISelectOption,
  MultiTicketTypeEnum,
  OutboundFares,
  SingleTravelItinerary,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";

import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getExchangeTypeEvent,
  getOriginalFlightInfo,
  getShoppedOrigin,
  getShoppedDestination,
  getShoppedDepartureDate,
  getShoppedReturnDate,
  getFlightBooking,
  getTripType,
  getPrevOutboundFlight,
  getPrevReturnFlight,
  getAirlineMap,
  getTravelItinerary,
} from "./search";
import { ExchangeModuleRootState } from "../store";
import filters from "../utils/flightFilters";
import sorters from "../utils/flightSorters";
import {
  getPassengerCountsFromItinerary,
  isTimeRangeModified,
} from "../utils/helpers";

export const getFlightShop = (state: ExchangeModuleRootState) =>
  state.flightShop;

export const getFlightShopFilters = (state: ExchangeModuleRootState) =>
  state.flightShop.filters;

export const getAirlinesFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).airlines;

export const getAirportsFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).airports;

export const getFareClassesFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).fareClasses;

export const getFlightGridFares = (state: ExchangeModuleRootState) =>
  getFlightShop(state).flightGridFares;

export const getFlightNumberFilters = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).flightNumbers;

export const getFlights = (state: ExchangeModuleRootState) =>
  getFlightShop(state).flights;

export const getHasTripDetailsError = (state: ExchangeModuleRootState) =>
  getFlightShop(state).hasTripDetailsError;

export const getHasTripSummariesError = (state: ExchangeModuleRootState) =>
  getFlightShop(state).hasTripSummariesError;

export const getIsTripDetailsLoading = (state: ExchangeModuleRootState) =>
  getFlightShop(state).isTripDetailsLoading;

export const getIsTripSummariesLoading = (state: ExchangeModuleRootState) =>
  getFlightShop(state).isTripSummariesLoading;

export const getMaxPriceFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).maxPrice;

export const getOutboundArrivalTimeFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).outboundArrivalTime;

export const getOutboundDepartureTimeFilter = (
  state: ExchangeModuleRootState
) => getFlightShopFilters(state).outboundDepartureTime;

export const getOutboundFareClass = (state: ExchangeModuleRootState) =>
  getFlightShop(state).outboundFareClass;

export const getReturnArrivalTimeFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).returnArrivalTime;

export const getReturnDepartureTimeFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).returnDepartureTime;

export const getReturnFlights = (state: ExchangeModuleRootState) =>
  getFlightShop(state).returnFlights;

export const getSortOption = (state: ExchangeModuleRootState) =>
  getFlightShop(state).sortOption;

export const getStopsCountFilter = (state: ExchangeModuleRootState) =>
  getFlightShopFilters(state).stopsCount;

export const getFlightShopStep = (state: ExchangeModuleRootState) =>
  getFlightShop(state).shopStep;

export const getShoppedTrip = (state: ExchangeModuleRootState) =>
  getFlightShop(state).shoppedTrip;

export const getTripDetailsMap = (state: ExchangeModuleRootState) =>
  getFlightShop(state).tripDetails;

export const getTripDetails = (
  state: ExchangeModuleRootState,
  tripId: string
) => getFlightShop(state).tripDetails[tripId];

export const getTripIds = (state: ExchangeModuleRootState) =>
  getFlightShop(state).tripIds;

export const getTripSummaries = (state: ExchangeModuleRootState) =>
  getFlightShop(state).tripSummaries;

export const getTripSliceKey = createSelector(getFlightShopStep, (shopStep) =>
  shopStep === FlightShopStep.ChooseReturn ? "returningSlice" : "outgoingSlice"
);
export const getExchangeType = (state: ExchangeModuleRootState) =>
  getFlightShop(state).exchangeType;

export const getFetchSummariesParams = createSelector(
  getFlightBooking,
  getDepartureDate,
  getDestination,
  getOrigin,
  getOriginalFlightInfo,
  getReturnDate,
  getStopsCountFilter,
  getTripType,
  getExchangeType,
  (
    booking,
    depDate,
    dest,
    origin,
    originalFlightInfo,
    retDate,
    stopsCount,
    tripType,
    exchangeType
  ) => {
    const {
      departureDate: ogDepDate,
      destination: ogDest,
      origin: ogOrigin,
      returnDate: ogRetDate,
    } = originalFlightInfo;
    const passengersMap = getPassengerCountsFromItinerary(
      booking?.bookedItinerary
    );

    // use original values in case not modified
    return {
      bookingId: booking?.bookedItinerary.id,
      passengersMap,
      departureDate: depDate ?? ogDepDate,
      destTerminus: dest ?? ogDest,
      originTerminus: origin ?? ogOrigin,
      returnDate: retDate ?? ogRetDate,
      stopsCount,
      tripType,
      exchangeType,
    };
  }
);

export const getFareClassFilterApplied = createSelector(
  getFareClassesFilter,
  (fareClassesFilter) => Object.values(fareClassesFilter).includes(true)
);

export const getIsOutgoingMultiTicket = createSelector(
  getTripDetailsMap,
  getShoppedTrip,
  (tripDetailsMap, shoppedTrip) => {
    const { outgoingFareId } = shoppedTrip;
    const trip = tripDetailsMap[shoppedTrip?.tripId || ""];

    if (trip && outgoingFareId) {
      const fareDetails = trip.fareDetails.find((f) => f.id === outgoingFareId);

      return Boolean(fareDetails?.multiTicket);
    }

    return false;
  }
);

export const getIsReturnMultiTicket = createSelector(
  getTripDetailsMap,
  getShoppedTrip,
  (tripDetailsMap, shoppedTrip) => {
    const { returnFareId } = shoppedTrip;
    const trip = tripDetailsMap[shoppedTrip?.tripId || ""];

    if (trip && returnFareId) {
      const fareDetails = trip.fareDetails.find((f) => f.id === returnFareId);

      return Boolean(fareDetails?.multiTicket);
    }

    return false;
  }
);

export const getPrevAirlineName = createSelector(
  getPrevOutboundFlight,
  getPrevReturnFlight,
  getFlightShopStep,
  getAirlineMap,
  (prevOutbound, prevReturn, shopStep, airlines) => {
    let prevFlight;

    if (shopStep === FlightShopStep.ChooseReturn) prevFlight = prevReturn;
    else prevFlight = prevOutbound;

    return airlines[prevFlight?.marketingAirline?.code ?? ""]?.displayName;
  }
);

export const getNonFareClassFilterApplied = createSelector(
  getFlightShopFilters,
  (shopFilters) => {
    const {
      airlines,
      airports,
      flightNumbers,
      maxPrice,
      outboundArrivalTime,
      outboundDepartureTime,
      returnArrivalTime,
      returnDepartureTime,
      stopsCount,
    } = shopFilters;
    let applied = false;

    if (airlines.length) applied = true;
    else if (airports.length) applied = true;
    else if (flightNumbers.length) applied = true;
    else if (maxPrice >= 0 && maxPrice !== Number.MAX_SAFE_INTEGER)
      applied = true;
    else if (isTimeRangeModified(outboundArrivalTime)) applied = true;
    else if (isTimeRangeModified(outboundDepartureTime)) applied = true;
    else if (isTimeRangeModified(returnArrivalTime)) applied = true;
    else if (isTimeRangeModified(returnDepartureTime)) applied = true;
    else if (stopsCount !== SliceStopCountFilter.ANY_NUMBER) applied = true;

    return applied;
  }
);

export const getOutboundFlightList = createSelector(
  getFlights,
  (flights) => flights?.outbound ?? []
);

export const getReturnFlightList = createSelector(
  getFlights,
  getShoppedTrip,
  (flights, shoppedTrip) => {
    const { outgoingFareId, outgoingSliceId } = shoppedTrip;

    if (!outgoingSliceId || !flights) return [];

    const outboundFlight = flights.outbound.find(
      (f) => f.slice === outgoingSliceId
    );
    const fare =
      outboundFlight?.fares.find(
        (f: OutboundFares) => f.example.fare === outgoingFareId
      ) ?? outboundFlight?.fares[0];

    if (fare?.next) {
      return fare.next.reduce(
        (returnFlightList: IFlightListData[], tripId: string) => {
          const trip = flights.trips[tripId];
          const { fares, return: returnSliceId = "" } = trip;
          const tripFares = fares.map((fareId) => flights.fares[fareId]);

          returnFlightList.push({
            fares: tripFares,
            slice: returnSliceId,
          });

          return returnFlightList;
        },
        []
      );
    }

    return [];
  }
);

export const getFlightList = createSelector(
  getOutboundFlightList,
  getReturnFlightList,
  getFlightShopStep,
  (outboundList, returnList, shopStep) => {
    if (shopStep === FlightShopStep.ChooseReturn) return returnList;
    return outboundList;
  }
);

export const getFilteredFlights = createSelector(
  getFlightShopFilters,
  getFlightShopStep,
  getFlights,
  getFlightList,
  (shopFilters, shopStep, flights, flightList) => {
    const {
      airlines,
      airports,
      fareClasses,
      flightNumbers,
      maxPrice,
      outboundArrivalTime,
      outboundDepartureTime,
      returnArrivalTime,
      returnDepartureTime,
      stopsCount,
    } = shopFilters;
    const onReturnStep = shopStep === FlightShopStep.ChooseReturn;
    const selectedFares = pickBy(fareClasses, (value) => value === true);

    const meetsFilterPredicates = (flight: IFlightListData) => {
      const { fares, slice } = flight;
      const flightSlice = flights!.slices[slice];
      const selectedFareClasses = Object.keys(selectedFares);
      let keep = true;

      if (airlines.length) {
        keep = keep && filters.filterByAirline(flightSlice, airlines);
      }

      if (airports.length) {
        keep = keep && filters.filterByAirport(flightSlice, airports);
      }

      if (selectedFareClasses.length) {
        keep =
          keep &&
          filters.filterByFareClass(flights!, flight, selectedFareClasses);
      }

      if (flightNumbers.length) {
        keep = keep && filters.filterByFlightNumber(flightSlice, flightNumbers);
      }

      if (maxPrice > 0 && maxPrice !== Number.MAX_SAFE_INTEGER) {
        keep = keep && filters.filterByMaxPrice(fares, maxPrice);
      }

      if (
        !onReturnStep &&
        (isTimeRangeModified(outboundArrivalTime) ||
          isTimeRangeModified(outboundDepartureTime))
      ) {
        keep =
          keep &&
          filters.filterByTimeRange(
            flightSlice,
            outboundDepartureTime,
            outboundArrivalTime
          );
      }

      if (
        onReturnStep &&
        (isTimeRangeModified(returnArrivalTime) ||
          isTimeRangeModified(returnDepartureTime))
      ) {
        keep =
          keep &&
          filters.filterByTimeRange(
            flightSlice,
            returnDepartureTime,
            returnArrivalTime
          );
      }

      if (stopsCount !== SliceStopCountFilter.ANY_NUMBER) {
        keep = keep && filters.filterByStopsCount(flightSlice, stopsCount);
      }

      return keep;
    };

    const filteredFlights = filter(flightList, meetsFilterPredicates);

    return (filteredFlights ?? []) as IFlightListData[];
  }
);

export const getSortedFlights = createSelector(
  getFilteredFlights,
  getFlights,
  getSortOption,
  (flightList, flights, sortOption) => {
    switch (sortOption) {
      case FlightSortOption.ArrivalTime:
        return [...sorters.orderByArrivalTime(flightList, flights!)];
      case FlightSortOption.DepartureTime:
        return [...sorters.orderByDepartureTime(flightList, flights!)];
      case FlightSortOption.Duration:
        return [...sorters.orderByDuration(flightList, flights!)];
      case FlightSortOption.FareScore:
        return [...sorters.orderByRecommended(flightList, flights!)];
      case FlightSortOption.Price:
        return [...sorters.orderByPrice(flightList)];
      case FlightSortOption.Stops:
        return [...sorters.orderByStops(flightList, flights!)];
      default:
        return [...sorters.orderByRecommended(flightList, flights!)];
    }
  }
);

/**
 * @description Compiles the set of all possible filter options based on the reshop results
 * @return {IReshopFilterOpts}
 */
export const getFlightFilterOptions = createSelector(
  getFlights,
  getFlightList,
  (flights, flightList) => {
    const airlines: { [key: string]: ISelectOption<AirlineCode> } = {};
    const airports: { [key: string]: ISelectOption<AirportCode> } = {};
    const flightNumbers: IFlightNumberMap = {};
    const max = { value: 0 };
    const min = { value: Number.MAX_SAFE_INTEGER };

    const flightFilterOpts: IReshopFilterOpts = {
      airlineOpts: [],
      airportOpts: [],
      flightNumberOpts: flightNumbers,
      priceMax: max,
      priceMin: min,
    };

    if (!flights) return flightFilterOpts;

    flightList.forEach((flight: any) => {
      const flightSlice = flights.slices[flight.slice];
      const {
        marketingAirline: airlineCode,
        origin: airportCode,
        segments,
      } = flightSlice;
      const { flightNumber } = segments[0];

      // AIRLINES
      if (!airlines[airlineCode]) {
        airlines[airlineCode] = {
          label: flights.airlines[airlineCode].displayName || "",
          value: airlineCode,
        };
      }

      // AIRPORTS
      if (!airports[airportCode]) {
        airports[airportCode] = {
          label: flights.airports[airportCode].name || "",
          value: airportCode,
        };
      }

      // PRICES
      flight.fares.forEach((fare: any) => {
        const { value } = fare.amount.fiat;

        max.value = Math.max(value, max.value);
        min.value = Math.min(value, min.value);
      });

      // FLIGHT NUMBER
      if (!flightNumbers[airlineCode]) {
        flightNumbers[airlineCode] = [flightNumber];
      } else if (!flightNumbers[airlineCode].includes(flightNumber)) {
        flightNumbers[airlineCode].push(flightNumber);
      }
    });

    flightFilterOpts.airlineOpts = Object.values(airlines);
    flightFilterOpts.airportOpts = Object.values(airports);

    return flightFilterOpts;
  }
);

export const getNoResultsEventProps = createSelector(
  getExchangeTypeEvent,
  getOriginalFlightInfo,
  getShoppedOrigin,
  getShoppedDestination,
  getShoppedDepartureDate,
  getShoppedReturnDate,
  getTripType,
  getFlightBooking,
  getFlightList,
  (
    exchangeTypeEvent,
    originalFlightInfo,
    shoppedOrigin,
    shoppedDestination,
    shoppedDepartureDate,
    shoppedReturnDate,
    tripType,
    flightBooking,
    flightList
  ) => {
    const slices =
      // @ts-ignore
      flightBooking?.bookedItinerary?.travelItinerary?.slices || [];

    return flightList.length
      ? null
      : {
          initial_origin: originalFlightInfo?.origin?.id?.code?.code,
          searched_origin: shoppedOrigin?.id?.code?.code,
          initial_destination: originalFlightInfo?.destination?.id?.code?.code,
          searched_destination: shoppedDestination?.id?.code?.code,
          initial_departure_date: originalFlightInfo?.departureDate?.toString(),
          searched_departure_date: shoppedDepartureDate?.toString(),
          initial_return_date: originalFlightInfo?.returnDate?.toString(),
          searched_return_date: shoppedReturnDate?.toString(),
          exchange_shop: exchangeTypeEvent,
          initial_trip_type: originalFlightInfo?.returnDate
            ? TripCategory.ROUND_TRIP
            : TripCategory.ONE_WAY,
          searched_trip_type: tripType,
          initial_outbound_stops: originalFlightInfo?.departureStops,
          initial_return_stops: originalFlightInfo?.returnStops,
          product: "flight",
          outbound_segments: slices[0]?.segments?.length,
          return_segments: slices[1]?.segments?.length,
          is_multi_ticket:
            flightBooking?.bookedItinerary?.multiTicketType !==
            MultiTicketTypeEnum.Single,
          carrier: originalFlightInfo?.departureAirlineCode,
          agent_locator:
            flightBooking?.bookedItinerary?.travelItinerary?.locators?.agent
              ?.unscopedValue,
          system_locator:
            // @ts-ignore
            flightBooking?.bookedItinerary?.travelItinerary?.locators?.b2b,
        };
  }
);

/**
 * Combines info from all segments in a slice to create a summary
 * @example slice origin/dest = segment[0].origin -> segment[N].dest
 */
export const getPrevFlightSliceSummary = createSelector(
  getFlightShopStep,
  getTravelItinerary,
  (shopStep, fullItinerary) => {
    let sliceSummary: Partial<FlightItinerarySegment> = {};

    if (fullItinerary) {
      const onReturnStep = shopStep === FlightShopStep.ChooseReturn;
      let itinerary;
      let slice: FlightItinerarySlice | undefined;
      let slices: FlightItinerarySlice[] | undefined;
      let outboundSegment;
      let returnSegment;

      if ("travelItineraries" in fullItinerary) {
        // handle MultiTravelItinerary
        itinerary = fullItinerary.travelItineraries as SingleTravelItinerary[];
      } else if ("slices" in fullItinerary) {
        // handle SingleTravelItinerary
        slices = fullItinerary?.slices;
      }

      if (itinerary) {
        if (onReturnStep) {
          slice = last(itinerary[1]?.slices ?? []);
        } else {
          slice = itinerary[0]?.slices[0];
        }
      }

      if (slices) {
        if (onReturnStep) {
          slice = last(slices ?? []);
        } else {
          slice = slices[0];
        }
      }

      if (slice) {
        outboundSegment = slice.segments[0];

        if (onReturnStep) {
          returnSegment = last(slice.segments);
        }
      }

      if (slice && outboundSegment) {
        const totalPlusDays =
          slice.segments.reduce((acc, seg) => {
            acc += seg.plusDays ?? 0;

            return acc;
          }, 0) ?? 0;

        sliceSummary = {
          ...(onReturnStep ? returnSegment : outboundSegment),
          destination:
            returnSegment?.destination ?? outboundSegment?.destination,
          origin: outboundSegment.origin,
          plusDays: totalPlusDays,
          scheduledArrival:
            returnSegment?.scheduledArrival ?? outboundSegment.scheduledArrival,
          scheduledDeparture: outboundSegment.scheduledDeparture,
          stops: slice.segments.length - 1,
          updatedArrival:
            returnSegment?.updatedArrival ?? outboundSegment.updatedArrival,
          updatedDeparture: outboundSegment.updatedDeparture,
          zonedScheduledArrival:
            returnSegment?.zonedScheduledArrival ??
            outboundSegment.zonedScheduledArrival,
          zonedScheduledDeparture: outboundSegment.zonedScheduledDeparture,
          zonedUpdatedArrival:
            returnSegment?.zonedUpdatedArrival ??
            outboundSegment.zonedUpdatedArrival,
          zonedUpdatedDeparture: outboundSegment.zonedUpdatedDeparture,
        };
      }
    }

    return sliceSummary;
  }
);
